import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { Select } from '../../components/ModalInputs'
import { useFormik } from 'formik'
import Button from '../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { getCourses, getSchoolYears, getCLasses } from '../../actions/common'
import { getExamReport } from '../../actions/exams/examReport'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import { objFilter } from '../../utils/smallHelpers'
import * as Yup from 'yup'
import Loader from '../../components/Loader'
import Table from '../../components/Table'

const PARALLELS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']

const validationSchema = Yup.object().shape({
  school_year_id: Yup.string().required('обязательное поле'),
  parallel: Yup.string().required('обязательное поле'),
})

const Report = () => {
  
  const dispatch = useDispatch()
  const {  schoolYears, courses, classes } = useSelector(state => state.common)
  const { report, loading } = useSelector(state => state.exams)
  const location = useLocation()
  const navigate = useNavigate()

  const paramsForm = queryString.parse(location.search)
  
  useEffect(() => {
    dispatch(getSchoolYears())
    dispatch(getCourses())
    dispatch(getCLasses())
  }, [])
  
  const submitForm = (values) => {
    const filteredValues = objFilter(values)
    const searchParams = queryString.stringify(filteredValues)
    navigate(`/ege/report?${searchParams}`)
    const {school_year_id, parallel} = filteredValues
    if (!school_year_id || !parallel) return;
    dispatch(getExamReport(filteredValues))
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleBlur,
    handleSubmit
  } = useFormik({
    initialValues: paramsForm,
    enableReinitialize: true,
    validationSchema,
    onSubmit: submitForm
  })
  
  const renderOption = (item) => {
    const { name, gid, xp_key, title, cid } = item
    const optionName = name || title
    const optionValue = gid || xp_key || cid
    return <option key={optionValue} value={optionValue}>{optionName}</option>
  }

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]
  
  const renderRow = (item) => (
    <tr>
      <td>{item.title}</td>   
      <td>{item.title}</td>   
      <td>{item.title}</td>   
      <td>{item.title}</td>   
      <td>{item.title}</td>   
      <td>{item.title}</td>   
      <td>{item.title}</td>   
      <td>{item.title}</td>   
      <td>{item.title}</td>   
      <td>{item.title}</td>   
      <td>{item.title}</td>   
    </tr>
  )

  return (
    <div className={styles.reportWrapper}>
      
      <h1>Составление расписания</h1>
      
      <form onSubmit={handleSubmit}>
        <Select
          label='Учебный год'
          name='school_year_id'
          value={values.school_year_id}
          onChange={handleChange}
          onBlur={handleBlur}
          error={error('school_year_id')}
        >
          <option value=''>Выберите учебный год</option>
          {schoolYears?.map(renderOption)}
        </Select>
        
        <Select
          label='Параллель'
          name='parallel'
          value={values.parallel}
          onChange={handleChange}
          onBlur={handleBlur}
          error={error('parallel')}
        >
          <option value=''>Выберите параллель</option>
          {PARALLELS?.map(par => <option key={par} value={par}>{par}</option>)}
        </Select>
        
        <Select
          label='Предмет'
          name='subject_id'
          value={values.subject_id}
          onChange={handleChange}
        >
          <option value=''>Выберите предмет</option>
          {courses?.map(renderOption)}
        </Select>
        
        <Select
          label='Класс'
          name='class_id'
          value={values.class_id}
          onChange={handleChange}
        >
          <option value=''>Выберите класс</option>
          {classes?.map(renderOption)}
        </Select>
        
        <Select
          label='Код ОО'
          name='codeOO'
          value={values.codeOO}
          onChange={handleChange}
        >
        </Select>

        <Button type='submit'>Составить</Button>
      </form>

      {loading
        ? <Loader/>
        : !report?.length
          ? <p>Ничего не найдено</p>
          : <Table>
              <thead>
                <tr>
                  <th>Предмет</th>
                  <th>Дата</th>
                  <th>ППЭ</th>
                  <th>Код ОО</th>
                  <th>Класс</th>
                  <th>ФИО</th>
                  <th>Первичный балл</th>
                  <th>Балл</th>
                  <th>Сумм. баллы</th>
                  <th>Отметка</th>
                  <th>Результат</th>
                </tr>
              </thead>
              <tbody>
                {report?.map(renderRow)}
              </tbody>
            </Table>
      }

    </div>
  )
}

export default Report