import storage from 'redux-persist/es/storage';
import { persistCombineReducers } from 'redux-persist';

import auth from './auth';
import groups from './groups';
import roles from './roles';
import common from './common';
import courses from './courses';
import school_years from './schoolYears';
import school_weeks from './schoolWeeks';
import candidates from './candidates';
import terms from './terms';
import periods from './periods';
import schedule_variants from './schedule_variants';
import reports from './reports';
import schedule from './schedule';
import classbook from './classbook';
import personnel from './personnel';
import info_main from './info_main';
import method_office from './method_office';
import settings from './settings';
import discipline_structure from './discipline_structure';
import roomTypes from './roomTypes';
import rooms from './rooms';
import students from './students';
import planning from './theme_planning';
import ads from './ads';
import event_type from './event_types';
import FAQ from './FAQ';
import directory from './directory';
import educational_structure from './educational_structure';
import competence from './competence';
import scheduling from './scheduling';
import edu_directions from './edu_directions';
import directoryObjectsUMB from './directoryObjectsUMB';
import culture from './culture';
import formsOfEducation from './formsOfEducation';
import schedule_settings from './schedule_settings';
import studyPlans from './studyPlans';
import subjectsByYearStudy from './subjectsByYearStudy';
import directoryMedCenter from './directoryMedCenter';
import directoryCanteen from './directoryCanteen';
import directoryBasicEducation from './directoryBasicEducation';
import directoryExtraEducation from './directoryExtraEducation';
import directoryExtraCurEducation from './directoryExtraCurEducation';
import event_levels from './event_levels';
import achievements from './achievements';
import do_schedule_variants from './do_schedule_variants';
import act_types from './act_types';
import physical_group from './physical_group';
import achiev_points from './achiev_points';
import vd_schedule_variants from './vd_schedule_variants';
import educ_directions from './educ_directions';
import exams from './exams';
import studyPlansDO from './studyPlansDO';
import studyPlansVD from './studyPlansVD';
import holidays from './holidays';
import fitnessIndicators from './fitnessIndicators';
import stages_study from './stages_study';
import educational_institutions from './educational_institutions';


const storageConfig = { whitelist: 'auth', key: 'certification_storage', storage };

export default persistCombineReducers(storageConfig, {
  auth,
  common,
  courses,
  candidates,
  scheduling,
  groups,
  periods,
  reports,
  roles,
  school_years,
  school_weeks,
  schedule_variants,
  schedule,
  terms,
  classbook,
  personnel,
  info_main,
  method_office,
  settings,
  students,
  discipline_structure,
  roomTypes,
  rooms,
  planning,
  ads,
  event_type,
  FAQ,
  directory, // ВСЕ СПРАВОЧНИКИ
  educational_structure,
  competence,
  edu_directions,
  educ_directions,
  directoryObjectsUMB,
  culture,
  formsOfEducation,
  schedule_settings,
  studyPlans,
  subjectsByYearStudy,
  directoryMedCenter, // МЕДПУНКТ СПРАВОЧНИКИ
  directoryCanteen, // СТОЛОВАЯ СПРАВОЧНИКИ
  directoryBasicEducation, // ОСНОВНОЕ ОБРАЗОВАНИЕ СПРАВОЧНИКИ
  directoryExtraEducation, // ДОПОЛНИТЕЛЬНОЕ ОБРАЗОВАНИЕ СПРАВОЧНИКИ
  directoryExtraCurEducation, // Внеурочная деятельность СПРАВОЧНИКИ
  event_levels,
  achievements,
  do_schedule_variants,
  vd_schedule_variants,
  act_types,
  physical_group,
  achiev_points,
  exams,
  studyPlansDO,
  studyPlansVD,
  holidays,
  fitnessIndicators,
  stages_study,
  educational_institutions,
});