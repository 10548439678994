import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCandidatesParams } from '../../../../actions/settings/candidates'
import { Checkbox } from '../../../../components/ModalInputs'
import styles from './styles.module.scss'

const GRADE_TYPES = [
  {name: 'отсутствует', value: 1},
  {name: 'да(допуск)/нет', value: 2},
  {name: 'нет(допуск)/да', value: 3},
  {name: 'оценка', value: 4}
]


const AssessmentParams = ({form}) => {
  
  const dispatch = useDispatch()
  const { params } = useSelector(state => state.settings.candidates)


  useEffect(() => {
    dispatch(getCandidatesParams(form.values.year))
  }, [form.values.year])
  
  
  return (
    <div>

      <div className={styles.paramsChildBLock}>
        
        <p>Психологическое тестирование</p>

        <Checkbox label='Психологическая готовность по принципу Зачет/Незачет'/>

        {params && <table>
          {[1, 2, 3, 4, 5, 6, 7, 8].map(index =>
            <tr>
              <td>{`Тест${index}`}</td>
              <td>
                <select>
                  {GRADE_TYPES.map(type => <option value={type.value}>{type.name}</option>)}
                </select>
              </td>
              <td>{params[`name${index}`]}</td>
              <td>{params[`min${index}`]}</td>
              <td>{params[`max${index}`]}</td>
              <td>{params[`pass${index}`]}</td>
            </tr>
          )}
        </table>}
      
      </div>
      
      <div className={styles.paramsChildBLock}>
        
        <p>Участвуют в расчете ЕДИНОЙ БАЛЬНОЙ ОЦЕНКИ</p>

        <Checkbox label='Средний балл по табелю'/>

        <Checkbox label='Участие в олимпиадах, конкурсах'/>
        
        <Checkbox label='Балл за портфолио'/>
        
        <Checkbox label='Техническая и специальная физическая подготовка'/>

      </div>

    </div>
  )
}

export default AssessmentParams