import React, { useState } from 'react'
import Button from '../../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import Li from '../../../components/Li'
import { thrash } from '../../../assets/svg/other'
import styles from '../styles.module.css'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGroupUsers, deleteGroupUser } from '../../../actions/permissions'
import Loader from '../../../components/Loader'
import Modal from '../Modal'
import Confirmation from '../../../components/Confirmation/Confirmation'

export function Employees ({roleId}) {
  
  const dispatch = useDispatch();
  const {groupUsers: peoples, loading} = useSelector(state => state.roles.permission_groups);
  const [selectedItem, setSelectedItem] = useState(null);
  const [confirmation, setConfirmation] = useState(false);

  const acceptConfirmation = () => {
    dispatch(deleteGroupUser(roleId, confirmation));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  useEffect(() => {
    dispatch(getGroupUsers(roleId))
  }, []);

  const openModal = () => {
    setSelectedItem(peoples.role_group.pmid || null);
  }

  const closeModal = () => setSelectedItem(null);

  const renderLi = ({lastname, firstname, patronymic, mid}) => {
    const name = `${lastname} ${firstname} ${patronymic}`
    return (
      <Li key={mid}>
        <div className={styles.liTitle}>{name}</div>
        <i onClick={() => setConfirmation(mid)}>{thrash()}</i>
      </Li>
    )
  }

  const getFio = (mid) => {
    const {lastname, firstname, patronymic} = peoples.users.find(user => user.mid === mid);
    return `${lastname + ' ' + firstname + ' ' + patronymic}`
  }

  return (
    <>
      {confirmation && (
        <Confirmation
            top="30px"
            left="40px"
            title="Удалить права сотруднику?"
            confirmation={getFio(confirmation)} 
            acceptConfirmation={() => acceptConfirmation(confirmation)}
            rejectConfirmation={rejectConfirmation}
        />
        )}
      <Modal closeModal={closeModal} data={selectedItem}/>

      {loading ? <Loader/> : <ul className={styles.peoplesList}>{peoples?.users?.map(renderLi)}</ul>}
      <Button style={{padding: '.5rem 1.5rem'}} onClick={() => openModal()}>
        <IoIosAdd size={30}/>Добавить сотрудника
      </Button>
    </>
  )
}
