import React, { useEffect, useState } from 'react'
import { coursesModalModes } from '../../assets/const'
import {Main, Files} from './ModalTabs'

import Modal from '../../components/Modal'

export default function ModalComponent ({data, closeModal, list, pageNumber, addMode, setAddMode, infoCreateChange}) {
  const [mode, setMode] = useState('main')

  const renderTab = () => {
      switch (mode) {
          case 'main': return <Main closeModal={closeModal} data_={data} list={list} addMode={data === 'add'} pageNumber={pageNumber} infoCreateChange={infoCreateChange}/>
          case 'files': return <Files closeModal={closeModal} infoCreateChange={infoCreateChange} />
          default: return
      }
  }
  

  return (
      <Modal
        open={!!data || addMode}
        menuItem={addMode}
        addMode={addMode}
        setMenuItem={setMode}
        menuList={coursesModalModes}
        coursesClassBistribution
      >
        {renderTab()}
      </Modal>
  )
}
