import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.css'
import * as Yup from 'yup'
import { BiTrash } from 'react-icons/bi'
import Table from '../../../components/Table'
import ModalVacBot from '../TypeVaccine/ModalVacBot'
import { IoIosAdd, IoIosClose } from 'react-icons/io'
import Button from '../../../components/Button'
import { deleteTypesOfVaccinations, getTypesOfVaccinations } from '../../../actions/medCenter/vassinations'
import Confirmation from '../../../components/Confirmation/Confirmation'


export default function ModalComponent({ height, setOpenModalVacTop }) {

  const dispatch = useDispatch()

  const { typesVaccinations } = useSelector(state => state.directoryMedCenter)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  // useEffect(() => {
  //   dispatch(getTypesOfVaccinations())

  // }, [])
  const openModalBot = (data) => () => {
    setSelectedItem(data || {})
  }
  const takeSelectedVac = (data) => () => {
    setSelectedItem(data)
  }

  const closeModalBot = () => {
    setSelectedItem(null)
  }

  const closeModal = () => setOpenModalVacTop(null)

  const acceptConfirmation = () => {
    dispatch(deleteTypesOfVaccinations(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { name, c_vac_type } = data
    return (
      <tr >
        <td
          onDoubleClick={openModalBot(data)}
          onClick={takeSelectedVac(data)} // для main запроса
        >{name}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: c_vac_type, name: name })}><BiTrash /></i></td>
      </tr>
    )
  }


  return (
    <Modal
      open={!!typesVaccinations}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      center
      classNames={{
        // overlay: styles.overlay,
        modal: styles.custom
      }}
    >
      <>
        {confirmation && (
          <Confirmation
            top="30px"
            left="40px"
            title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
            acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
            rejectConfirmation={rejectConfirmation}
          />
        )}
        {selectedItem &&
          <ModalVacBot
            closeModal={closeModalBot}
            data={selectedItem}
            addMode={selectedItem === 'add'}
          />}
        <Table style={{ marginTop: '2rem', backgroundColor: 'white', maxHeight: "55vh" }}>
          <thead>
            <tr>
              <th>Тип вакцины</th>
              <th></th>
            </tr>
          </thead>
          <tbody>

            {typesVaccinations?.map(renderLi)}

          </tbody>
        </Table>
        <div style={{ display: 'flex', flexDirection: "row" }}>
          <Button
            style={{ padding: '.5rem 1.5rem', margin: '12px' }}
            onClick={openModalBot('add')}
          >
            <IoIosAdd size={30} />
            Добавить
          </Button>
          <Button
            onClick={closeModal}
            style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          >
            <IoIosClose size={30} />
            Закрыть
          </Button>
        </div>


      </>
    </Modal>

  )
}
