import { createContext } from 'react'

export const ClassingContext = createContext()
export const CandidatesContext = createContext()
export const MethodOfficeContext = createContext()
export const SettingsContext = createContext()
export const DisciplineStructureContext = createContext()
export const VariableStuffContext = createContext()
export const StudentsContext = createContext()
export const StaffRosterContext = createContext()
export const EducationalStructureContext = createContext()
