import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { Input  } from '../../../components/ModalInputs'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { useDispatch,  } from 'react-redux'
import { useFormik } from 'formik'
import { createCompetence, updateCompetence } from '../../../actions/competence'



export default function Main ({closeModal, addMode, selectedItem, }) {

  const dispatch = useDispatch()

  const handleCreateCompetence =  (values) => {
      dispatch(createCompetence(values))  
      closeModal()
  }

  const handleUpdateCompetence =  (values) => {
      dispatch(updateCompetence(values))  
      closeModal()
  }

  const {
    values, 
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: addMode ? {} : selectedItem,
    enableReinitialize: true,
    onSubmit: addMode ? handleCreateCompetence :  handleUpdateCompetence 
  })

return (
!!values && 
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
          <div> 
              <Input 
                label='Название'
                name='name'
                value={values.name}
                error={!addMode ? values?.name ? 
                          values?.name?.length > 50 ?   'Не более 50 символов '  : ''
                          : 'Обязательное поле'
                        : ''}
                onChange={handleChange}
              />
            </div> 
          

        </div>
        </div>
      </div>
      {/* <Button mode='white' onClick={()=>handleCreateYear(values)}>Сохранить</Button> */}
      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )

}
