import { api } from "../App";
import { BACKEND_URL_PREFIX } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import {

  GET_GROUP_NUTRITION_PARALLELS,
  GET_NUTRITION_GROUP,
  GET_PERIOD_NUTRITION_LIST,
  GET_NUTRITION_PERIOD,
  GET_NUTRITION_PERIOD_FILES,
  GET_NUTRITION_PERIOD_FILE,
  GET_TRACK_PROFILE_LIST,
  GET_TRACK_PROFILE_CURR,
  GET_STUDY_SHIFTS_LIST,
  GET_STUDY_MANNERS_LIST,
  GET_homeworksS,
  GET_HOMEWORKS,
  GET_DIGITAL_EXAM_BOOK,
  GET_JORNAL_CONTROL,
  GET_JOURNAL_MANNERS_LIST,
  GET_JORNAL_CONTROL_COURSES,
  GET_HOMEWORK_LIST_BASEEDUC
} from "./types";





// <----  ПРОФИЛИ ОБУЧЕНИЯ ---->

export const getTrackProfileList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/track_profile`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_TRACK_PROFILE_LIST, payload: result });
    }
  }
};

export const getTrackProfileCurr = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/track_profile/${id} `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());

      dispatch({ type: GET_TRACK_PROFILE_CURR, payload: result });
    }
  }

};

export const updateTrackProfile = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/track_profile/${params.trp_id}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getTrackProfileList());
    }
  };
}

export const createTrackProfile = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/track_profile`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getTrackProfileList());
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const deleteTrackProfile = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/track_profile/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();

    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getTrackProfileList());
    } else createNotification('error', result.error);
  }
};

// <----  УЧЕБНЫЕ СМЕНЫ ---->

export const getStudyShiftsList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/shifts`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_STUDY_SHIFTS_LIST, payload: result });
    }
  }

};


export const updateStudyShift = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/shifts/${params.sid}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getStudyShiftsList());
    }
  };
}

export const createStudyShift = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/shifts`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getStudyShiftsList());
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const deleteStudyShift = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/shifts/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();

    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getStudyShiftsList());
    } else createNotification('error', result.error);
  }
};
// <----  Критерии воспитанности ----> ///////////////////////////////////////////////////////

export const getMannersList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/manners`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      console.log('result::: ', result);
      dispatch({ type: GET_STUDY_MANNERS_LIST, payload: result });
    }
  }
};


export const updateManners = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/manners/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getMannersList());
    }
  };
}

export const createManners = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/manners`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getMannersList());
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const deleteManners = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/manners/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();

    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getMannersList());
    } else createNotification('error', result.error);
  }
};
// <----  ЖУРНАЛ воспитанности ----> ///////////////////////////////////////////////////////

export const getJournalMannersList = (data) => async (dispatch, getState) => {
  console.log('data::: ', data);
  const { group_id, manner_id, trimestr } = data
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/manners_journal/${trimestr}/${manner_id}/${group_id}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      console.log('result::: ', result);
      dispatch({ type: GET_JOURNAL_MANNERS_LIST, payload: result });
    }
  }
};


// export const updateManners = (params) => async (dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {

//     const response = await fetch(`${BACKEND_URL_PREFIX}/manners/${params.id}`, {
//       method: "PUT",
//       body: JSON.stringify(params),
//       ...buildDefaultHeaders(getState),
//     });
//     if (response.ok) {
//       await dispatch(getMannersList());
//     }
//   };
// }

// export const createManners = (data) => async (dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     const response = await fetch(`${BACKEND_URL_PREFIX}/manners`, {
//       method: "POST",
//       body: JSON.stringify(data),
//       ...buildDefaultHeaders(getState),
//     });
//     if (response.ok) {
//       createNotification('success', 'Успешно');
//       await dispatch(getMannersList());
//     } else createNotification('error', "Не удалось создать запись");
//   }
// };

// export const deleteManners = (id) => async (dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     const response = await fetch(`${BACKEND_URL_PREFIX}/manners/${id}`, {
//       method: "DELETE",
//       ...buildDefaultHeaders(getState),
//     });
//     const result = await response.json();

//     if (response.ok) {
//       createNotification('success', 'Успешно');
//       await dispatch(getMannersList());
//     } else createNotification('error', result.error);
//   }
// };

// <---- Домашние задания  ---->  /////////////////////////////////////////////////////// API
//TODO homeworks
export const getHomeworks = (id) => async (dispatch, getState) => {
  try {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    const { data } = await api.get(`/homeworks/${id}`)
    dispatch({ type: GET_HOMEWORKS, payload: data })
  } catch (ERR) {
    console.log(ERR)
  } finally {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};

export const updateHomeworks = (id, data) => async (dispatch, getState) => {
  try {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    await api.put(`/homeworks/${id}`, data)
  } catch (ERR) {
    console.log(ERR)
  } finally {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};

export const createHomeworks = (data) => async (dispatch, getState) => {
  console.log('data::: ', data);
  try {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    await api.post('/homeworks', data)
  } catch (ERR) {
    console.log(ERR)
  } finally {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};

export const deleteHomeworks = (id) => async (dispatch, getState) => {
  try {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    await api.delete(`/homeworks/${id}`)
  } catch (ERR) {
    console.log(ERR)
  } finally {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};


// <---- Электронаня зачетка  ---->  /////////////////////////////////////////////////////// API
//TODO DigitalExamBook


export const getDigitalExamBook = (data) => async (dispatch, getState) => {
  const urlParams = new URLSearchParams(data).toString();
  try {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    const { data } = await api.get(`/student_record_book?${urlParams}`)
    dispatch({ type: GET_DIGITAL_EXAM_BOOK, payload: data })
  } catch (ERR) {
    console.log(ERR)
  } finally {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};

// <---- Контроль ведения классного журнала  ---->  /////////////////////////////////////////////////////// API
// ПО ОДНОМУ ЗАПРОСУ ИДУТ ПОСЛЕДОВАТЕЛЬНО ВЫПАДАШКИ ГОД-УЧ ПЕРИОД-КЛАСС
export const getJournalControl = (data) => async (dispatch, getState) => {
  console.log('data::: ', data);
  const urlParams = new URLSearchParams(data).toString();
  try {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    const { data } = await api.get(`/journal_control?${urlParams}`)
    dispatch({ type: GET_JORNAL_CONTROL, payload: data })
  } catch (ERR) {
    console.log(ERR)
  } finally {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};
export const getJournalControlCourses = () => async (dispatch, getState) => {

  try {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    const { data } = await api.get(`/journal_control/courses`)
    dispatch({ type: GET_JORNAL_CONTROL_COURSES, payload: data })
  } catch (ERR) {
    console.log(ERR)
  } finally {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};

export const updateJournalControlElement = (id, data) => async (dispatch, getState) => {
  try {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    await api.put(`/journal_control/${id}`, data)
  } catch (ERR) {
    console.log(ERR)
  } finally {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};

export const createJournalControlElement = (data) => async (dispatch, getState) => {
  console.log('data::: ', data);
  const { gid, trmid } = data
  const payload = { gid, trmid }
  try {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    await api.post('/journal_control', data)
    dispatch(getJournalControl(payload))
  } catch (ERR) {
    console.log(ERR)
  } finally {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};


// <---- Домашние задания  ---->  /////////////////////////////////////////////////////// API
// ПО ОДНОМУ ЗАПРОСУ ИДУТ ПОСЛЕДОВАТЕЛЬНО ВЫПАДАШКИ ГОД-УЧ ПЕРИОД-КЛАСС
export const getHomeworkList = ({ gid, ...data }) => async (dispatch, getState) => {
  const urlParams = new URLSearchParams(data).toString();
  try {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    const { data } = await api.get(`/homework/${gid}?${urlParams}`)
    dispatch({ type: GET_HOMEWORK_LIST_BASEEDUC, payload: data })
  } catch (ERR) {
    console.log(ERR)
  } finally {
    // dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};