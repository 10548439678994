import * as types from "../actions/types";
import Healthgroup from "../pages/Healthgroup";



const initialState = {
  pageNumber: 0,
  pageCount: 0,
  count: 0,
  list: null,
  currentPeriod: {},
  filePNG: [],
  loading: false
};

// МЕДПУНКТ СПРАВОЧНИКИ

export default function directoryCanteen(state = initialState, action) {
  switch (action.type) {

    case types.GET_GROUP_NUTRITION_LIST:
      return {
        ...state,
        groupNutritionList: action.payload
      };
    case types.GET_GROUP_NUTRITION_PARALLELS:
      return {
        ...state,
        parallelList: action.payload
      };
    case types.GET_NUTRITION_GROUP:
      return {
        ...state,
        group: action.payload
      };

    case types.GET_PERIOD_NUTRITION_LIST:
      return {
        ...state,
        periodNutritionList: action.payload
      };
    case types.GET_NUTRITION_PERIOD:
      return {
        ...state,
        period: action.payload
      };
    case types.GET_NUTRITION_PERIOD_FILES:
      return {
        ...state,
        files: action.payload
      };
    case types.GET_NUTRITION_PERIOD_FILE:
      return {
        ...state,
        filePNG: action.payload
      };
    case types.GET_NUTRITION_PERIOD_CREATOR:
      return {
        ...state,
        forFile: action.payload // создание фотки
      };


    case types.GET_CANTEEN_STAFF_LIST:
      return {
        ...state,
        canteenStaffList: action.payload
      };
    case types.GET_CANTEEN_STAFF:
      return {
        ...state,
        canteenStaff: action.payload
      };
    case types.GET_CANTEEN_STAFF_FILE:
      return {
        ...state,
        image: action.payload
      };
    case types.GET_MEAL:
      return {
        ...state,
        meal: action.payload
      };
    case types.GET_DISH:
      return {
        ...state,
        dish: action.payload
      };
    case types.GET_MENU_ON_DATE:
      return {
        ...state,
        menuOnDate: action.payload
      };
    case types.GET_DISH_TYPE_LIST:
      return {
        ...state,
        dishTypeList: action.payload
      };
    case types.GET_DISH_FILE:
      return {
        ...state,
        dishFile: action.payload
      };


    case types.SET_CANTEEN_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}