import * as types from "../actions/types";


const initialState = {
  scheduleSettingsTeachersSubList: [],
  scheduleSettingsRoomsSubList: [],
  scheduleSettingsRoomsTeachersList: [],
  loading: false
};


export default function schedule_settings(state = initialState, action) {
  switch (action.type) {

    case types.GET_SCHEDULE_SETTINGS_LESSONS:
      return {
        ...state,
        scheduleSettingsLessons: action.payload
      };
    case types.GET_SCHEDULE_SETTINGS_CYCLE:
      return {
        ...state,
        cycle: action.payload
      };
    case types.GET_SCHEDULE_SETTINGS_ROLE:
      return {
        ...state,
        role: action.payload
      };

    case types.GET_SCHEDULE_SETTINGS_ROOMS:
      return {
        ...state,
        scheduleSettingsRooms: action.payload
      };
    case types.GET_SCHEDULE_SETTINGS_GROUPS:
      return {
        ...state,
        groups: action.payload
      };
    case types.GET_SCHEDULE_SETTINGS_DAY_OF_WEEK:
      return {
        ...state,
        dayOfWeek: action.payload
      };
    case types.GET_SCHEDULE_SETTINGS_STUDY_PERIOD:
      return {
        ...state,
        studyPeriod: action.payload
      };

    case types.GET_SCHEDULE_SETTINGS_TEACHERS_SUBJECTS:
      return {
        ...state,
        scheduleSettingsTeachersSubList: action.payload
      };
    case types.GET_SCHEDULE_SETTINGS_ROOMS_SUBJECTS:
      return {
        ...state,
        scheduleSettingsRoomsSubList: action.payload
      };
    case types.GET_SCHEDULE_SETTINGS_ROOMS_TEACHERS:
      return {
        ...state,
        scheduleSettingsRoomsTeachersList: action.payload
      };
    case types.GET_SCHEDULE_SETTINGS_COMPLEXITY_GROUPS_SUBJECTS:
      return {
        ...state,
        scheduleSettingsComplexityClassesSubjectsList: action.payload
      };
    case types.GET_SCHEDULE_SETTINGS_TEACHERS_LESSON:
      return {
        ...state,
        TeachersSubjectsList: action.payload
      };
    case types.GET_SCHEDULE_SETTINGS_WORKLOAD_TEACHERS:
      return {
        ...state,
        workloadList: action.payload
      };

    case types.SET_SCHEDULE_SETTINGS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}