import { api } from "../App";
import { SET_EVENT_LEVELS_LIST, SET_EVENT_LEVELS_LOADING, SET_HOLIDAYS_LIST } from "./types";




export const getHolidays = () => async (dispatch, getState) => {
  try {
    // dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
    const { data } = await api.get('/all_holidays');
    dispatch({ type: SET_HOLIDAYS_LIST, payload: data });
  } catch (ERR) {
  } finally {
    // dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
  }
}

export const createHolidays = (data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
    await api.post('/all_holidays', data);
    await dispatch(getHolidays())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
    return success
  }
}

export const updateHolidays = ({ hid, ...data }) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
    await api.put(`/all_holidays/${hid}`, data);
    await dispatch(getHolidays())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
    return success
  }
}

export const deleteHolidays = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
    await api.delete(`/all_holidays/${id}`);
    await dispatch(getHolidays())
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
  }
}