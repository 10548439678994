import classNames from 'classnames'
import React from 'react'
import styles from './styles.module.css'

export default function Qualification ({active}) {
  return (
    <div className={classNames(styles.tabWrapper, {[styles.dislplayNone]: !active})}>
      Qualification
    </div>
  )
}
