import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { getHealthgroup, deleteHealthgroup } from '../../actions/medCenter/healthGroups'


export default function Healthgroup() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { healthgroup } = useSelector(state => state.directoryMedCenter)
  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getHealthgroup())
  }, [])

  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteHealthgroup(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { hid, title } = data
    return (
      <tr >
        <td onClick={openModal(data)}>{title}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: hid, name: title })}><BiTrash /></i></td>
      </tr>
    )
  }

  return (
    <div className={styles.wrapper} style={{ width: '700px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      <Modal
        closeModal={closeModal}
        data={selectedItem}
        height={'430px'}
      />
      <h1>Группы здоровья</h1>

      <Table className={styles.tables}>
        <thead>
          <tr>
            <th>Название</th>
          </tr>
        </thead>
        <tbody>

          {healthgroup?.map(renderLi)}

        </tbody>
      </Table>

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
    </div>
  )
}
