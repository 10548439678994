import XLSX from "xlsx";
import { saveAs } from "file-saver";

export const getExcelFromTable = (table, fileName) => {
    const wb = XLSX.utils.book_new();
    wb.SheetNames.push("test");
    const ws = XLSX.utils.table_to_sheet(table);
    wb.Sheets["test"] = ws;
    const wbout = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });
    const s2ab = (s) => {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };
    saveAs(
      new Blob([s2ab(wbout)],
      { type: "application/octet-stream" }),
      `${fileName}.xlsx`
    );
  }