import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SettingsContext } from '../../../utils/context'
import { getExportData } from '../../../actions/settings/export'
import { Checkbox, Input, Select } from '../../../components/ModalInputs'
import styles from '../styles.module.scss'


const MAIL_TYPES = ['IMAP', 'POP3']

export default function Export() {
  
  const dispatch = useDispatch()
  const { export: data } = useSelector(state => state.settings)
  const { form } = useContext(SettingsContext)

  const { values, handleChange, handleBlur, setFieldValue } = form
  
  useEffect(() => {
    !data && dispatch(getExportData())
  }, [])
  
  
  return (
    <div className={styles.resultsWrapper}>
      
      <Input
        label='Почтовый адрес'
        name='cons_email'
        value={values.cons_email}
        onChange={handleChange}
      />

      <p>Входящая почта</p>

      <div className={styles.exportInputsRow}>

        <Select
          label='Тип'
          name='ConsServerType'
          value={values.ConsServerType}
          onChange={handleChange}
        >
          {MAIL_TYPES.map(type => <option key={type}>{type}</option>)}
        </Select>

        <Input
          label='Сервер'
          name='ConsPOP3Host'
          value={values.ConsPOP3Host}
          onChange={handleChange}
        />

        <Input
          label='Порт'
          name='ConsPOP3Host'
          value={values.ConsPOP3Host}
          onChange={handleChange}
        />

        <Checkbox label='Использовать TLS' />

        <Input label='Имя пользователя'/>
        
        <Input label='Пароль' type='password'/>
      
      </div>

      <p>Исходящая почта (SMTP)</p>

      <div className={styles.exportInputsRow}>

        <Input label='Сервер'/>

        <Input label='Порт'/>

        <Checkbox label='Использовать TLS' />

        <Input label='Имя пользователя'/>
        
        <Input label='Пароль' type='password'/>
      
      </div>

      <div style={{marginTop: '1rem'}}>
        <Input
          label='Почтовый адрес консолидированной базы'
          name='local_email_for_cons'
          value={values.local_email_for_cons}
          onChange={handleChange}
        />
      </div>

    </div>
  )
}
