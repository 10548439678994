import React from 'react'
import { useContext } from 'react'
import { CandidatesContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCandidateAdditionalEducation } from '../../../actions/candidates'
import styles from './styles.module.scss'
import Button from '../../../components/Button'
import { Select } from '../../../components/ModalInputs'
import { useFormik } from 'formik'
import { BiTrash } from 'react-icons/bi'
import Table from '../../../components/Table'



const contraindicationsList = [
  { name: '1 четверть', id: 3 },
  { name: '2 четверть', id: 4 },
  { name: '3 четверть', id: 5 },
  { name: '4 четверть', id: 6 },
]

export default function SpecNotes() {

  const { student, closeModal } = useContext(CandidatesContext)

  const dispatch = useDispatch()
  const { additional_education: data } = useSelector(state => state.candidates.data)

  useEffect(() => {
    // dispatch(getCandidateAdditionalEducation(student))
  }, [])
  const selected = (data) => () => {
    // setDiagnosisComplaint(data)
    // closeComplain()
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: data || {},
    enableReinitialize: true,
    onSubmit: console.log
  })

  const renderLi = (data) => {
    const { id, vac_name, type_name, doctor, ex_date, to_date, description, } = data
    return (
      <tr >
        {/* <td onClick={openModal(data)}>{name}</td> */}
        <td onClick={selected(data)}>{type_name}</td>
        <td onClick={selected(data)}>{vac_name}</td>
        <td onClick={selected(data)}>{doctor}</td>
        <td onClick={selected(data)}>{ex_date}</td>
        <td onClick={selected(data)}>{to_date}</td>
        <td onClick={selected(data)}>{description}</td>

        {/* <td><i className={styles.trash} onClick={() => setConfirmation({ id: id, name: type_name })}><BiTrash /></i></td> */}
      </tr>
    )
  }

  const renderOption = (option, index) => {
    const { xp_key, value, trmid, cid, gid, work_status, id, pmid, did, name, title, year, access, diagnosis, disease, iddischarge, discharge, c_vac_type } = option
    const optionValue = xp_key || value || trmid || cid || gid || work_status || id || pmid || did || disease || iddischarge || c_vac_type;
    const optionTitle = name || title || value || year || work_status || access || diagnosis || discharge;
    return <option key={`${optionValue}+${index}`} value={optionValue}>{optionTitle}</option>
  }

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>

          <div className={styles.inputsRow}>
            <Select
              label='Учебный период'
              name='term' //TODO mockDAta
              value={values.term}
              onChange={handleChange}
              style={{ width: '400px', marginBottom: '10px' }}
              options={contraindicationsList}
            >
              <option value=''>Выбрать</option>
              {contraindicationsList?.map(renderOption)}
            </Select>
          </div>
          <Table className={styles.tables}>
        <thead>
          <tr>
            <th></th>
            <th>Раздел</th>
            <th>Автор</th>
            <th>Четверть</th>
            <th>Внесено</th>
            <th>Содержание</th>
            <th>Родителям</th>
            <th>Преподавателям</th>
            <th>Воспитателям</th>
          </tr>
        </thead>
        <tbody>

          {/* {vacExclusions?.map(renderLi)} */}

        </tbody>
      </Table>
        </div>
      </div>
      <div className={styles.modalFooter}>
        <div>
          <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
        </div>
        <Button mode='white' onClick={handleSubmit}>Сохранить</Button>
        <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </>
  )
}
