import React from 'react'
import styles from './index.module.css'
import {Checkbox, Input} from '../../../../components/ModalInputs'
import moment from 'moment'

const Index = ({values, setValues}) => {

    const handleChange = (e) => {
        if (e.target.name === 'isbasic') {
            setValues({
                ...values,
                [e.target.name]: e.target.checked
            })
        } else {
            setValues({
                ...values,
                [e.target.name]: e.target.value
            })
        }
    }

    return (
        <div className={styles.tabWrapper}>
            <div className={styles.inputsRow}>
                <Input
                    error={values.name === '' ? 'Обязательное поле' : ''}
                    label='Название'
                    name='name'
                    value={values.name}
                    maxLength={50}
                    onChange={e => handleChange(e)}/>
            </div>

            <div className={styles.inputsRow}>
                <Input
                    error={values.edupart === '' ? 'Обязательное поле' : ''}
                    label='Ступень образования'
                    name='edupart'
                    maxLength={25}
                    value={values.edupart}
                    onChange={e => handleChange(e)}/>
            </div>

            <div className={styles.inputsRow}>
                <div className={styles.input}>
                    <Input
                        error={values.periodfrom === '' ? 'Обязательное поле' : ''}
                        type='date'
                        label='Период действия с'
                        name='periodfrom'
                        value={moment(values.periodfrom).format('YYYY-MM-DD')}
                        onChange={e => handleChange(e)}/></div>

                <div className={styles.input}>
                    <Input
                        error={values.periodtill === '' ? 'Обязательное поле' : ''}
                        type='date'
                        label='Период действия по'
                        name='periodtill'
                        value={moment(values.periodtill).format('YYYY-MM-DD')}
                        onChange={e => handleChange(e)}/></div>

                <div className={styles.input}>
                    <Input
                        error={values.periodkind === '' ? 'Обязательное поле' : ''}
                        label='Тип периода'
                        name='periodkind'
                        value={values.periodkind}
                        maxLength={20}
                        onChange={e => handleChange(e)}/></div>

                <div className={styles.checkbox}>
                    <Checkbox
                        label='Это Основной'
                        name='isbasic'
                        checked={values.isbasic}
                        onChange={e => handleChange(e)}/></div>
            </div>
            <div className={styles.inputsRow}>
                <Input
                    error={values.category === '' ? 'Обязательное поле' : '' }
                    type='text'
                    label='Категория'
                    name='category'
                    maxLength={9}
                    value={values.category}
                    onChange={e => handleChange(e)}/></div>
            <div className={styles.inputsRow}>
                <Input
                    type='text'
                    label='Предельно допустимая аудиторная учебная нагрузка при 6-дневной учебной неделе'
                    name='limit5'
                    value={values.limit5}
                    maxLength={9}
                    onKeyPress={e => !/[0-9]/.test(e.key) && e.preventDefault()}
                    onChange={e => handleChange(e)}/></div>
            <div className={styles.inputsRow}>
                <Input
                    type='text'
                    label='Предельно допустимая аудиторная учебная нагрузка при 5-дневной учебной неделе'
                    name='limit6'
                    value={values.limit6}
                    maxLength={9}
                    onKeyPress={e => !/[0-9]/.test(e.key) && e.preventDefault()}
                    onChange={e => handleChange(e)}/></div>
        </div>
    )
}

export default Index