import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox, Input, Select } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { createPeriodNutrition, getPeriodNutritionFiles, updatePeriodNutrition } from '../../../actions/directoryCanteen'
import { api } from '../../../App'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),
  sort: Yup.number().required('обязательное поле').typeError('Пожалуйста, введите число').nullable().max(999999999, 'Должно быть не более 9 символов'),
  xp_eat_group: Yup.string().required('обязательное поле'),
})

export default function Main({ closeModal, data, addMode }) {

  const dispatch = useDispatch()
  const { groupNutritionList, files } = useSelector(state => state.directoryCanteen)

  const [image, setImage] = useState(null);


  useEffect(() => {
    if (!files) return;
    fetchPhoto(files[0]?.file_name, values?.xp_key)
  }, [files])

  useEffect(() => {
    data?.xp_key && dispatch(getPeriodNutritionFiles(data.xp_key))
  }, [data])

  const handleCreate = async (values) => {
    dispatch(createPeriodNutrition(values, image))
    closeModal()
  }

  const handleUpdate = async ({ xp_key, ...values }) => {
    dispatch(updatePeriodNutrition(xp_key, values, image))
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    // validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const handlePick = () => {
    document.getElementById('filePicker').click()
  }

  const handleChangeFile = (e) => {
    setImage(Object.values(e.target.files)[0])
  }

  const fetchPhoto = async (file_name, id) => { // file_name ИЗ-ЗА МАССИВА С ФОТО ОТ СЕРВЕРА
    const { data } = id && await api.get(`/xp_eat_period/file/${id}/${file_name}`, { responseType: 'blob' })
    // const url = URL.createObjectURL(data)
    // dispatch({ type: GET_NUTRITION_PERIOD_FILE, payload: url })
    setImage(data)
  }

  return (
    <>
      <div className={styles.modalMain} >
        <div className={styles.tabWrapper} style={{ display: "flex", justifyContent: 'space-evenly', flexDirection: 'column' }}>

          <div className={styles.inputsRow}>
            <Input
              label='Название'
              name='name'
              value={values.name}
              error={error('name')}
              onChange={handleChange}
              style={{ width: '300px' }}
            />
            <div style={{ padding: '15px' }}></div>
            <Select
              label='Группа питания'
              value={values.xp_eat_group}
              error={error('xp_eat_group')}
              onChange={handleChange}
              name='xp_eat_group'>
                <option value=''>Выберите группу</option>
              {groupNutritionList?.map(({ name, xp_key }) => <option value={xp_key}>{name}</option>)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Сортировка'
              name='sort'
              value={values.sort}
              error={error('sort')}
              onChange={handleChange}
              style={{ width: '300px' }}
            />
            <div style={{ padding: '15px' }}></div>
            <Input type='time' label='Начало' value={values.start} name='start' onChange={handleChange} />
            <Input type='time' label='Окончание' value={values.stop > values.start ? values.stop : values.start} name='stop' onChange={handleChange} />
            {/* <Input type='time' label='Количества работ' value={amountOfWork} onChange={e => setAmountOfWork(e.target.value)} /> */}
          </div>
          <Checkbox
            label='Отображать калории'
            checked={values.kkalvisible}
            value={values.kkalvisible}
            error={error('kkalvisible')}
            onChange={handleChange}
            name='kkalvisible'
            style={{ margin: '15px' }}
          />
          <div className={styles.buttons_wrap}>
            <div className={styles.buttons}>
              <>
                <Button onClick={handlePick}>{image ? 'Изменить фото' : 'Загрузить фото'}</Button>
                <input
                  className={styles.hidden}
                  type='file'
                  onChange={(e) => handleChangeFile(e)}
                  accept="image/*"
                  id='filePicker'
                />
              </>

            </div>
          </div>
          <br />
          {!!image &&
            <img
              src={URL.createObjectURL(image)}
              alt="0_o"
              height={350}
              width={450}
            />
          }
        </div>
      </div>

      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}
