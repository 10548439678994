import * as types from "../actions/types";
import periods from "./periods";



const initialState = {
  periods: [],
  groups: [],
  courses: [],
  courses_all: [],
  data: null,
  loading: false
};


export default function journal_control (state = initialState, action) {
  switch (action.type) {
    
    case types.SET_JOURNAL_CONTROL_PERIODS:
      return {
        ...state,
        periods: action.payload,
      };
    
    case types.SET_JOURNAL_CONTROL_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    
    case types.SET_JOURNAL_CONTROL_COURSES:
      return {
        ...state,
        courses: action.payload,
      };
    
    case types.SET_JOURNAL_CONTROL_COURSES_ALL:
      return {
        ...state,
        courses_all: action.payload,
      };
    
    case types.SET_JOURNAL_CONTROL_DATA:
      return {
        ...state,
        data: action.payload,
      };
    
    case types.SET_JOURNAL_CONTROL_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
  
    default:
      return state;
  }
}