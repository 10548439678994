import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { deleteManners, getJournalControl, getJournalMannersList, getMannersList } from '../../actions/directoryBasicEducation'
import Select from '../../components/Select'
import { getCurrentTerm, getTermsList } from '../../actions/terms'
import { getSchoolYearsList } from '../../actions/schoolYears'
import ClassbookTable from '../../components/ClassbookTable'
import moment from 'moment'
import { getCurrentPeriodUtils, getCurrentYearUtils, getCurrentTermUtils } from '../../utils/classBook'
import TeacherInfo from '../Classbook/TeacherInfo/TeacherInfo'
import SelectPal from '../../components/Select copy'
import { useFormik } from 'formik'
import { arrayForSelect } from '../../utils/arrayForSelect'



export default function JournalManners() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { mannersList, journalControl, journalMannersList } = useSelector(state => state.directoryBasicEducation)
  const { list: yearList } = useSelector(state => state.school_years)
  const { list: termList } = useSelector(state => state.terms)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);
  const [data, setData] = useState();
  const [classSel, setClassSel] = useState();
  const [scYear, setScYear] = useState()
  const [termSel, setTermSel] = useState()
  const [curManners, setCurManners] = useState()

  const teacherFIO = journalMannersList?.teachers

  useEffect(() => {
    dispatch(getSchoolYearsList())
    dispatch(getMannersList())
  }, [])

  useEffect(() => {
    yearList && setScYear(getCurrentYearUtils(yearList, moment()))
    // setTermSel(getCurrentPeriodUtils(scYear))
  }, [yearList])

  useEffect(() => {
    // yearList && setScYear(getCurrentYearUtils( yearList, moment()))
    // !!termList &&  setTermSel(getCurrentPeriodUtils(termList))
    termList && setTermSel(getCurrentTermUtils(termList, moment()))
  }, [termList])

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: {},
  })

  useEffect(() => {
    values.xp_key && dispatch(getTermsList(values.xp_key))
    !!values.trmid && values.xp_key && setFieldValue('trmid', 0)
  }, [values.xp_key])

  useEffect(() => {
    values.trmid && dispatch(getJournalControl({ trmid: values.trmid }))
  }, [values.trmid])

  useEffect(() => {
    // termSel && classSel && dispatch(getJournalControl({ trmid: termSel, gid: classSel }))
  }, [classSel])

  useEffect(() => {
    journalControl?.[0]?.gid && setData(journalControl)
  }, [journalControl])

  useEffect(() => {
    curManners && dispatch(getJournalMannersList({ trimestr: termSel, manner_id: curManners, group_id: classSel }))
  }, [curManners])

  const openModal = (data) => () => {
    setSelectedItem(data || {})
    termSel && classSel && dispatch(getJournalMannersList({ trimestr: termSel, manner_id: curManners, group_id: classSel }))
    // dispatch(getJournalControl({ trmid: termSel, gid: classSel, cid: data?.jmid }))
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteManners(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  // const handleInputChange = (data) => {
  //   const { xp_key, trmid, gid, id } = data

  //   if (xp_key) setScYear(xp_key)
  //   if (trmid) setTermSel(trmid)
  //   if (gid) setClassSel(gid)
  //   if (id) setCurManners(id)

  // }


  return (
    <div className={styles.wrapper} style={{ width: '80vw', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {selectedItem &&
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          height={'430px'}
          // addMode={(!selectedItem?.name && selectedItem !== null)}
          addMode={!selectedItem.name}
        />
      }
      <h1>Журнал воспитанности</h1>
      <div>

        <SelectPal
          title="Учебный год"
          options={yearList && arrayForSelect(yearList, 'name', 'xp_key')}
          valuesName="xp_key"
          values={values}
          setFieldValue={setFieldValue}
        />
        {
          console.log('values::: ', values)
        }
        <SelectPal
          title="Учебный период"
          valuesName="trmid"
          values={values}
          options={termList ? arrayForSelect(termList, 'title', 'trmid') : [{ name: "Необходимо выбрать учебный год", trmid: 0 }]}
          // options={termList ? arrayForSelect(termList, 'title', 'trmid') : [{ name: "Необходимо выбрать учебный год", trmid: 0 }]}
          setFieldValue={setFieldValue}
        />

        <SelectPal
          title="Критерии"
          valuesName="criteria"
          options={mannersList && arrayForSelect(mannersList, 'name', 'id')}
          // onChange={handleChange}
          setFieldValue={setFieldValue}
        />
        <SelectPal
          title="Класс"
          valuesName="gid"
          // options={data ? arrayForSelect(data, 'f', 'gid') : !!data.length  ? [{ name: "Необходимо выбрать учебный период", trmid: 0 }]}
          options={!!data?.length && arrayForSelect(data, 'f', 'gid')}
          onChange={(e) => handleChange(e)}
          setFieldValue={setFieldValue}
          disabled={!data?.length}
        />

        <TeacherInfo
          // class_teacher_fio={teacherFIO[0].lastname}
          teacher_fio={teacherFIO?.[0]?.lastname}
        />

      </div>

      {
        journalMannersList &&
        <JournalMannersTable
          classbook={journalMannersList.journal_result}
        // formData={formData}
        // lesson_chart={lesson_chart}
        // class_topic={class_topic}
        // handleClassTopic={handleClassTopic} 
        />
      }

      {/* <Table className={styles.tables}>
        <thead>
          <tr>
            <th>Месяц, число</th>
            <th>Замечания и предложения проверяющих</th>
            <th>Отметка о выполнении</th>
            <th>Предмет</th>
            <th>Класс</th>

            <th></th>
          </tr>
        </thead>
        <tbody>

          {journalControl?.[0]?.date && journalControl?.map(renderLi)}

        </tbody>
      </Table> */}

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
    </div>
  )
}


const JournalMannersTable = ({ classbook }) => {
  const allWeeks = classbook.map(week => week.week_id)
  const weeks = Array.from(new Set(allWeeks));

  const dateWeeks = weeks.map((date, i) => classbook.find(el => el.week_id === date))

  const uniqStud = (options) => {
    return options.reduce(
      (res, cur) =>
        res.find((find) => JSON.stringify(find.mid) === JSON.stringify(cur.mid))
          ? res
          : [...res, cur],
      []
    )
  };
  const listStudent = (uniqStud(classbook).filter((el) => el.mid !== 99999))
  const avBall = uniqStud(classbook).find((el) => el.mid === 99999)
  const listAll = [...listStudent, avBall]

  const handleOpenModal = (data) => {
    console.log('data::: ', data);

  }

  return (
    <div >
      <div className={styles.tableJournal}>
        <br />
        <table style={{ borderCollapse: 'collapse', }}>
          <thead style={{
            border: "1px solid black",
            textAlign: "left",
            fontSize: '13px',
          }}>
            <tr>
              <td rowSpan={2} style={{ width: "70px", textAlign: "center" }} bgcolor="#85aee7">№ п.п.</td>
              <td rowSpan={2} style={{ backgroundColor: "#85aee7", textAlign: "center" }}>Фамилия И.О.</td>
              <td colSpan={weeks.length - 1} bgcolor="#85aee7" style={{ textAlign: 'center' }} >Недели</td>
              <td bgcolor="#85aee7" style={{ textAlign: 'center' }} >Итоги</td>

            </tr>
            <tr>
              {!!dateWeeks && dateWeeks.map(week =>
                <td
                  onClick={() => handleOpenModal(week)}
                  bgcolor="#85aee7"
                  style={{ textAlign: 'center' }}
                >
                  {week.weekstart !== null ?
                    (
                      <>
                        {moment(week.weekstart).format("DD.MM")}
                        < br />
                        {moment(week.weekend).format("DD.MM")}
                      </>
                    )
                    : week.weekname
                  }
                </td>
              )}
            </tr>
          </thead>
          <tbody>
            {!!listAll.length &&
              listAll.map((el, i) => {
                const allStudentGrades = classbook.filter((student) => student.mid === el.mid)
                return (
                  <tr>
                    {el.student !== "Ср. балл" ?
                      <td style={{ border: "1px solid black", width: "70px", textAlign: "center", }}>{i + 1}</td>
                      :
                      <td></td>
                    }
                    <td
                      className={styles.student}
                      style={{
                        border: "1px solid black",
                        textAlign: "left",
                        fontSize: '13px',
                      }}
                    >
                      {el.student}
                    </td>
                    {dateWeeks.map(({ week_id }, i) => {
                      let grade = allStudentGrades.find((grade) => grade.week_id === week_id)
                      return (

                        <td style={{ border: "1px solid black", width: "70px", textAlign: "center" }}>{grade?.grade !== null ? grade?.grade : ''}</td>
                      )
                    })}
                  </tr>
                )
              })


            }
          </tbody>
        </table>
      </div>

    </div>
  )
}