import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { createJournalControlElement, deleteManners, getJournalControl, getJournalControlCourses, getMannersList, updateJournalControlElement, } from '../../actions/directoryBasicEducation'
import Select from '../../components/Select copy'
import { getCurrentTerm, getTermsList } from '../../actions/terms'
import { getSchoolYearsList } from '../../actions/schoolYears'


export default function JournalControl() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { mannersList, journalControl } = useSelector(state => state.directoryBasicEducation)
  const { list: yearList } = useSelector(state => state.school_years)
  const { list: termList } = useSelector(state => state.terms)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);
/// не совпадают содержание и найминг 
  const [data, setData] = useState();
  const [classSel, setClassSel] = useState();
  const [scYear, setScYear] = useState()
  const [termSel, setTermSel] = useState()

  useEffect(() => {
    dispatch(getSchoolYearsList())
    dispatch(getJournalControlCourses())
  }, [])

  useEffect(() => {
    scYear && dispatch(getTermsList(scYear))
  }, [scYear, termSel, classSel])

  useEffect(() => {
    termSel && dispatch(getJournalControl({ trmid: termSel }))
  }, [termSel ])

  useEffect(() => {
    termSel && classSel && dispatch(getJournalControl({ trmid: termSel, gid: classSel }))
  }, [ classSel])

  useEffect(() => {
      journalControl?.[0]?.gid && setData(journalControl)
  }, [journalControl])

  const openModal = (data) => () => {
    console.log('data::: ', data);
    setSelectedItem(data || {})
    termSel && classSel && dispatch(getJournalControl({ trmid: termSel, gid: classSel, cid: data?.jmid }))
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteManners(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { id, date, checktext,xp_f_get_mid_fio, subject, group} = data
    return (
      <tr >
        <td onClick={openModal(data)}>{date}</td>
        <td onClick={openModal(data)}>{checktext}</td>
        <td onClick={openModal(data)}>{xp_f_get_mid_fio}</td>
        <td onClick={openModal(data)}>{subject}</td>
        <td onClick={openModal(data)}>{group}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: id, name: date })}><BiTrash /></i></td>
      </tr>
    )
  }

  const handleInputChange = (data) => {
    console.log('data::: ', data);
    const { name, value, begdate, xp_key, term_end, term_begin, trmid, fio, mid, gid } = data

    if (xp_key) setScYear(xp_key)
    if (trmid) setTermSel(trmid)
    if (gid) setClassSel(gid)
    // const {name, value, xp_key, } = e
    // setData({
    //     ...data,
    //     [name]: value
    // })
  }

  const handleCreate = (values) => {
    console.log('handleCreate::: ', values);
    dispatch(createJournalControlElement({ trmid: termSel, gid: classSel, ...values}))
    closeModal()
  }

  const handleUpdate = (values) => {
     dispatch(updateJournalControlElement(values))
    closeModal()
  }

  return (
    <div className={styles.wrapper} style={{ width: '1200px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {selectedItem &&
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          classGroup ={data}
          height={'430px'}
          // addMode={(!selectedItem?.name && selectedItem !== null)}
          addMode={!selectedItem.subject}
          handleCreate={handleCreate}
          handleUpdate={handleUpdate}
        />
      }
      <h1>Контроль ведения классного журнала</h1>

      <Select
        title="Учебный год"
        name="year"
        options={[{ name: "Выбрать", xp_key: 0 }, ...yearList]}
        onChange={handleInputChange}
      />
      { }
      <Select
        title="Учебный период"
        name="trmid"
        options={termList ?  [{ name: "Выбрать", trmid: 0 }, ...termList] : [{name: "Необходимо выбрать учебный год", trmid:0}]}
        onChange={(e) => handleInputChange(e)}
      />

      <Select
        title="Класс"
        name="gid"
        options={data ? [{ name: "Выбрать", gid: 0 }, ...data] : [{name: "Необходимо выбрать учебный период", trmid:0}]}
        onChange={(e) => handleInputChange(e)}
      />

      <Table className={styles.tables}>
        <thead>
          <tr>
            <th>Месяц, число</th>
            <th>Замечания и предложения проверяющих</th>
            <th>Отметка о выполнении</th>
            <th>Предмет</th>
            <th>Класс</th>
           
            <th></th>
          </tr>
        </thead>
        <tbody>

          {journalControl?.[0]?.date && journalControl?.map(renderLi)}

        </tbody>
      </Table>

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
    </div>
  )
}
