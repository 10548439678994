import React, { useState } from 'react'
import styles from './styles.module.css'
import Select from '../../../components/ModalInputs/Select'
import {Input} from '../../../components/ModalInputs'
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import moment from 'moment';

const Planning = () => {

    const [data, setData] = useState({
      date: new Date(),
      lesson: 'География 6, класс - 6А',
      type: '0',
    })

    const nodes = [
      {
        key: '0',
        id: '0',
        data: {
          content: 'География 6, класс - 6А',
          number: '1',
          hours: '2',
          result: 'тест',
        },
        label: 'География 6, класс - 6А',
        children: [
          {
            id: '0-0',
            key: '0-0',
            data: {
              content: 'География 6, класс - 6А',
              number: '2',
              hours: '5',
              result: 'тест',
            },
            children: [
              {
                id: '0-0-0',
                key: '0-0-0',
                data: {
                  content: 'География 6, класс - 6А',
                  number: '3',
                  hours: '10',
                  result: 'тест',
                }, 
              }
            ]
          }
        ]
      }
    ]

  return (
    <>
    <div className={styles.wrapperPlanning}>

      <Input type='text' label='Предмет' value={data.lesson} disabled/> 

        <Select  onChange={e => setData({...data, type: e.target.value})} type='text' label='Тип занятия'>
          <option value='0'>Занятие</option>  
          <option value='1'>Занятие2</option>  
          <option value='1'>Занятие3</option>  
        </Select>

        <Input 
          type='date' 
          label='Дата по факту' 
          onChange={e => setData({...data, date: moment(e.target.value).format('YYYY-MM-DD')})} 
          value={data.date}/>

      </div>

      <TreeTable value={nodes} tableStyle={{ margin: '.5rem auto', width: '98%' }}>
      <Column field="content" header="Содеражание курса" expander></Column>
      <Column field="number" header="№ урока"></Column>
      <Column field="hours" header="Кол-во часов"></Column>
      <Column field="result" header="Планирумые результаты"></Column>
      </TreeTable>
    </>
  )
}

export default Planning