import React, { useEffect, useState } from 'react'
import styles from './index.module.css'

import {useDispatch, useSelector} from 'react-redux'
import { Input, Select } from '../../../../ModalInputs';
import { getEventTools } from '../../../../../actions/classbook';

const TabPlan = ({handleInputChange}) => {

    const dispatch = useDispatch();
    const { class_topic, event_tools } = useSelector(state => state.classbook);

    useEffect(() => {
        dispatch(getEventTools());
    }, [])

    const [type, setType] = useState('');
    const [selectedLesson, setSelectedLesson] = useState('');
    const [expandedNodes, setExpandedNodes] = useState([]);
    const [amountOfWork, setAmountOfWork] = useState(0);

    const toggleNode = (oid) => {
      if (expandedNodes.includes(oid)) {
        setExpandedNodes(expandedNodes.filter(node => node !== oid));
      } else {
        setExpandedNodes([...expandedNodes, oid]);
      }
    };
  
    const renderTreeNode = (node) => {
      const hasChildren = class_topic?.some(item => item.prev_ref === node.oid);
      const isExpanded = expandedNodes.includes(node.oid);
  
      return (
        <div key={node.oid} className={styles.node}>
          <div onClick={() => toggleNode(node.oid)}>
            {node.title}
            {hasChildren && <span className={styles.arrow}>{isExpanded ? ' -' : ' +'}</span>} 
          </div>
          <span className={styles.checkbox} onClick={() => setSelectedLesson(node.oid)}>
              <input type='checkbox' checked={selectedLesson === node.oid} />
          </span>
          {isExpanded && renderChildren(node.oid)}
        </div>
      );
    };
  
    const renderChildren = (prevRef) => {
      const children = class_topic?.filter(item => item.prev_ref === prevRef);
      if (children.length === 0) return null;
      return (
        <div style={{ marginLeft: '20px'}} className={styles.nodes}>
          {children.map(child => renderTreeNode(child))}
        </div>
      );
    };
  
    // Find the root node with prev_ref equal to -1
    const roots = class_topic?.filter(item => item.prev_ref === -1);

  return (
    <div className={styles.wrapper}>
        <h1>Тип занятия</h1>
        <div  className={styles.paramsBlockClassbook}>

            <div className={styles.treeBlock}>
            <div className={styles.treeTitle}>Выберите тему занятия:</div>
            {roots?.map(root => renderTreeNode(root))}
            </div>

        <div>
            <div>
                <Select onChange={e => setType(e.target.value)} label='Тип занятия' value={type}>
                    {event_tools?.map(el => <option value={el.typeid}>{el.typename}</option>)}
                </Select>
            </div>
        </div>

        <div className={styles.amountOfWork}>
            {/* <Input type='number' label='Количества работ' value={amountOfWork} onChange={e => setAmountOfWork(e.target.value)} /> */}
            <Input type='number' label='Количество работ' value={amountOfWork} name={"amountOfWork"} onChange={e => handleInputChange(e)} />
        </div>
        
        </div>
    </div>
  )
}

export default TabPlan