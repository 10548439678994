import {BACKEND_URL_PREFIX, itemsOnPage, recipientActionTypes} from "../assets/const";
import {createNotification} from "../components/Notifications/Notifications";
import {checkAndUpdateToken} from "./auth";
import {buildDefaultHeaders} from "./helper";
import {
    SET_FAQ,
    SET_FAQ_LOADING,
    SET_FAQ_BY_ID,
    SET_FAQ_BY_ID_LOADING,
    SET_FIO,
    SET_MESSAGE,
    SET_MESSAGE_LOADING,
    SET_FAQ_FILES,
    SET_FAQ_PAGE,
    SET_FAQ_COUNT
} from "./types";

export const setPage = (pageNumber) => ({type: SET_FAQ_PAGE, payload: pageNumber })

export const getFAQ = (params) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());

    const { pageNumber } = getState().FAQ
    const max_results = itemsOnPage
    const paginate_from = itemsOnPage * pageNumber
    let copyParams = {
        ...params,
        to_date: `${params.to_date}  23:59:59`,
        max_results: max_results,
        paginate_from: paginate_from
    }

    const urlParams = new URLSearchParams(copyParams).toString();

    if (getState().auth.token) {
        dispatch({type: SET_FAQ_LOADING, payload: true});

        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/question_and_answer?${urlParams}`, {
                method: "GET",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                const {count, dialogues: data} = await response.json();
                const pageCount = Math.ceil(count / itemsOnPage)
                
                dispatch({type: SET_FAQ, payload: data});
                dispatch({ type: SET_FAQ_COUNT, payload: {pageCount, count} });
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            dispatch({type: SET_FAQ_LOADING, payload: false});
        }
    }
};

export const getFioList = () => async(dispatch, getState) => {

    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/fio`, {
            method: "GET",
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            const data = await response.json();
            dispatch({type: SET_FIO, payload: data});
        }
    }
};

export const getFAQById = (id) => async(dispatch, getState) => {
    dispatch({type: SET_FAQ_BY_ID_LOADING, payload: true});

    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/posts/${id}`, {
            method: "GET",
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            const data = await response.json();
            dispatch({type: SET_FAQ_BY_ID, payload: data});
        }
    }
    dispatch({type: SET_FAQ_BY_ID_LOADING, payload: false});
};

export const createFAQ = (data, params, files) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/question_and_answer`, {
                method: "POST",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                const data = await response.json();
                dispatch(addFiles(data.dialogue_id, data.message_id, files))
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getFAQ(params));
        }
    }
};

export const editFAQ = (data, params, id) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/question_and_answer/${data.postid || id}`, {
                method: "PUT",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getFAQ(params));
        }
    }
};

export const deleteFAQ = (id, from, to) => async(dispatch, getState) => {
    // await dispatch(checkAndUpdateToken());
    // if (getState().auth.token) {
    //     try {
    //         const response = await fetch(`${BACKEND_URL_PREFIX}/question_and_answer/${id}`, {
    //             method: "DELETE",
    //             ...buildDefaultHeaders(getState)
    //         });
    //         if (response.ok) {
    //             createNotification('success', 'Успешно');
    //         } else {
    //             createNotification('error', 'Ошибка');
    //         }
    //     } catch (error) {
    //         createNotification('error', 'Ошибка');
    //     } finally {
    //         await dispatch(getFAQ(from, to));
    //     }
    // }
};

export const getRecipient = (recipientType) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    
    if (getState().auth.token) {
        dispatch({type: SET_MESSAGE_LOADING, payload: true});

        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/question_and_answer/${(recipientActionTypes[recipientType]).toLowerCase()}`, {
                method: "GET",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                const data = await response.json();
                    dispatch({type: `SET_${[recipientActionTypes[recipientType]]}`, payload: data});
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            dispatch({type: SET_MESSAGE_LOADING, payload: false});
        }
    }
};

/// MESSAGE ACTION 

export const getMessage = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());

    if (getState().auth.token) {
        dispatch({type: SET_MESSAGE_LOADING, payload: true});

        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/question_and_answer/${id}`, {
                method: "GET",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                const data = await response.json();
                dispatch({type: SET_MESSAGE, payload: {id, data}});
                data?.map(element => {
                    dispatch(getFiles(id, element.id))
                });
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            dispatch({type: SET_MESSAGE_LOADING, payload: false});
        }
    }
};

export const createMessage = (data, files) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/messages`, {
                method: "POST",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                const newdata = await response.json();
                dispatch(addFiles(data.chat, newdata.id, files))
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getMessage(data.chat));
        }
    }
};

export const editMessage = (data, chatID, id, files, filesDelete) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/messages/${id}`, {
                method: "PUT",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                dispatch(addFiles(chatID, id, files))
                if (filesDelete?.length) {
                    filesDelete.map(element =>
                        dispatch(deleteFiles(chatID, id, element.file_name))
                    )
                }
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getMessage(chatID));
        }
    }
};

export const deleteMessage = (id, chat) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/messages/${id}`, {
                method: "DELETE",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getMessage(chat));
        }
    }
};

/// MESSAGE ACTION 


/// FILES

export const getFiles = (chatID, messageID) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());

    if (getState().auth.token) {
        dispatch({type: SET_MESSAGE_LOADING, payload: true});

        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/messages/file/${chatID}/${messageID}`, {
                method: "GET",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                const data = await response.json();
                const files = data?.files
                if (messageID, files) {
                    dispatch({type: SET_FAQ_FILES, payload: {messageID, files}});
                }
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            dispatch({type: SET_MESSAGE_LOADING, payload: false});
        }
    }
};

export const addFiles = (chatID, messageID, data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    const fd = new FormData();
    
    for (let file of data) { fd.append('files', file); }
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/messages/file/${chatID}/${messageID}`, {
        method: "POST",
        body: fd,
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
        },
      });
      if (response.ok) {
        createNotification('success', 'Успешно');
        // await dispatch(getPlannerF(week, sheid));
      }
    }
  };


  export const deleteFiles = (chatID, messageID, fileName) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/messages/file/${chatID}/${messageID}/${fileName}`, {
                method: "DELETE",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно удалено');
            } else {
                createNotification('error', 'Ошибка удаления');
            }
        } catch (error) {
            createNotification('error', 'Ошибка удаления');
        } finally {
            // await dispatch(getMessage(chat));
        }
    }
};

/// FILES