import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import Modal from 'react-responsive-modal';
import { useDispatch, useSelector } from 'react-redux';
import { getTFOList, } from '../../../actions/rooms';
import Button from '../../../components/Button';
import Main from './Tabs/Main';
import Equipment from './Tabs/Equipment';
import { getTeachersList } from '../../../actions/common';
import { getRoomTypesList } from '../../../actions/roomTypes';

export default function ModalComponent({ modalOpen, closeModal, handleSubmit, setModalOpen, handleChange, values, error }) {

  const dispatch = useDispatch();
  const { tfo_list, tfo_types } = useSelector(state => state.rooms);
  const { list } = useSelector(state => state.roomTypes);
  const { teachers } = useSelector(state => state.common);

  const [activeTab, setActiveTab] = useState(1); // 1 - Main, 2 - Equipment

  useEffect(() => {
    dispatch(getTFOList());
    dispatch(getTeachersList());
    dispatch(getRoomTypesList());
  }, [])

  useEffect(() => {
    setActiveTab(1)
  }, [modalOpen])


  const handleTab = (tab) => {
    setActiveTab(tab);
  }

  const getTab = (selectedTab) => {
    switch (selectedTab) {
      case 1: return <Main
        teachers={teachers}
        roomTypes={list}
        tfo_list={tfo_list}
        tfo_types={tfo_types}
        modalOpen={modalOpen}
        closeModal={closeModal}
        // handleSubmit={handleSubmit}  
        setModalOpen={setModalOpen}
        handleChange={handleChange}
        values={values}
        error={error}
      />
      case 2: return <Equipment modalOpen={modalOpen} />
    }
  }

  return (
    <Modal
      close={closeModal}
      open={modalOpen}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      showCloseIcon={false}
      animationDuration={1}
      classNames={{
        modal: styles.roomTypesModal
      }}
      style={{
        padding: '0',
        margin: '0'
      }}>

      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.title}>{modalOpen
            ?.rid
            ? 'Редактирование'
            : 'Добавление'}</div>
          <div className={styles.tabs}>
            <Button mode={activeTab === 1 ? '' : 'white'} onClick={() => handleTab(1)}>Основная</Button>
            {modalOpen.rid && <Button mode={activeTab === 2 ? '' : 'white'} onClick={() => handleTab(2)}>Оснащенность</Button>}
          </div>
          {getTab(activeTab)}
        </div>
      </div>
      <div className={styles.modalFooter}><ModalFooter isShowInfo={false} handleSubmit={handleSubmit} closeModal={closeModal} /></div>
    </Modal>
  )
}
