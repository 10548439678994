import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setPage, getSchoolYearsList } from '../../actions/schoolYears'
import Table from './Table/Table'
import { getEducationalStructures } from '../../actions/educationalStructure'
import StructureTree from './StructureTree';
import { list2tree, tableItem } from "../../utils/programModules";
import Modal from './Modal'
import { EducationalStructureContext } from '../../utils/context'




export default function EducationalStructure() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { staff } = useSelector(state => state.educational_structure)
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = (row) => setModalOpen(row)

  const handleOpenModal = (data) => {
    // handleClassTopic(formData.scYear, data.sheid);
    openModal(data);
  }

  const closeModal = () => setModalOpen(null)

  useEffect(() => {
    dispatch(setPage(0));
    dispatch(getEducationalStructures());
  }, [])


  function buildHierarchy(data) {
    const map = new Map(); // Создаем карту для быстрого доступа к элементам по их did

    // Сначала заполняем карту
    data.forEach(item => {
      item.children = []; // Инициализируем детей как пустой массив
      map.set(item.did, item);
    });

    // Затем строим иерархию
    data.forEach(item => {
      if (item.owner_did !== 0 && map.has(item.owner_did)) {
        const parent = map.get(item.owner_did);
        parent.children.push(item); // Добавляем текущий элемент в список детей родителя
      }
    });

    // Возвращаем только те элементы, которые являются корнями иерархии (их owner_did равен 0)
    return data.filter(item => item.owner_did === 0);
  }

  const hierarchy = staff && buildHierarchy((JSON.parse(JSON.stringify(staff))));

  const goHome = () => navigate('/')


  return (

    <div className={styles.wrapper}>
      <Modal
        modalOpen={modalOpen}
        isOpen={!!modalOpen}
        closeModal={closeModal}
      // addMode={addMode}
      // person={person} //выбранный препод
      />
      <h1>Учебная структура</h1>
      <div >
        <Table
          initialData={hierarchy}
          staff={staff}
          // updateData={updateData} // ОЧЕРЕДЬ ДЕТЕЙ
          // params={formInfo}
          handleOpenModal={handleOpenModal}
        />
      </div>
      {/* <StructureTree structure={hierarchy ? hierarchy : []} /> */}
    </div>
  )
}