import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd, IoIosSave } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import { useState } from 'react'
import Modal from './Modal'
import { setPage, getPeriodsList, deletePeriod, copyPeriod } from '../../actions/periods'
import Table from '../../components/Table'
import { getSchoolYears, getShifts } from '../../actions/common'
import { Select } from '../../components/ModalInputs'
import Confirmation from '../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi'
import { getCurrentYearUtils } from '../../utils/classBook'


export default function Periods() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { list, loading } = useSelector(state => state.periods)

  const { shifts = [] } = useSelector(state => state.common)

  const [selectedItem, setSelectedItem] = useState(null)
  const { schoolYears } = useSelector(state => state.common)

  const [search, setSearch] = useState(null)
  const [periodForDelete, setPeriodForDelete] = useState(null)
  const [forCopyPeriod, setForCopyPeriod] = useState(null)

  useEffect(() => {
    dispatch(getSchoolYears())
    dispatch(getShifts())
  }, [])

  useEffect(() => {
    setSearch({ year: getCurrentYearUtils(schoolYears), shift: '0' })
  }, [schoolYears])

  useEffect(() => () => dispatch(setPage(0)), [])

  const handleSearch = (name, value) => {
    setSearch(prev => ({ ...prev, [name]: value }));
  }

  useEffect(() => {
    if (search !== null) dispatch(getPeriodsList({ year: search.year, shift: search.shift }));
  }, [search]);

  const openModal = (data) => () => {
    if (data?.length) {
      setForCopyPeriod(search)
    } else setSelectedItem(data || {})
  }

  const closeModal = () => {
    setSelectedItem(null)
    setForCopyPeriod(null)
  }


  const goHome = () => navigate('/')

  const handleAdd = () => {
    console.log('add')
  }

  const renderRow = (data) => {
    const { name, s_year_id, starttime, stoptime, lid, status, dopstatus, outstatus } = data
    return (
      <tr>
        <td onClick={openModal(data)}>{name}</td>
        <td onClick={openModal(data)}>{status}</td>
        <td onClick={openModal(data)}>{dopstatus}</td>
        <td onClick={openModal(data)}>{outstatus}</td>
        <td onClick={openModal(data)}>{starttime}</td>
        <td onClick={openModal(data)}>{stoptime}</td>
        <td><i className={styles.trash} onClick={deleteClickHandler({ lid, s_year_id, name, search })}><BiTrash /></i></td>

      </tr>
    )
  }

  const deleteClickHandler = (lid) => (e) => {
    e.stopPropagation()
    setPeriodForDelete(lid)
  }

  const acceptConfirm = () => {
    dispatch(deletePeriod(periodForDelete))
    setPeriodForDelete(null)
  }

  const rejectConfirm = () => {
    setPeriodForDelete(null)
  }

  return (
    <div className={styles.wrapper}>
      {periodForDelete &&
        <Confirmation
          title={`Вы уверены, что хотите удалить занятие ${periodForDelete.name}?`}
          acceptConfirmation={acceptConfirm}
          rejectConfirmation={rejectConfirm}
        />
      }
      <Modal
        closeModal={closeModal}
        data={selectedItem || forCopyPeriod}
        search={search}
      />
      <h1>Учебный распорядок</h1>

      <div className={styles.wrapperRow}>
        <div className={styles.selectWrapper}>
          <Select
            label={'Учебный год'}
            name={'year'}
            onChange={({ target: { name, value } }) => handleSearch(name, value)}
            value={search && search.year || schoolYears?.[0]?.xp_key}
          >
            {[{ name: 'Все', xp_key: '0' }, ...schoolYears]?.map(el => <option value={el.xp_key}>{el.name}</option>)}
          </Select>
        </div>
        <div className={styles.selectWrapper}>
          <Select
            label='Смена'
            name={'shift'}
            onChange={({ target: { name, value } }) => handleSearch(name, value)}
            value={search && search.shift || shifts?.[0]?.value}
          >
            {[{ name: 'Все', sid: '0' }, ...shifts]?.map(el => <option value={el.sid}>{el.name}</option>)}
          </Select>
        </div>
      </div>

      {loading
        ? <Loader />
        : list &&
        <Table style={{ marginTop: '2rem', backgroundColor: 'white' }}>
          <thead>
            <tr className={styles.thead}>
              <th>Название</th>
              <th>Уч. период</th>
              <th>Уч. период ДО</th>
              <th>Уч. период ВД</th>
              <th>Время начала</th>
              <th>Время окончания</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {list.map(renderRow)}
          </tbody>
        </Table>
      }
      <div className={styles.buttons}>
        <Button icon={IoIosAdd} onClick={openModal()}><IoIosAdd size={30} />Добавить запись</Button>
        <Button icon={IoIosSave} onClick={openModal(list)}><IoIosSave size={30} />Копировать текущий распорядок</Button>
      </div>
    </div>
  )
}

