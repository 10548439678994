import { BACKEND_URL_PREFIX } from "../assets/const";
import { buildDefaultHeaders } from "./helper";
import {
  SET_COMMON_ADMINISTRATION,
  SET_COMMON_CLASSES,
  SET_COMMON_COUNTRIES,
  SET_COMMON_COURSES,
  SET_COMMON_EXAM_TYPES,
  SET_COMMON_FAMILY_COMPOSITION,
  SET_COMMON_FAMILY_STATUSES,
  SET_COMMON_LANGUAGES,
  SET_COMMON_LOADING,
  SET_COMMON_PARALLELS,
  SET_COMMON_SCHEDULE_VARIANTS,
  SET_COMMON_SCHOOLS,
  SET_COMMON_SCHOOL_YEARS,
  SET_COMMON_SHIFTS,
  SET_COMMON_SPECIALIZATIONS,
  SET_COMMON_STUDY_FORMS,
  SET_COMMON_STUDY_MODES,
  SET_COMMON_STUDY_PROFILES,
  SET_COMMON_STUDY_STEPS,
  SET_COMMON_SUBJECTS,
  SET_COMMON_TEACHERS_LIST,
  SET_COMMON_TERM_GRADE_TYPES,
  SET_COMMON_TERM_TYPES,
} from "./types";
import { checkAndUpdateToken } from "./auth";
import { createNotification } from "../components/Notifications/Notifications";
import { getGroups } from "./groups";




export const getTeachersList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMMON_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/teachers`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COMMON_TEACHERS_LIST, payload });
    }
  }
  dispatch({ type: SET_COMMON_LOADING, payload: false });
};

export const getSchoolYears = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMMON_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/school_year`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COMMON_SCHOOL_YEARS, payload: payload.school_year });
    }
  }
  dispatch({ type: SET_COMMON_LOADING, payload: false });
};

export const getSpecializations = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMMON_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/groups/specializations`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const payload = data.specializations
      dispatch({ type: SET_COMMON_SPECIALIZATIONS, payload });
    }
  }
  dispatch({ type: SET_COMMON_LOADING, payload: false });
};

export const getLanguages = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMMON_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/groups/languages`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const payload = data.language
      dispatch({ type: SET_COMMON_LANGUAGES, payload });
    }
  }
  dispatch({ type: SET_COMMON_LOADING, payload: false });
};

export const getStudyProfiles = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMMON_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/track_profile`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COMMON_STUDY_PROFILES, payload });
    }
  }
  dispatch({ type: SET_COMMON_LOADING, payload: false });
};

export const getStudyModes = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMMON_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/groups/study_modes`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const payload = data.study_mode
      dispatch({ type: SET_COMMON_STUDY_MODES, payload });
    }
  }
  dispatch({ type: SET_COMMON_LOADING, payload: false });
};

export const getStudyForms = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMMON_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/study_forms`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COMMON_STUDY_FORMS, payload });
    }
  }
  dispatch({ type: SET_COMMON_LOADING, payload: false });
};

export const getStudySteps = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMMON_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/study_steps`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COMMON_STUDY_STEPS, payload });
    }
  }
  dispatch({ type: SET_COMMON_LOADING, payload: false });
};
//совпадает с  import { getStagesStudy } from '../../actions/stagesStudy'

export const getShifts = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMMON_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/shifts`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COMMON_SHIFTS, payload });
    }
  }
  dispatch({ type: SET_COMMON_LOADING, payload: false });
};

export const getCountries = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMMON_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/countries`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COMMON_COUNTRIES, payload });
    }
  }
  dispatch({ type: SET_COMMON_LOADING, payload: false });
};

export const getSubjects = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMMON_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/subjects`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COMMON_SUBJECTS, payload });
    }
  }
  dispatch({ type: SET_COMMON_LOADING, payload: false });
};

export const getSchools = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMMON_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/schools`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COMMON_SCHOOLS, payload });
    }
  }
  dispatch({ type: SET_COMMON_LOADING, payload: false });
};

export const getFamilyStatuses = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMMON_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/family_status`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COMMON_FAMILY_STATUSES, payload });
    }
  }
  dispatch({ type: SET_COMMON_LOADING, payload: false });
};

export const getFamilySocialComposit = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMMON_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/family_social_composition/types_to_values`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COMMON_FAMILY_COMPOSITION, payload });
    }
  }
  dispatch({ type: SET_COMMON_LOADING, payload: false });
};

export const getTermTypes = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMMON_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/term_types`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COMMON_TERM_TYPES, payload });
    }
  }
  dispatch({ type: SET_COMMON_LOADING, payload: false });
};

export const getTermGradeTypes = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMMON_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/term_grade_types`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const { term_grade_types } = await response.json();
      dispatch({ type: SET_COMMON_TERM_GRADE_TYPES, payload: term_grade_types });
    }
  }
  dispatch({ type: SET_COMMON_LOADING, payload: false });
};

export const getAdminisList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMMON_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/admin`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const { admin } = await response.json();
      dispatch({ type: SET_COMMON_ADMINISTRATION, payload: admin });
    }
  }
  dispatch({ type: SET_COMMON_LOADING, payload: false });
};

export const deleteGroup = (groupsId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/groups/${groupsId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getGroups());
    }
  }
};

export const getParallels = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/parallel`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COMMON_PARALLELS, payload });
    }
  }
};

export const getCLasses = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/scheduling/classes`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COMMON_CLASSES, payload });
    }
  }
};

export const getScheduleVariants = (yearId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/schedule_variant/year/${yearId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_COMMON_SCHEDULE_VARIANTS, payload: data.schedule_variants });
    }
  }
};

export const getExamTypes = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/exam_schedule_type`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_COMMON_EXAM_TYPES, payload: data });
    }
  }
};

export const getCourses = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/courses`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_COMMON_COURSES, payload: data.courses });
    }
  }
};
