import React, {useEffect, useState} from 'react'
import styles from './styles.module.css'
import Modal from 'react-responsive-modal'
import {Input, Select, Textarea} from '../../../components/ModalInputs'
import {recipientTypes} from '../../../assets/const'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { createNotification } from '../../../components/Notifications/Notifications'
import { createFAQ, editFAQ, getRecipient } from '../../../actions/FAQ'
import { useDispatch, useSelector } from 'react-redux'
import Files from '../Files'

const defaultParams = {
    title: '',
    message: '',
    to_type: '0',
    to_mid: '',
    // to_type: 0
}; // Параметры по умолчанию

const Index = ({type, setType, params, closeModal, modalOpen}) => {

    const dispatch = useDispatch()

    const [values, setValues] = useState(defaultParams) 

    const [files, setFiles] = useState(null)

    const {students_list, parents_list, employees_list, roles_list} = useSelector(state => state.FAQ)

    const recipientList = {
        0: students_list,
        1: parents_list,
        2: employees_list,
        3: roles_list
    }

    const handleSubmit = () => { // отправка формы
        if (!values.title || !values.message || !values.to_type || !values.to_mid) {
            return createNotification('error', 'Заполните все обязательные поля')
        } else {
            if (type && type !== 'ADD') {
                dispatch(editFAQ({...values, id: Number(values.id), to_mid: Number(values.to_mid), to_type: Number(values.to_type)}, files))
            } else {
                dispatch(createFAQ({...values, to_mid: Number(values.to_mid), to_type: Number(values.to_type)}, params, files))
            }
        }

        closeModal()
    }

    useEffect(() => {
      if (type && type !== 'ADD') {
        setValues(type) 
      } else {
        setValues(defaultParams)
      }
    }, [type])
    

    useEffect(() => {
      dispatch(getRecipient(0))
      dispatch(getRecipient(1))
      dispatch(getRecipient(2))
      dispatch(getRecipient(3))
    }, [])

    // useEffect(() => {
    //   setValues({...values, to_mid: ''})
    // }, [values?.type])
    
    return (
        <Modal
            close={closeModal}
            open={modalOpen}
            closeOnOverlayClick={true}
            closeOnEsc={false}
            center
            showCloseIcon={false}
            animationDuration={1}
            classNames={{
            modal: styles.modal
        }}
            style={{
            padding: '0',
            margin: '0'
        }}>
            <div className={styles.wrapper}>
                <div className={styles.title}>{type === 'ADD'
                        ? 'Создать'
                        : 'Редактировать'} чат</div>

                <div className={styles.body}>
                    <Input
                        type='text'
                        label='Название чата'
                        name='title'
                        value={values.title}
                        onChange={(e) => setValues({
                        ...values,
                        title: e.target.value
                    })}
                        required/>

                    <div className={styles.recipient}>
                        <span className={styles.recipient_label}>Получатель:</span>
                        <div className={styles.recipientList}>
                            {Object
                                .keys(recipientTypes)
                                .map(el => <div key={el} className={styles.recipientItem}>
                                    <Input
                                        className={styles.radio}
                                        type="radio"
                                        name={el}
                                        value={el}
                                        checked={values.to_type === el}
                                        onChange={(e) => setValues({
                                        ...values,
                                        to_type: e.target.value
                                    })}
                                        label={recipientTypes[el]}/>
                                </div>)}
                        </div>
                    </div>

                    <div className={styles.recipientSelectWrap}>
                        {values.to_type == 0 && <Select
                            label=''
                            name='to_mid'
                            value={values.to_mid}
                            onChange={(e) => setValues({
                            ...values,
                            to_mid: e.target.value
                        })}>
                            <option value=''>Выбарать</option>
                            {recipientList?.[values?.to_type]
                            ?.map(el => <option key={el?.mid || el?.pmid} value={el?.mid || el?.pmid}>{el?.username || el?.student || el?.name}</option>)}
                        </Select>}
                        {values.to_type == 1 && <Select
                            label=''
                            name='to_mid'
                            value={values.to_mid}
                            onChange={(e) => setValues({
                            ...values,
                            to_mid: e.target.value
                        })}>
                            <option value=''>Выбарать</option>
                            {recipientList?.[values?.to_type]
                            ?.map(el => <option key={el?.mid || el?.pmid} value={el?.mid || el?.pmid}>{el?.username || el?.student || el?.name}</option>)}
                        </Select>}
                        {values.to_type == 2 && <Select
                            label=''
                            name='to_mid'
                            value={values.to_mid}
                            onChange={(e) => setValues({
                            ...values,
                            to_mid: e.target.value
                        })}>
                            <option value=''>Выбарать</option>
                            {recipientList?.[values?.to_type]
                            ?.map(el => <option key={el?.mid || el?.pmid} value={el?.mid || el?.pmid}>{el?.username || el?.student || el?.name}</option>)}
                        </Select>}
                        {values.to_type == 3 && <Select
                            label=''
                            name='to_mid'
                            value={values.to_mid}
                            onChange={(e) => setValues({
                            ...values,
                            to_mid: e.target.value
                        })}>
                            <option value=''>Выбарать</option>
                            {recipientList?.[values?.to_type]
                            ?.map(el => <option key={el?.mid || el?.pmid} value={el?.mid || el?.pmid}>{el?.username || el?.student || el?.name}</option>)}
                        </Select>}
                    </div>
                    
                    <Files files={files} setFiles={setFiles}/>

                    <Textarea
                        className={styles.textarea}
                        label='Текст'
                        name='message'
                        value={values.message}
                        onChange={(e) => setValues({
                        ...values,
                        message: e.target.value
                    })}/>
                </div>
            </div>

            <div className={styles.footer}>
                <ModalFooter closeModal={closeModal} handleSubmit={handleSubmit} isShowInfo={false}/>
            </div>
        </Modal>
    )
}

export default Index