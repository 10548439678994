import React from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { Checkbox, Input, Select } from '../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import { createJournalControl, updateJournalControl } from '../../actions/journalControl'

const validationSchema = Yup.object({
  // name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),
})

export default function ModalComponent({ data, closeModal, height, addMode }) {

  const dispatch = useDispatch()
  const { periods, groups } = useSelector(state => state.journal_control)

  const handleCreate = async(values) => {
    await dispatch(createJournalControl(values))
    closeModal()
  }

  const handleUpdate = async ({jmid, ...values}) => {
    await dispatch(updateJournalControl(jmid, values))
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    dirty,
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const renderOptions = ({ name, xp_key, gid, f, title, cid }) => <option value={gid || xp_key || cid}>{name || f || title}</option>

  console.log(data)

  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      classNames={{
        // overlay: styles.overlay,
        modal: styles.custom
      }}
      center
    >
      <>
        <div className={styles.modalLocal} >
          <div className={styles.tabWrapper} >

            <div className={styles.inputsRow}>
              <Input
                label='Дата'
                type='date'
                name='date'
                value={values.date}
                onChange={handleChange}
              />
              <Select
                label='Класс'
                name='group'
                value={values?.group}
                onChange={handleChange}
              >
                <option value=''>{values?.group}</option>
                {groups?.map(renderOptions)}
              </Select>
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Замечания'
                name='checktext'
                value={values.checktext}
                error={error('checktext')}
                onChange={handleChange}
                style={{ width: '600px' }}
              />

            </div>
            <div className={styles.inputsRow}>

              <Input
                label='Предмет'
                name='subject'
                value={values.subject}
                onChange={handleChange}
              />
              <Select
                label='Проверяющий'
                name='xp_f_get_mid_fio'
                value={values?.xp_f_get_mid_fio}
                onChange={handleChange}
              >
                <option value=''>{values?.xp_f_get_mid_fio !== null ? values?.xp_f_get_mid_fio : "Не выбран"}</option>
              </Select>
            </div>

            <div className={styles.inputsRow}>
              <Checkbox label="Выполнено" />
              <Input
                label='Дата'
                type='date'
                name='visitdate'
                // value={values.visitdate}
                // value={values.visitdate}
                // onChange={handleChange}
                disabled
              />
            </div>
            <div className={styles.inputsRow}>

              <Select
                label='Выполняющий'
                name='visitorder'
                // value={values?.visitorder}
                // onChange={handleChange}
                // options={typeOfAppeal}
                disabled

              >
                <option value=''>Выбрать</option>
                {/* {typeOfAppeal?.map(renderOption)} */}
              </Select>

            </div>
          </div>
        </div>

        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </>
    </Modal>

  )
}
