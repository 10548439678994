import { combineReducers } from 'redux'
import { data } from './data'
import { form } from './form'


const candidates = combineReducers({
    form,
    data
})

export default candidates