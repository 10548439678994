import * as types from "../actions/types";



const initialState = {
  pageNumber: 0,
  pageCount: 0,
  count: 0,

  list: null,
  loading: false,
  FAQById: null,
  loadingByID: false,
  fioList: null,
  message_list: [],
  message_list_loading: false,

  students_list: null,
  parents_list: null,
  employees_list: null,
  roles_list: null,
  files_list: []
};


export default function FAQ (state = initialState, action) {
  switch (action.type) {
    case types.SET_FAQ_COUNT:
      return {
        ...state,
        ...action.payload
      };
    case types.SET_FAQ:
      return {
        ...state,
        list: action.payload
      };
    case types.SET_FAQ_LOADING:
      return {
        ...state,
        loading: action.payload
      };
      case types.SET_FAQ_BY_ID:
        return {
          ...state,
          FAQById: action.payload
      };
      case types.SET_FAQ_BY_ID_LOADING:
        return {
          ...state,
          loadingByID: action.payload
        };
      case types.SET_FIO:
        return {
          ...state,
          fioList: action.payload
      };
      case types.SET_MESSAGE:
      return {
        ...state,
        message_list: {
          ...state.message_list,
          [action.payload?.id] : action.payload?.data
        }
      };
    case types.SET_MESSAGE_LOADING:
      return {
        ...state,
        message_list_loading: action.payload
      };

      case types.SET_STUDENTS:
        return {
          ...state,
          students_list: action.payload
      };
      case types.SET_PARENTS:
        return {
          ...state,
          parents_list: action.payload
      };
      case types.SET_EMPLOYEES:
        return {
          ...state,
          employees_list: action.payload
      };
      case types.SET_ROLES:
        return {
          ...state,
          roles_list: action.payload
      };
      case types.SET_FAQ_PAGE:
      return {
        ...state,
        pageNumber: action.payload
      };
      case types.SET_FAQ_FILES:
      return {
        ...state,
        files_list: {
          ...state.files_list,
          [action.payload?.messageID] : action.payload?.files
        }
      };
    default:
      return state;
  }
}