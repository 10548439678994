import { BACKEND_URL_PREFIX } from "../assets/const";
import { buildDefaultHeaders } from "./helper";
import { 
  SET_PERMISSION_GROUPS, 
  SET_PERMISSION_GROUP_USERS, 
  SET_PERMISSION_LOADING,
  SET_TEACHERS_BY_GROUP_ID,
  SET_TEACHERS_BY_GROUP_ID_LOADING } from "./types";
import { checkAndUpdateToken } from "./auth";
import { createNotification } from "../components/Notifications/Notifications";

export const getPermissionGroups = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_PERMISSION_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/permission_groups`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_PERMISSION_GROUPS, payload });
    }
  }
  dispatch({ type: SET_PERMISSION_LOADING, payload: false });
};

export const getTeacherByGroupId = (groupId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_TEACHERS_BY_GROUP_ID_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/people/teachers/${groupId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_TEACHERS_BY_GROUP_ID, payload });
    }
  }
  dispatch({ type: SET_TEACHERS_BY_GROUP_ID_LOADING, payload: false });
};

export const updatePermissionGroup = (groupId, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
  
    dispatch({ type: SET_PERMISSION_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/permission_groups/${groupId}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getPermissionGroups());
    }
  }
  dispatch({ type: SET_PERMISSION_LOADING, payload: false });
};

export const createPermissionGroup = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_PERMISSION_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/permission_groups`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getPermissionGroups())
    }
  
  }
  dispatch({ type: SET_PERMISSION_LOADING, payload: false });
};

export const addGroupUser = (groupId, userId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_TEACHERS_BY_GROUP_ID_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/permission_groups/${groupId}/users/${userId}`, {
      method: "POST",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getGroupUsers(groupId));
      createNotification('success', 'Успешно!')
    } else {
      createNotification('error', 'Ошибка!')
    }
  }
  dispatch({ type: SET_TEACHERS_BY_GROUP_ID_LOADING, payload: false });
};


export const deletePermissionGroup = (groupId) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_PERMISSION_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/permission_groups/${groupId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getPermissionGroups());
    }
  }
  dispatch({ type: SET_PERMISSION_LOADING, payload: false });
};

export const deleteGroupUser = (groupId, userId) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_PERMISSION_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/permission_groups/${groupId}/users/${userId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getGroupUsers(groupId));
    }
  }
  dispatch({ type: SET_PERMISSION_LOADING, payload: false });
};


export const getGroupUsers = (groupId) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
    dispatch({ type: SET_PERMISSION_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/permission_groups/${groupId}/users`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
        const payload = await response.json();
        dispatch({ type: SET_PERMISSION_GROUP_USERS, payload });
    }
    }
    dispatch({ type: SET_PERMISSION_LOADING, payload: false });
};