import React from 'react'
import styles from '../styles.module.css'
import Li from '../../../components/Li'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getAccessChapters, getChapterEntity, getChapterGroupData, uploadChapterGroupData } from '../../../actions/accessControls'
import { useState } from 'react'
import { accessStatuses } from '../../../assets/const'
import Button from '../../../components/Button'


export function Access ({roleId}) {
  
  const dispatch = useDispatch()
  const {chapters, chapterGroupData: accessData, loading} = useSelector(state => state.roles.access_control)

  const [chapterId, setChapterId] = useState(null)
  const [accessChanged, setAccessChanged] = useState(false)
  const [newAccessData, setNewAccessData] = useState(null)

  useEffect(() => { dispatch(getAccessChapters()) }, [])

  useEffect(() => { chapters && setChapterId(chapters[0].xp_key) }, [chapters])

  useEffect(() => {
    chapterId && dispatch(getChapterGroupData(chapterId, roleId))
  }, [chapterId])

  useEffect(() => {
      setNewAccessData(accessData)
      setAccessChanged(false)
  }, [accessData])
  
  const chapterClickHandler = (id) => () => setChapterId(id)



  const renderAccessPointsLI = ({name: chapterName, xp_key}) => {
    return (
      <li
        onClick={chapterClickHandler(xp_key)}
        className={cn(styles.chapterLi, {
          [styles.chapterLiActive]: xp_key === chapterId
        })}
      >
        {chapterName}
      </li>
    )
  }


  const rightChange = (index) => (e) => {
    const {value} = e.target
    const modifyAccessData = JSON.parse(JSON.stringify(newAccessData))
    const modifyItem = modifyAccessData[index]
    modifyItem.value = value
    modifyAccessData[index] = modifyItem
    setNewAccessData(modifyAccessData)
    setAccessChanged(true)
  }

  const saveChanges = () => {
    const payload = newAccessData.reduce((res, accessItem) => {
      const {xp_key, xp_rpt_id, value} = accessItem
      if (value === 'Нет') return res
      const reportMode = chapterId === 21
      const archiveMode = chapterId === 337 
      const rasdel = (reportMode || archiveMode) ? chapterId : xp_key
      const report_id = reportMode ? xp_rpt_id : null
      const syid = archiveMode ? xp_key : null
      return [...res, {
        rasdel,
        role: roleId,
        value, 
        report_id,
        syid 
      }]
    }, [])
    dispatch(uploadChapterGroupData(payload, chapterId, roleId))
  }

  const renderPointItemsLi = ({name, value}, index) => {
    return (
      <Li>
        <div className={styles.itemLabel}>{name}</div>
        <select
          name={name}
          className={cn(
            styles.itemSelect, {
              [styles.selectReadValue]: value==='Чтение',
              [styles.selectNotValue]: value==='Нет'
            }
          )}
          value={value}
          onChange={rightChange(index)}
        >
          {accessStatuses.map(status => <option >{status}</option>)}
        </select>
      </Li>
    )
  }
  
  return (
    <div className={styles.accessWrapper}>
      <ul>
        {chapters?.map(renderAccessPointsLI)}
        <Button
          onClick={saveChanges}
          disabled={!accessChanged}
          loading={loading}
        >
          Сохранить
        </Button>
      </ul>
      <ul>{newAccessData?.map(renderPointItemsLi)}</ul>
    </div>
  )
}
