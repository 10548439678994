import * as types from "../actions/types";



const initialState = {
  loading: false,
  schoolYears: []
};


export default function common (state = initialState, action) {
  switch (action.type) {
    case types.SET_COMMON_SCHOOL_YEARS:
      return {
        ...state,
        schoolYears: action.payload,
      };
    case types.SET_COMMON_SPECIALIZATIONS:
      return {
        ...state,
        specializations: action.payload,
      };
    case types.SET_COMMON_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };
    case types.SET_COMMON_STUDY_PROFILES:
      return {
        ...state,
        studyProfiles: action.payload,
      };
    case types.SET_COMMON_STUDY_MODES:
      return {
        ...state,
        studyModes: action.payload,
      };
    case types.SET_COMMON_STUDY_FORMS:
      return {
        ...state,
        studyForms: action.payload,
      };
    case types.SET_COMMON_STUDY_STEPS:
      return {
        ...state,
        studySteps: action.payload,
      };
    case types.SET_COMMON_SHIFTS:
      return {
        ...state,
        shifts: action.payload,
      };
    case types.SET_COMMON_TEACHERS_LIST:
      return {
        ...state,
        teachers: action.payload,
      };
    case types.SET_COMMON_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case types.SET_COMMON_SUBJECTS:
      return {
        ...state,
        subjects: action.payload,
      };
    case types.SET_COMMON_SCHOOLS:
      return {
        ...state,
        schools: action.payload,
      };
    case types.SET_COMMON_FAMILY_STATUSES:
      return {
        ...state,
        familyStatuses: action.payload,
      };
    case types.SET_COMMON_FAMILY_COMPOSITION:
      return {
        ...state,
        familyCompos: action.payload,
      };
    case types.SET_COMMON_TERM_TYPES:
      return {
        ...state,
        termTypes: action.payload,
      };
    case types.SET_COMMON_TERM_GRADE_TYPES:
      return {
        ...state,
        termGradeTypes: action.payload,
      };
    case types.SET_COMMON_ADMINISTRATION:
      return {
        ...state,
        admins: action.payload,
      };
    case types.SET_COMMON_PARALLELS:
      return {
        ...state,
        parallels: action.payload,
      };
    case types.SET_COMMON_CLASSES:
      return {
        ...state,
        classes: action.payload,
      };
    case types.SET_COMMON_SCHEDULE_VARIANTS:
      return {
        ...state,
        scheduleVariants: action.payload,
      };
    case types.SET_COMMON_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SET_COMMON_EXAM_TYPES:
      return {
        ...state,
        examTypes: action.payload,
      };
    case types.SET_COMMON_COURSES:
      return {
        ...state,
        courses: action.payload,
      };
    default:
      return state;
  }
}