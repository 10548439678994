import { api } from "../../App";
import { getObjectFromArray } from "../../utils/smallHelpers";
import {
  SET_SETTINGS_CANDIDATES_EXAM_TICKETS,
  SET_SETTINGS_CANDIDATES_NOTICES,
  SET_SETTINGS_CANDIDATES_NOTICES_STAT,
  SET_SETTINGS_CANDIDATES_PARAMS,
  SET_SETTINGS_CANDIDATES_RATE,
  SET_SETTINGS_LOADING,
} from "../types";



export const getCandidatesRate = (xpKey) => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_SETTINGS_LOADING, payload: true });
      const { data } = await api.get(`/applicants/enrolment_rate/${xpKey}`)
      dispatch({ type: SET_SETTINGS_CANDIDATES_RATE, payload: data.data })
    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({ type: SET_SETTINGS_LOADING, payload: false });
    }
};

export const getCandidatesParams = (xpKey) => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_SETTINGS_LOADING, payload: true });
      const response = await api.get(`/applicants/assessment_params/${xpKey}`)
      dispatch({ type: SET_SETTINGS_CANDIDATES_PARAMS, payload: response.data })
    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({ type: SET_SETTINGS_LOADING, payload: false });
    }
};

export const getCandidatesNotices = () => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_SETTINGS_LOADING, payload: true });
      const { data } = await api.get(`/applicants/notices`)
      const payload = getObjectFromArray(data.options)
      dispatch({ type: SET_SETTINGS_CANDIDATES_NOTICES, payload })
    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({ type: SET_SETTINGS_LOADING, payload: false });
    }
};

export const getCandidatesNoticesStat = () => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_SETTINGS_LOADING, payload: true });
      const { data } = await api.get(`/applicants/notices/stats`)
      const payload = data.stats[0]
      dispatch({ type: SET_SETTINGS_CANDIDATES_NOTICES_STAT, payload })
    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({ type: SET_SETTINGS_LOADING, payload: false });
    }
};

export const getCandidatesExamTickets = () => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_SETTINGS_LOADING, payload: true });
      const { data } = await api.get(`/applicants/exam_tickets`)
      const payload = getObjectFromArray(data.options)
      dispatch({ type: SET_SETTINGS_CANDIDATES_EXAM_TICKETS, payload })
    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({ type: SET_SETTINGS_LOADING, payload: false });
    }
};