import React from 'react'
import styles from './styles.module.css'
import {Checkbox, Input, Select, Textarea} from '../../../../../components/ModalInputs';
import { degreeOfCapacity, statusAccess } from '../../../../../assets/const';


  

const Main = ({ modalOpen, setModalOpen, tfo_list, tfo_types, teachers, roomTypes, closeModal, handleSubmit1, values, handleChange,  error }) => {
 
  return (
    <div>
        <div className={styles.inputsRow}>
            <Input
                label='Название'
                name='name'
                value={values.name}
                onChange={handleChange}
                error={error('name')}
            />
        </div>
        <div className={styles.inputsRow}>
            <Input
                label='Номер'
                name='short_name'
                value={values.short_name}
                onChange={handleChange}
                error={error('short_name')}
            />
        
            <Input
                label='Этаж'
                name='floor'
                value={values.floor}
                onChange={handleChange}
                error={error('floor')}
            />
        </div>
        <div className={styles.inputsRow}>
            <Select 
                name='tfoid'
                label='Объекты УМБ'
                value={values.tfoid}
                onChange={handleChange}
            >
                <option value=''>Выбрать</option>
                {tfo_list?.map(({base_title, tfoid}) => <option value={tfoid}>{base_title}</option>)}
            </Select>
            <Select 
                // error={checkError('mid')}
                name='mid'
                label='Закреплен за' 
                value={values.mid}
                onChange={handleChange}
            >
                <option value=''>Выбрать</option>
                {teachers?.teachers?.map(({mid, calc_fio}) => <option value={mid}>{calc_fio}</option>)}
            </Select>
        </div>
        <div className={styles.inputsRow}>
            <Select 
                // error={checkError('type')}
                name='type'
                label='Тип' 
                value={values.type}
                onChange={handleChange}
            >
                <option value=''>Выбрать</option>
                {/* {tfo_types?.map(({title, tfotid}) => <option value={tfotid}>{title}</option>)} */}
                {roomTypes?.map(({name, rtid}) => <option value={rtid}>{name}</option>)}
            </Select>
        
            <Select 
                label='Статус' 
                name='mid'
                value={values.status}
                onChange={handleChange}
                >
                <option value=''>Выбрать</option>
                {statusAccess?.map(({value, name}) => <option value={value}>{name}</option>)}
            </Select>
        </div>

        <div className={styles.inputsRow}>
            <Input 
                label={'Посадочных мест'}
                name='volume'
                value={values.volume}
                onChange={handleChange}
                error={error('volume')}
            />
            <Input 
                label={'Учебных коллективов'}
                name='volume_groups'
                value={values.volume_groups}
                onChange={handleChange}
                error={error('volume_groups')}
            />
        </div>

        <div className={styles.inputsRow}>
            <Select 
                label='Степень вместимости' 
                name='capacity_range'
                // error={checkError('capacity_range')}
                value={values.capacity_range}
                onChange={handleChange}
            >
                {/* <option value=''>Выбрать</option> */}
                {degreeOfCapacity?.map(({value, name}) => <option value={value}>{name}</option>)}
            </Select>

            <Input 
                label={'Площадь, м2'}
                name='area'
                value={values.area}
                onChange={handleChange}
                error={error('area')}
            /> 
            
            <Input 
                label={'Объём, м3'}
                name='volume_m3'
                value={values.volume_m3}
                onChange={handleChange}
                error={error('volume_m3')}
            /> 
        </div>

        <div className={styles.inputsRow} style={{width: '100%'}}>
            <Textarea
                className={styles.textarea} 
                label='Дополнительная информация' 
                name='extra_info'
                value={values.extra_info} 
                onChange={handleChange}
                error={error('extra_info')}
            />
        </div>

        <div className={styles.inputsRow}>
            <Input 
                label={'Строительный номер'}
                name='xp_building'
                value={values.xp_building} 
                onChange={handleChange}
                error={error('xp_building')}
            /> 
            <Input 
                label={'Секция'}
                name='section'
                value={values.section} 
                onChange={handleChange}
                error={error('section')}
            /> 
        </div>
    </div>
  )
}

export default Main