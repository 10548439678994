import React, { useState } from 'react'
import styles from '../styles.module.scss'
import ScheduleTable from '../../../components/ScheduleTable'
import Modal from 'react-responsive-modal'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useFormik } from 'formik'
import { Input, Select, Textarea } from '../../../components/ModalInputs'
import Button from '../../../components/Button'
import { useSelector } from 'react-redux'


export default function Table () {
  

  const { data, loading } = useSelector(state => state.scheduling)

  const [editCell, setEditCell] = useState(null)

  const handleCellDoubleClick = (cellData) => setEditCell(cellData)

  const closeModal = () => setEditCell(null)

  return (
    <div className={styles.wrapperTable}>
      
      <ScheduleTable
        schedule={data || []}
        loading={loading}
        onCellDoubleCLick={handleCellDoubleClick}
      /> 

      <EditModal
        open={!!editCell}
        data={editCell}
        closeModal={closeModal}
      />

    </div>
  )
}



const EditModal = ({open, closeModal, data}) => {
  
  return (
    <Modal
      open={open}
      onClose={closeModal}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
    >
      <Tabs>
        
        <TabList className={styles.tabsBlock}>
            {data?.map((_, ind) => <Tab className={styles.tab} selectedClassName={styles.activeTab}>{`Предмет ${ind + 1}`}</Tab>)}
        </TabList>
        
        {data?.map((course, ind) =>
          <TabPanel key={ind}>
            <CourseTab data={course} closeModal={closeModal}/>
          </TabPanel>
        )}

      </Tabs>
    </Modal>
  )
}

const CourseTab = ({ data, closeModal }) => {
  
  const {
    values,
    errors,
    handleChange,
    handleSubmit
  
  } = useFormik({
    initialValues: data,
    onSubmit: () => {}
  })

  return (
      <form className={styles.courseForm} onSubmit={handleSubmit}>
        <Input label='Класс' value={values.groupname} disabled/>
        <Input label='Урок' value={`${values.periodname} (${values.periodtime})`} disabled/>
        <Select label='Предмет' />
        <Select label='Класс / Подгруппа' />
        <Select label='Преподаватель' />
        <Select label='Аудитория' />
        <Select label='Статус занятия' />
        <Textarea label='Примечание' />
        <div className={styles.buttonsBlock}>
          <Button>Сохранить</Button>
          <Button onClick={closeModal}>Отмена</Button>
        </div>
      </form>
  )
}