import React from 'react'
import styles from './styles.module.css'
import Select from 'react-select';


export default function MultiSelect ({label, ...props}) {
  return (
    <label className={styles.label}>
      <p>{label}</p>
      <Select
        className={styles.creatableSelect}
        noOptionsMessage={() => 'Нет опций'}
        placeholder='Выбрать'
        isMulti
        {...props}
      />
    </label>
  )
}
