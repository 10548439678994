import { api } from "../App";
import { SET_VD_SCHEDULE_VARIANTS_LIST, SET_VD_SCHEDULE_VARIANTS_LOADING, SET_VD_SCHEDULE_VARIANTS_YEAR } from "./types";


export const setYear = (year) => ({type: SET_VD_SCHEDULE_VARIANTS_YEAR, payload: year})

export const getScheduleVariants = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_VD_SCHEDULE_VARIANTS_LOADING, payload: true });
    const year_id = getState().vd_schedule_variants.year || null
    const { data } = await api.get('/schedule_variant_out', {params: {year_id}});
    dispatch({ type: SET_VD_SCHEDULE_VARIANTS_LIST, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_VD_SCHEDULE_VARIANTS_LOADING, payload: false });
  }
}

export const createScheduleVariant = (data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_VD_SCHEDULE_VARIANTS_LOADING, payload: true });
    await api.post('/schedule_variant_out', data);
    await dispatch(getScheduleVariants())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_VD_SCHEDULE_VARIANTS_LOADING, payload: false });
    return success
  }
}

export const updateScheduleVariant = (id, data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_VD_SCHEDULE_VARIANTS_LOADING, payload: true });
    await api.put(`/schedule_variant_out/${id}`, data);
    await dispatch(getScheduleVariants())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_VD_SCHEDULE_VARIANTS_LOADING, payload: false });
    return success
  }
}

export const deleteScheduleVariant = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_VD_SCHEDULE_VARIANTS_LOADING, payload: true });
    await api.delete(`/schedule_variant_out/${id}`);
    await dispatch(getScheduleVariants())
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_VD_SCHEDULE_VARIANTS_LOADING, payload: false });
  }
}