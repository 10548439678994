import React, {useEffect, useState} from 'react'
import styles from './styles.module.css'
import {IoIosEye, IoIosEyeOff, IoIosArrowDown} from 'react-icons/io'
import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux';
import {editFAQ, getMessage} from '../../../actions/FAQ';

import MessageList from '../MessageList'

const dateFormat = 'YYYY-MM-DD, HH:mm';

const Index = ({item, params}) => {

    const dispatch = useDispatch()

    const [state,
        setState] = useState({hidden: false, isCollapse: false, loading: false}) // состояние компонента сообщений
    const {message_list} = useSelector(state => state.FAQ) // список сообщений по теме

    useEffect(() => {
        if (state.isCollapse == true && item.id) {
            getData()
        }
    }, [state.isCollapse])

    const getData = async() => { // получаем список сообщений по теме [ID]
        setState({
            ...state,
            loading: true
        })
        await dispatch(getMessage(item.id))
        setState({
            ...state,
            loading: false
        })
    }

    const handleHidden = () => {
        setState({
            ...state,
            hidden: !state.hidden
        })

        dispatch(editFAQ({hide: Number(!item.hidden)}, params, item.id))
    }
    return ( 
        <> 
            <div className={styles.wrapper}>
                <div className={styles.users}>
                    <div className={styles.title}>{item.title}</div>
                    <div className={styles.header}>
                        <div className={styles.user}>От:
                            <span>{item.from_user}</span>
                        </div>
                        <div className={styles.user}>Кому:
                            <span>{item.to_user}</span>
                        </div>
                    </div>
                </div>

                <div className={styles.info}>
                    <div className={styles.date}>Создан:
                        <span>{moment(item.created).format(dateFormat)}</span>
                    </div>
                    <div className={styles.date}>Обновлен:
                        <span>{moment(item.updated).format(dateFormat)}</span>
                    </div>
                    <div className={styles.buttons}>
                        <span className={styles.count}>Сообщений: {item.count}</span>
                        <i
                            className={styles.icon_hidden}
                            title={`${state.hidden
                            ? 'Скрыть'
                            : 'Показать'}`}
                            onClick={handleHidden}>
                            {item.hidden
                                ? <IoIosEyeOff className={styles.icon}/>
                                : <IoIosEye className={styles.icon}/>}
                        </i>
                        <i
                            onClick={() => setState({
                            ...state,
                            isCollapse: !state.isCollapse
                        })}
                            className={styles.icon_collapse}
                            style={{
                            transform: state.isCollapse
                                ? 'rotate(180deg)'
                                : 'rotate(0deg)'
                        }}
                            title={state.isCollapse
                            ? 'Развернуть'
                            : 'Свернуть'}><IoIosArrowDown className={styles.icon}/></i>
                    </div>
                </div>
            </div>

            {
            !state.isCollapse
                ? <></>
                : <MessageList data={message_list[item.id]} loading={state.loading}/>
            } 
        </>
    )
}

export default Index