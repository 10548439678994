import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { getJournalControlPeriods, getJournalControlGroups, getJournalControlData, getJournalControlCourses } from '../../actions/journalControl'
import Select from '../../components/Select'
import { useFormik } from 'formik'


const initialValues = {
  course_type: 1,
  trmid: '',
  gid: ''
}

export default function JournalControl() {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const { list: yearList } = useSelector(state => state.school_years)
  const { periods, groups, courses, data } = useSelector(state => state.journal_control)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  const { values, handleChange } = useFormik({ initialValues })

  useEffect(() => {
    const { course_type } = values
    const params = { course_type }
    dispatch(getJournalControlPeriods(params))
  }, [])

  useEffect(() => {
    const { course_type, trmid } = values
    if (!trmid) return;
    const params = { course_type, trmid }
    dispatch(getJournalControlCourses(params))
  }, [values.trmid])

  useEffect(() => {
    const { course_type, trmid, gid } = values
    if (!trmid || !gid) return;
    const params = { course_type, trmid, gid }
    dispatch(getJournalControlGroups(params))
  }, [values.cid])

  useEffect(() => {
    const { course_type, trmid, gid, cid } = values
    if (!trmid || !gid || !cid) return;
    const params = { course_type, trmid, gid, cid }
    dispatch(getJournalControlData(params))
  }, [values.gid])

  const goTo = (url) => () => navigate(url)

  const openModal = (data) => () => setSelectedItem(data || {})

  const closeModal = () => setSelectedItem(null)

  const acceptConfirmation = () => {
    // dispatch(deleteManners(confirmation.id));
    setConfirmation(false);
    closeModal();
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const deleteItemHandler = (data) => (e) => {
    e.stopPropagation()
    setConfirmation({ id: data.id, name: data.date })
  }

  console.log(groups)

  const renderLi = (data) => {
    const { date, checktext,xp_f_get_mid_fio, subject, group} = data
    return (
      <tr onClick={openModal(data)}>
        <td>{date}</td>
        <td>{checktext}</td>
        <td>{xp_f_get_mid_fio}</td>
        <td>{subject}</td>
        <td>{group}</td>
        <td><i className={styles.trash} onClick={deleteItemHandler(data)}><BiTrash /></i></td>
      </tr>
    )
  }

  return (
    <div className={styles.wrapper} style={{ width: '1200px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {selectedItem &&
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          height={'430px'}
          addMode={!selectedItem.jmid}
        />
      }
      <h1>Контроль ведения классного журнала ДО</h1>

      <Select
        title="Учебный год"
        name="year"
        options={[{ name: "Выбрать", xp_key: 0 }, ...yearList]}
        // onChange={handleInputChange}
      />

      <Select
        title="Учебный период"
        name="trmid"
        value={values.trmid}
        options={[{ name: "Выбрать", value: '' }, ...periods]}
        onChange={handleChange}
        />

      <Select
        title="Предмет"
        name="cid"
        value={values.cid}
        options={[{ name: "Выбрать", value: '' }, ...courses]}
        onChange={handleChange}
      />

      <Select
        title="Группа"
        name="gid"
        value={values.gid}
        options={[{ name: "Выбрать", value: '' }, ...groups]}
        onChange={handleChange}
      />

      <Table className={styles.tables}>
        <thead>
          <tr>
            <th>Месяц, число</th>
            <th>Замечания и предложения проверяющих</th>
            <th>Отметка о выполнении</th>
            <th>Предмет</th>
            <th>Класс</th>
           
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.map(renderLi)}
        </tbody>
      </Table>

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
      <div className={styles.footer}> 
        <Button mode='white' onClick={goTo('/journal_control')}>Классный журнал ДО</Button>
        <Button onClick={goTo('/')}>На главную</Button>
      </div>
    </div>
  )
}
