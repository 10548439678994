import React, { useContext } from 'react'
import styles from './styles.module.css'
import { Checkbox, Input, Select } from '../../../components/ModalInputs'
import { armyDistricts, sex, statuses, streams } from '../../../assets/const'
import Button from '../../../components/Button'
import { StudentsContext, VariableStuffContext } from '../../../utils/context'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

export default function Admission ({active}) {
  
  const {mainForm} = useContext(StudentsContext)
  const { languages } = useSelector(state => state.common)

  const renderOptions = ({name, value, xp_key, subject, idschool, schoolname}) => 
  <option value={value || xp_key || idschool}>
  {name || subject || schoolname}
  </option> 

  const {values, handleChange} = mainForm

  
  return (
    <div className={classNames(styles.tabWrapper, {[styles.dislplayNone]: !active})}>
        <div className={styles.inputsRow}>
        <Select 
          label='Военный округ прибытия' 
          name='district'
          value={values.district}
          onChange={handleChange}
        >
          {armyDistricts?.map(renderOptions)}
        </Select>
        <Select label='Результат зачисления'>{sex?.map(renderOptions)}</Select>
       
        </div>
        <div className={styles.inputsRow}>
          <Select label='Допуск к экзаменам'>{sex?.map(renderOptions)}</Select>
          <Input label='Причина отказа в допуске' name='DENINED'  onChange={handleChange}   value={values?.DENINED}/>
        </div>
        <h3>Вступительные экзамены</h3>
        <div className={styles.inputsRow}>
         <Select label='Экзамен 1'>{sex?.map(renderOptions)}</Select>
          <Input label='Оценка'/>
          <Select label='Экзамен 4'>{sex?.map(renderOptions)}</Select>
          <Input label='Оценка'/>
        </div>
        <div className={styles.inputsRow}>
          <Select label='Экзамен 2'>{sex?.map(renderOptions)}</Select>
          <Input label='Оценка'/>
          <Select label='Экзамен 5'>{sex?.map(renderOptions)}</Select>
          <Input label='Оценка'/>
        </div>
        <div className={styles.inputsRow}>
          <Select label='Экзамен 3'>{sex?.map(renderOptions)}</Select>
          <Input label='Оценка'/>
          <Select label='Экзамен 6'>{sex?.map(renderOptions)}</Select>
          <Input label='Оценка'/>
        </div>
        <div className={styles.inputsRow}>
          <Input label='Психологический тест 1'/>
          <Input label='Психологический тест 2'/>
          <Input label='Психологический тест 3'/>
        </div>
        <div className={styles.inputsRow}>
        <Input label='Психологический тест 4'/>
        <Input label='Психологический тест 5'/>
        <Input label='Психологический тест 6'/>
         </div>

        <div className={styles.inputsRow}>
          <Input label='Суммарный балл за экзамены'/>
          <Input label='Средний балл за экзамены'/>
          <Input label='Skype'/>
        </div>

      <p>Суммарный балл за экзамены: {'-'},      включая псих.тесты: {'-'}</p>
      <p>Средний балл за экзамены: {'-'},      включая псих.тесты: {'-'}</p>

       <h3>Прочее</h3>
       
        <div className={styles.inputsRow}>
          <Input label='Ср. балл на момент пост.'/>
        </div>
        <div className={styles.inputsRow}>
          <Input style={{width: '500px'}} label='Портфолио'/>
          <Input label='         '/>
        </div>
        <div className={styles.inputsRow}>
          <Select label='Училище для поступления'>{sex?.map(renderOptions)}</Select>
          <Select label='набор'>{streams?.map(renderOptions)}</Select>
        </div>
        <div className={styles.inputsRow}>
          <Select label='Иностранные языки'>{languages?.map(el => <option value={el}>
            {el}
            </option> )}</Select>
          <Button style={{margin: "20px"}}>Добавить</Button>
        </div>
     
    </div>
  )
}


// <Select label='Тип восприятия информации '>{sex?.map(renderOptions)}</Select>