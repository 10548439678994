import { combineReducers } from 'redux'
import main from './main'
import about from './about'
import candidates from './candidates'
import results from './results'
import administration from './administration'
import exportReducer from './export'
import control from './control'
import strength from './strength'
import kiosk from './kiosk'
import loader from './loader'


const settings = combineReducers({
    main,
    about,
    candidates,
    results,
    administration,
    export: exportReducer,
    kiosk,
    strength,
    control,
    loader
})

export default settings