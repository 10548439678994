import * as types from "../actions/types";



const initialState = {
  pageNumber: 0,
  pageCount: 0,
  count: 0,
  list: null,
  currentWeek: {},
  loading: false
};


export default function schoolWeeks (state = initialState, action) {
  switch (action.type) {
    case types.SET_SCHOOL_WEEKS_COUNT:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_SCHOOL_WEEKS_PAGE:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case types.SET_SCHOOL_WEEKS_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case types.SET_CURRENT_SCHOOL_WEEK:
      return {
        ...state,
        currentWeek: action.payload,
      };
    case types.SET_SCHOOL_WEEKS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}