import React, { useState } from 'react'
import styles from './styles.module.css'
import Loader from '../../../components/Loader'
import MessageItem from '../MessageItem'
import MessageModal from '../MessageModal'
import { pack } from '../../../utils/pack'

const Index = ({data, loading}) => {

    const [modalType, setModalType] = useState(null)

    function getList(data, nesting = 0) {
        let nesting_ = nesting + 1

        return data.map((item, index) => {
            return (
                <div key={index}>
                    <div style={{ borderLeft: '5px solid #3a93f9', marginLeft: `5px` }}>
                        <MessageItem isDeLete={item.columns.length === 0 && item.parent !== 0} item={item} modalType={modalType} setModalType={setModalType} nesting={nesting_} />
                        {item?.columns?.length > 0 ? getList(item.columns, nesting_) : <></>}
                        {modalType != null ? getModal(item) : <></>}
                    </div>
                </div>
            )
        })
    }
    const getModal = (item) => { // Вызов модального окна для создания/редактирования сообщения
        return <MessageModal type={modalType} params={item} setType={setModalType} modalOpen={modalOpen} modalClose={modalClose}/>
    }

    const modalOpen = (item) => { // Открытие модального окна
        setModalType(item)
    }

    const modalClose = () => { // Закрытие модального окна
        setModalType(null)
    }

    return (
        <div>
            {loading
                ? <Loader/>
                : !!data
                    ?.length
                        ? <div className={styles.wrapper}>{getList(pack(data, 0), 0)}</div>
                        : <></>}
        </div>
    )
}

export default Index