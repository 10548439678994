import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox, Input, Select } from '../../../components/ModalInputs'
import { createSchoolYear, updateSchoolYear } from '../../../actions/schoolYears'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { getTermGradeTypes, getTermTypes } from '../../../actions/common'
import { Checkboard } from 'react-color'
import { createTypesStudySessions, getGradeWeight, updateTypesStudySessions } from '../../../actions/directory'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле'),
})


export default function Main({ closeModal, data, addMode }) {

  const dispatch = useDispatch()
  const { gradeWeight } = useSelector(state => state.directory)
  useEffect(() => {
    dispatch(getGradeWeight());
  }, [])

  // СОЗДАНИЕ НОВОГО ПОКА НЕ НАДО
  // const handleCreateTypes =  (values) => {
  //   const {xp_key, fixed_weight, istest, no_access,  ...payload} = values
  //   const payload2 = {... values, icon: "task.gif", no_access: no_access[0] === 'on' ? 1 : 0, fixed_weight:fixed_weight[0] === 'on' ? 1 : 0 , istest:istest[0] === 'on' ? 1 :0 } //TODO
  //   dispatch(createTypesStudySessions(payload2))  
  //   closeModal()
  // }

  const handleUpdateTypes = (values) => {
    const { xp_key, fixed_weight, istest, no_access, ...payload } = values
    const payload2 = {
      ...values,
      icon: "task.gif",
      no_access: values.no_access == true ? 1 : 0,
      fixed_weight: values.fixed_weight == true ? 1 : 0,
      istest: values.istest == true ? 1 : 0,
      isavailable: values.isavailable == true ? 1 : 0,
    }
    dispatch(updateTypesStudySessions(payload2))
    closeModal()
  }

  const handleChangeCheckbox = (e) => {
    const nameCheckbox = e.target.name
    setFieldValue(nameCheckbox, !values[nameCheckbox])
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? {
      default_weight: 0,
      fixed_weight: 0,
      isavailable: true,
      isdop: 0,
      ismain: 0,
      istest: 0,
      no_access: null,
      typeid: '',
      typename: '',
    } : data,

    enableReinitialize: true,
    // validateOnChange: true,
    // validationSchema: validationSchema,
    // onSubmit: addMode ? handleCreateTypes : handleUpdateTypes бeз ДОБАВЛЕНИЯ
    onSubmit: handleUpdateTypes
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const { termTypes, termGradeTypes } = useSelector(state => state.common)
  // const getMultiOptions = ({title, trmtid}) => ({label: title, value: trmtid})
  const renderOptions = ({ weight }) => <option value={weight}>{weight}</option>

  // const getTermGradeValues = () => {
  //   const options = termGradeTypes?.filter(({trmtid}) => values.year_grade_types?.includes(trmtid)) || []
  //   return options.map(getMultiOptions)
  // }

  useEffect(() => {
    !termTypes && dispatch(getTermTypes())
    !termGradeTypes && dispatch(getTermGradeTypes())
  }, [])

  const setTermGradeValues = (fieldName) => (grades) => {
    const newValue = grades.map(({ value }) => value).join(',')
    setFieldValue(fieldName, newValue)
  }
  // TODO  НЕ ЯСНО ИЗ НАЗВАНИЙ КТО ЕСТЬ КТО
  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
          <div className={styles.inputsRow} style={{ display: 'flex', flexDirection: 'row' }}>
            <Input
              label='Тип учебного процесса'
              name='typename'
              value={values.typename}
              error={error('typename')}
              onChange={handleChange}
              style={{ width: '400px', marginRigth: "20px" }}
              disabled
            />

          </div>
          <div className={styles.inputsRow} style={{ display: 'flex', flexDirection: 'row', margin: "30px 0" }}>

            <Select
              label='Вес оценок по умолчанию'
              value={values.default_weight}
              name='default_weight'
              // options={gradeWeight?.map(getMultiOptions)}
              options={gradeWeight}
              onChange={handleChange}
            >
              {gradeWeight?.map(renderOptions)}
            </Select>
            <Checkbox label='Можно редактировать веса оценок' name='fixed_weight' isChecked={values.fixed_weight} onChange={handleChangeCheckbox} />
            <Checkbox label='Доступен'  name='isavailable' isChecked={values.isavailable} onChange={handleChangeCheckbox} />
            <Checkbox label='Используется' name='no_access' isChecked={values.no_access} onChange={() => setFieldValue('no_access', !values.no_access)} />
            <Checkbox label='Участвует в КОУ, СОУ, % усп.' name='istest' isChecked={values.istest} onChange={handleChangeCheckbox} />
          </div>
        </div>
      </div>
      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}
