import React, { useEffect } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { Input, Select } from '../../../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../../../components/ModalFooter/ModalFooter'
import { updateManners } from '../../../../actions/directoryBasicEducation'
import { getTypesOfVaccinations, getVaccinations } from '../../../../actions/medCenter/vassinations'
import { createVaccineExclusionsModal } from '../../../../actions/medCenter/medCard'


const doctorList = [
  { name: 'Хирург', id: 3 },
  { name: 'Диетолог', id: 4 },
  { name: 'Окулист', id: 5 }
]
const contraindicationsList = [
  { name: 'Вакцинация запрещена', id: 3 },
  { name: 'Вакцинация не обязательна', id: 4 },
  { name: 'Вакцинация не рекомендована', id: 5 }
]

const validationSchema = Yup.object({
  // seria: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),
})

export default function ModalComponent({ data, closeModal, height, addMode, student }) {

  const dispatch = useDispatch()
  const { vaccinations, typesVaccinations } = useSelector(state => state.directoryMedCenter)

  useEffect(() => {
    dispatch(getVaccinations())
    dispatch(getTypesOfVaccinations())
  }, [])

  const handleCreate = (values) => {
    console.log('values::: ', values);
    dispatch(createVaccineExclusionsModal({ mid: student.mid, doctor_mid: 67890, ...values }))
    closeModal()
  }

  const handleUpdate = () => {
    dirty && dispatch(updateManners(values))
    closeModal()
  }
  const {
    values,
    errors,
    touched,
    dirty, // проверяет есть ли изменения
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })
  console.log('values::: ', values);

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const renderOption = (option, index) => {
    const { xp_key, value, trmid, cid, gid, work_status, id, pmid, did, name, title, year, access, diagnosis, disease, iddischarge, discharge, c_vac_type } = option
    const optionValue = xp_key || value || trmid || cid || gid || work_status || id || pmid || did || disease || iddischarge || c_vac_type;
    const optionTitle = name || title || value || year || work_status || access || diagnosis || discharge;
    return <option key={`${optionValue}+${index}`} value={optionValue}>{optionTitle}</option>
  }

  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      classNames={{
        // overlay: styles.overlay,
        modal: styles.custom
      }}
    >
      <>
        <div className={styles.modalLocal} >
          <div className={styles.tabWrapper} >
            <div className={styles.column}>
              <Input
                label='Пациент'
                // name='wherehospitalisation'
                value={student.fio}
                // onChange={handleChange}
                // className={styles.selectos}
                // options={diseaseList}
                style={{ width: '600px' }}
                disabled
              />

              <Select
                label='Тип противопоказания'
                name='ex_type' //TODO mockDAta
                value={values?.ex_type}
                onChange={handleChange}
                style={{ width: '300px' }}
                options={contraindicationsList}
              >
                <option value=''>Выбрать</option>
                {contraindicationsList?.map(renderOption)}
              </Select>

              <Input
                label='Документ'
                name='name' //TODO
                value={values.name}
                error={error('name')}
                onChange={handleChange}
                style={{ width: '600px' }}
              />
              <div className={styles.inputsRow}>
                <Input
                  label='Дата медотвода'
                  type='date'
                  name='ex_date'
                  value={values.ex_date}
                  onChange={handleChange}
                />
                <Input
                  label='Действует по'
                  type='date'
                  name='to_date'
                  value={values.to_date}
                  onChange={handleChange}
                />
              </div>
              <Select
                label='Вид прививки'
                name='c_vaccine'
                value={values?.c_vaccine}
                onChange={handleChange}
                style={{ width: '300px' }}
                options={vaccinations}
              >
                <option value=''>Выбрать</option>
                {vaccinations?.map(renderOption)}
              </Select>
              <Select
                label='Вакцина'
                name='c_vac_type'
                value={values?.c_vac_type}
                onChange={handleChange}
                style={{ width: '300px' }}
                options={typesVaccinations}
              >
                <option value=''>Выбрать</option>
                {typesVaccinations?.map(renderOption)}
              </Select>
              <Select
                label='Врач'
                name='doctor_mid'//TODO mockData
                value={values?.doctor_mid}
                onChange={handleChange}
                style={{ width: '300px' }}
                options={doctorList}
              >
                <option value=''>Выбрать</option>
                {doctorList?.map(renderOption)}
              </Select>


              <Input
                label='Причина'
                name='description'
                value={values.description}
                error={error('description')}
                onChange={handleChange}
                style={{ width: '600px' }}
              />




            </div>
          </div>

        </div>

        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </>
    </Modal >

  )
}
