import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import Modal from 'react-responsive-modal'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import EventType from './EventType'
import { Input, Quill, Select } from '../../../components/ModalInputs'
import { createNotification } from '../../../components/Notifications/Notifications'
import { useDispatch, useSelector } from 'react-redux'
import { createAds, editAds, getAdsById, getEventTypes } from '../../../actions/ads'
import moment from 'moment'


const defaultValues = { // значения по умолчанию
    name: '',
    text: ' ',
    text: ' ',
    dnevnik_firstpage_type: 1,
    dnevnik_firstpage_show_days: 1,
    ev_type_id: '',
    kiosk: 1,
    isservice: 1,
    dnevnik: 0,
    cid: ''
};

const Index = ({ type, setType, date }) => {
    console.log('type::: ', type);

    const dispatch = useDispatch();

    const [values, setValues] = useState({ ...defaultValues }); // значения формы

    const [isEventType, setIsEventType] = useState(false); // тип события

    const { adsById = defaultValues } = useSelector(state => state.ads); // объявление по ID из store
    const { list: eventTypeList, eventTypeById } = useSelector(state => state.event_type); // тип события
    const { coursesAliasList } = useSelector(state => state.courses); // список курсов
    const coursesAliasListPlus = [...coursesAliasList] // закинул общее вначало списка
    coursesAliasListPlus.unshift("Общее")

    const [dataTime, setDataTime] = useState(null)

    const error = (fieldName) => {
        if (values[fieldName] === '') return 'Обязательное поле'
        if (values[fieldName].length > 120) return 'Должно быть не более 120 символов'

        // const value = values[fieldName];
        // console.log('value::: ', value);

        // // if (!value) return 'Обязательное поле';

        // if (value.length > 120) {
        //     return 'Должно быть не более 120 символов';
        // }

        // let errorMessage = '';

        // if (!values[fieldName]) {
        //     errorMessage = 'Обязательное поле';
        // } else if (values[fieldName].length > 120) {
        //     errorMessage = 'Должно быть не более 120 символов';
        // }

        // return errorMessage;

        // return '';
    }

    useEffect(() => { // получаем объявление по ID
        if (type && type !== 'ADD') {
            dispatch(getAdsById(type));
        }
        if (eventTypeById === null) { // получаем типы событий
            dispatch(getEventTypes())
        }
        setDataTime(moment().format("YYYY-MM-DDTHH:mm"))
    }, []);

    useEffect(() => {
        console.log('elseelseelseelseelseelseelseelseelseelseelseelseelseelseelseelseelseadsById::: ', adsById);

        if (adsById) {
            setValues(adsById);
        } else {
            console.log('elseelseelseelseelseelseelseelseelseelseelseelseelseelseelseelseelseadsById::: ', adsById);

            setValues(defaultValues);
        }
    }, [adsById]);

    useEffect(() => {
        if (values && values
            ?.dnevnik === 0) {
            setValues({
                ...values,
                dnevnik_firstpage_type: null,
                dnevnik_firstpage_show_days: 0
            })
        } else {
            setValues({
                ...values,
                dnevnik_firstpage_type: 1
            })
        }
    }, [values
        ?.dnevnik]);

    useEffect(() => {
        if (values
            ?.isservice === 0) {
            setValues({
                ...values,
                dnevnik: 0,
                kiosk: 0
            })
        }
    }, [values
        ?.isservice]);

    useEffect(() => {
        if (dataTime) {
            setValues({
                ...values,
                posted: dataTime,
            })
        }
    }, [dataTime]);

    const closeModal = () => { // закрытие модального окна
        setType(null)
        setValues(defaultValues)
        dispatch({ type: 'SET_ADS_BY_ID', payload: null });
    }

    const modalOpen = !!setType; // открытие модального окна

    const handleSubmit = () => { // отправка формы
        if (!values?.name || !values?.text || values?.cid === '') {
            return createNotification('error', 'Заполните все обязательные поля')
        } else {
            if (type && type !== 'ADD') {
                dispatch(editAds({
                    ...values,
                    cid: Number(values
                        ?.cid),
                    dnevnik_firstpage_show_days: Number(values
                        ?.dnevnik_firstpage_show_days),
                    ev_type_id: Number(values.ev_type_id),
                    posted: dataTime
                }, date.from, date.to))
            } else {
                dispatch(createAds({
                    ...values,
                    cid: Number(values
                        ?.cid),
                    dnevnik_firstpage_show_days: Number(values
                        ?.dnevnik_firstpage_show_days),
                    ev_type_id: Number(values.ev_type_id),
                    posted: dataTime
                }, date.from, date.to))
            }
            closeModal()
        }
    }

    const handleChange = (e) => {
        console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee::: ', e.target);
        console.log('values::: ', values);
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const handleSetTypeEvent = () => {
        setIsEventType(true);
    }


    return (
        <Modal
            close={closeModal}
            open={modalOpen}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            center
            showCloseIcon={false}
            animationDuration={1}
            classNames={{
                modal: styles.roomTypesModal
            }}
            style={{
                padding: '0',
                margin: '0'
            }}>
            {console.log('valuesvaluesvaluesvaluesvaluesvaluesvaluesvaluesvaluesvaluesvaluesvaluesvaluesvalues::: ', values)}
            {values.name &&
                <div className={styles.wrapper}>
                    {isEventType
                        ? <EventType isEventType={isEventType} setIsEventType={setIsEventType} />
                        : <></>}

                    <h1>{(type && type !== 'ADD')
                        ? 'Редактирование '
                        : 'Создание '}
                        объявления</h1>

                    <div className={styles.modalBody}>
                        <div className={styles.top}>
                            <Input
                                name='name'
                                label='Имя'
                                error={error('name')}
                                value={values?.name}
                                onChange={(e) => handleChange(e)} />
                            <br />
                            <br />
                            <div>
                                {
                                    console.log('dataTime::: ', dataTime)
                                }
                                {type !== 'ADD' ?
                                    <Input
                                        name='posted'
                                        label='Дата'
                                        type="datetime-local"
                                        value={moment(values.posted).format("YYYY-MM-DDTHH:mm")}
                                        onChange={(e) => setDataTime(moment(e.target.value).format("YYYY-MM-DDTHH:mm"))}
                                    />
                                    :
                                    <>
                                        <p style={{ color: '#778AAF', fontSize: '13px' }}>Дата</p>
                                        <Input
                                            type="datetime-local"
                                            value={dataTime}
                                            // value="2017-06-01T08:30"
                                            onChange={(e) => setDataTime(moment(e.target.value).format("YYYY-MM-DDTHH:mm"))} />
                                    </>

                                }
                                <br />
                                {console.log('values::: ', values)}
                            </div>

                            <br />
                            <Select
                                name='cid'
                                label='Предмет'
                                error={error('cid')}
                                onChange={(e) => handleChange({
                                    target: {
                                        name: 'cid',
                                        value: (e.target.value)
                                    }
                                })}>
                                <option selected={values.cid === ''} value={''}>Выберите предмет</option>

                                {/* не подходит coursesAliasListPlus из-за отсутствия id-ка для выбираемоего предмета  */}
                                {coursesAliasListPlus
                                    ?.map((item, index) => <option key={index} value={index} selected={index === values.cid}>{item}</option>)}
                            </Select>
                            <br />
                            <br />
                            <div className={styles.row}>
                                <Select
                                    name='ev_type_id'
                                    label='Тип события'
                                    style={{ width: '200px' }}
                                    onChange={(e) => handleChange({
                                        target: {
                                            name: 'ev_type_id',
                                            value: eventTypeList
                                                ?.find(item => item.ev_type === e.target.value)
                                                ?.ev_type_id
                                        }
                                    })}>
                                    <option
                                        selected={values
                                            ?.ev_type_id == ''}>Выберите тип</option>
                                    {eventTypeList && eventTypeList
                                        ?.map((item) => <option
                                            key={item
                                                ?.ev_type_id}
                                            selected={item
                                                ?.ev_type_id === values
                                                    ?.ev_type_id}>{item
                                                        ?.ev_type}</option>)}
                                </Select>
                                <div className={styles.edit} onClick={() => handleSetTypeEvent()}>Изменить</div>
                            </div>
                            <br />
                        </div>

                        <div className={styles.row_button}>
                            <div className={styles.row_button_1}>
                                <div className={styles.radio}>
                                    <Input
                                        label='Публичное'
                                        name='radio'
                                        type='radio'
                                        checked={values.isservice == 1}
                                        onChange={() => handleChange({
                                            target: {
                                                name: 'isservice',
                                                value: 1
                                            }
                                        })} />
                                    <Input
                                        label='Отображать на главной странице ЭД как'
                                        name='dnevnik'
                                        type='checkbox'
                                        checked={values.dnevnik !== 0}
                                        onChange={(e) => handleChange({
                                            target: {
                                                name: 'dnevnik',
                                                value: values.dnevnik == 0
                                                    ? 1
                                                    : 0
                                            }
                                        })}
                                        disabled={values.isservice === 0} />
                                </div>
                                <div className={styles.checkbox}>
                                    <Input
                                        label='Служебное'
                                        name='radio'
                                        type='radio'
                                        checked={values.isservice == 0}
                                        onChange={() => handleChange({
                                            target: {
                                                name: 'isservice',
                                                value: 0
                                            }
                                        })} />
                                    <Input
                                        label='Отображать в инф. киоске'
                                        name='kiosk'
                                        type='checkbox'
                                        checked={values.kiosk == 1}
                                        onChange={() => handleChange({
                                            target: {
                                                name: 'kiosk',
                                                value: Number(!values.kiosk)
                                            }
                                        })}
                                        disabled={values.isservice === 0} />
                                </div>
                            </div>

                            <div className={styles.row_button_2}>
                                <div className={styles.row_button_2_inner}>
                                    <Input
                                        label='Новость'
                                        name='dnevnik_firstpage_type'
                                        type='radio'
                                        checked={values.dnevnik_firstpage_type === 1}
                                        onChange={() => handleChange({
                                            target: {
                                                name: 'dnevnik_firstpage_type',
                                                value: 1
                                            }
                                        })}
                                        disabled={values.dnevnik === 0} />
                                    <Input
                                        label='Цитата'
                                        name='dnevnik_firstpage_type'
                                        type='radio'
                                        checked={values.dnevnik_firstpage_type === 2}
                                        onChange={() => handleChange({
                                            target: {
                                                name: 'dnevnik_firstpage_type',
                                                value: 2
                                            }
                                        })}
                                        disabled={values.dnevnik === 0} />
                                </div>
                                <Input
                                    label='Отображать дней'
                                    type='text'
                                    name='dnevnik_firstpage_show_days'
                                    onChange={(e) => handleChange({
                                        target: {
                                            name: 'dnevnik_firstpage_show_days',
                                            value: /\d+/.test(Number(e.target.value))
                                                ? e.target.value
                                                : e
                                                    .target
                                                    .value
                                                    .slice(0, -1)
                                        }
                                    })}
                                    value={values.dnevnik_firstpage_show_days}
                                    disabled={values.dnevnik === 0} />
                            </div>
                        </div>
                        <br />

                        <div className={styles.quill}>
                            <Quill
                                label='Текст объявления'
                                value={values.text}
                                name='text'
                                error={error('text')}
                                style={{
                                    height: '200px'
                                }}
                                onChange={(e) => handleChange({
                                    target: {
                                        name: 'text',
                                        value: e
                                    }
                                })}
                            />
                        </div>
                    </div>
                </div>
            }

            <div className={styles.modalFooter}>
                <ModalFooter
                    isShowInfo={false}
                    handleSubmit={handleSubmit}
                    closeModal={closeModal} />
            </div>
        </Modal>
    )
}

export default Index
