import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import moment from 'moment'
import ModalTheme from '../../../components/ClassbookTable/ModalTheme/ModalTheme';

const ClassbookChart = ({data, formData, handleClassTopic}) => {
  
    const [localData, setlocalData] = useState(null);
    const [modalData, setModalData] = useState(null);
    
    const openModal = (data) => setModalData(data);
    const closeModal = () => setModalData(null)
    
      const handleOpenModal = (data) => {
        handleClassTopic(formData.scYear, data.sheid);
        openModal(data);
      }
    
    useEffect(() => {
        setlocalData(data)
    }, [data])

    const handleChange = (id) => {
        setlocalData(localData.map(item => item.sh_mod_fact_id === id ? {...item, passed: !item.passed} : item))
    }

    return (
    <div className={styles.wrapper}>
    {<ModalTheme modalData={modalData} setModalData={setModalData} closeModal={closeModal} formData={formData}/> }

        <table>
            <thead>
                <tr>
                    <th>№ урока</th>
                    <th>Проведён</th>
                    <th>Тип</th>
                    <th>Дата</th>
                    <th>Пройдено</th>
                    <th>ДЗ</th>
                    <th>Заметки</th>
                </tr>
            </thead>
            <tbody>
                { localData?.map(item => <tr 
                    onClick={() => handleOpenModal(item)}
                    key={item.sh_mod_fact_id}>
                    <td>{item.les_num}</td>
                    <td><input type="checkbox" checked={item.passed} onClick={() => handleChange(item.sh_mod_fact_id)} /> </td>
                    <td>{item.event_type_j}</td>
                    <td>{moment(item.les_date).format('DD.MM.YYYY')}</td>
                    <td>null</td>
                    <td>{item.hw_text_j}</td>
                    <td>{item.teacher_comments}</td>
                </tr>) }
            </tbody>
        </table>
    </div>
  )
}

export default ClassbookChart