import * as types from "../actions/types";



const initialState = {

};


export default function subjectsByYearStudy (state = initialState, action) {
  switch (action.type) {
    case types.GET_SUBJECT_BY_YEAR_STUDY:
      return {
        ...state,
        subjects: action.payload,
      };
    case types.GET_STUDY_PLANS:
      return {
        ...state,
        plans: action.payload,
      };
  
    default:
      return state;
  }
}