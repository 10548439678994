import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox, Input, Select } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import { copyPeriod } from '../../../actions/periods'
import moment from 'moment'
import { getSchoolYears, getShifts } from '../../../actions/common'
import cn from 'classnames'
import Confirmation from '../../../components/Confirmation/Confirmation'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import Table from '../../../components/Table'



const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле'),
  s_year_id: Yup.string().required('обязательное поле'),
  dopstatus: Yup.string().required('обязательное поле'),
  short_name: Yup.string().required('обязательное поле'),
});

export default function CopyPeriod({ closeModal, data, addMode }) {
  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.terms);
  const { schoolYears, shifts } = useSelector(state => state.common);
  const [confirmation, setConfirmation] = useState(false);
  const { year, shift } = !!data && data
  const [copyYear, setCopyYear] = useState({
    from_year: year,
    from_shift: shift,
    to_year: 0
  });
  const schoolYearsMinus = schoolYears.filter(year => +year.xp_key !== +copyYear.from_year)

  const acceptConfirmation = () => {
    closeModal();
    setConfirmation(false);
  };

  const rejectConfirmation = () => {
    setConfirmation(false);
  };


  const handleCopy = () => {
    dispatch(copyPeriod(copyYear))
    closeModal()
  }

  const handleClick = (xp_key) => {
    setCopyYear((prev) => ({ ...prev, to_year: xp_key }))
  };


  const renderRow = (schoolYears) => {
    const { xp_key, name } = schoolYears
    return (
      <tr>
        <td
          key={xp_key}
          // className={cn([withPadding ? styles.methodOffice : styles.label, styles.checkbox])}
          className={cn([xp_key === +copyYear?.to_year ? styles.active : ''])}
          onClick={() => handleClick(xp_key)}
        >{name}</td>


      </tr>
    )
  }

  return (
    <>
      {
        confirmation && (
          <Confirmation
            top="30px"
            left="40px"
            title="Закрыть без сохранения?"
            confirmation={confirmation}
            acceptConfirmation={() => acceptConfirmation(confirmation)}
            rejectConfirmation={rejectConfirmation}
          />
        )
      }
      <div className={styles.modalMain}>
        <div className={styles.yearBox}>
          <Table style={{ marginTop: '2rem', backgroundColor: 'white' }}>
            <thead>
              <tr className={styles.thead}>
                <th style={{ backgroundColor: '#3689FF', color: 'white' }}>Год</th>
              </tr>
            </thead>
            <tbody>
              {schoolYearsMinus.map(renderRow)}
            </tbody>

          </Table>
        </div>
  
      </div>
      <ModalFooter handleSubmit={handleCopy} closeModal={closeModal} />
   
    </>
  )
}
