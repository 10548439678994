import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'

import { Form, Table, Footer, TableExpand } from './Components'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { getData, updateData } from '../../actions/disciplineStructure'
import { DisciplineStructureContext } from '../../utils/context'
import { getDataForUpdate } from '../../utils/disciplineStructure'

const initialValues = {
  educationType: 0,
  year: 0,
  profile: 0,
  teacher: 0,
  discipline: 0
}

export default function DisciplineStructure({ from }) {
  console.log('from::: ', from);

  const dispatch = useDispatch()
  const { data } = useSelector(state => state.discipline_structure)

  const [tableData, setTableData] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [editParams, setEditParams] = useState(null)
  const [arrInit, setArrInit] = useState(null)
  console.log('arrInit::: ', arrInit);
  const tableAreaRef = useRef(null);

  useEffect(() => {
    setTableData(data)
  }, [data])

  useEffect(() => {
    setArrInit({ ...initialValues, educationType: +from })
  }, [from])

  console.log('initialValues::: ', initialValues);


  const getTableData = values => {
    const { educationType, year, profile, teacher, discipline } = values
    if (!year || !teacher || !discipline) return;
    const params = { course_type: educationType }
    dispatch(getData(year, teacher, discipline, profile, params))
  }

  const form = useFormik({
    initialValues: arrInit,
    onSubmit: getTableData
  })
  console.log('form.values::: ', form.values);

  useEffect(() => {
    form.handleSubmit()
  }, [form.values])

  // useEffect(() => {
  //   form.setFieldValue('educationType', from)
  // }, [form.values])
  console.log('form.values]::: ', form.values);


  const buttonsBlockRef = useRef(null)

  const editRow = actionType => {
    const newTableData = JSON.parse(JSON.stringify(tableData));
    const { idsList, depth } = editParams
    const editableLevel = idsList.slice(0, -1).reduce((item, index) => item[index].children, newTableData)
    const index = idsList.at(-1)

    const action = actionType === 'up' ? moveUp : moveDown

    action(editableLevel, index)
    setTableData(newTableData);
    setSelectedRow(null)
  }
  // СОЗДАНИЕ НОВОЙ СТРОКИ - НОВОЙ ПОДСТРОКИ - УДАЛЕНИЕ СТРОКИ - ВВЕХ НА УРОВЕНЬ - ВНИЗ 
  const createRow = actionType => {
    const newTableData = JSON.parse(JSON.stringify(tableData));
    const { idsList, depth } = editParams
    const editableLevel = idsList.slice(0, -1).reduce((item, index) => item[index].children, newTableData)
    const index = idsList.at(-1)

    const action = actionType === 'new' ? create : createChild

    action(editableLevel, index)
    setTableData(newTableData);
    setSelectedRow(null)
  }

  const titleEdit = (e) => {
    const newTableData = JSON.parse(JSON.stringify(tableData));
    const { idsList, depth } = editParams
    const editableLevel = idsList.slice(0, -1).reduce((item, index) => item[index].children, newTableData)
    const index = idsList.at(-1)
    editableLevel[index].title = e.target.innerText
    setTableData(newTableData);
    setSelectedRow(null)
  }

  const create = (editableLevel, index) => {
    if (index === 0) return;
    const temp = editableLevel[index];
    editableLevel[index] = editableLevel[index];
    editableLevel[index] = temp;
  };
  const createChild = (editableLevel, index) => {
    if (index === 0) return;
    const temp = editableLevel[index - 1];
    editableLevel[index - 1] = editableLevel[index];
    editableLevel[index] = temp;
  };

  const moveUp = (editableLevel, index) => {
    if (index === 0) return;
    const temp = editableLevel[index - 1];
    editableLevel[index - 1] = editableLevel[index];
    editableLevel[index] = temp;
  };

  const moveDown = (editableLevel, index) => {
    if (index === editableLevel.length - 1) return;
    const temp = editableLevel[index + 1];
    editableLevel[index + 1] = editableLevel[index];
    editableLevel[index] = temp;
  };

  const saveData = () => {
    const { educationType, year, profile, teacher, discipline } = form.values
    const params = { course_type: educationType }
    const data = getDataForUpdate(tableData)
    dispatch(updateData(year, teacher, discipline, profile, params, data))
  }

  const contextValue = {
    form,
    initialValues,
    tableData,
    setTableData,
    selectedRow,
    setSelectedRow,
    editParams,
    setEditParams,
    editRow,
    saveData,
    buttonsBlockRef,
    titleEdit,
    createRow,
    tableAreaRef,
  }

  return (
    <DisciplineStructureContext.Provider value={contextValue}>
      <div className={styles.wrapper}>
        <h1>Структура учебного предмета</h1>
        <Form />
        {/* <Table/> */}
        <TableExpand />
        <Footer />
      </div>
    </DisciplineStructureContext.Provider>
  )
}
