import * as types from "../../actions/types";



const initialState = {
  rate: null,
  params: null,
  notices: null,
  notices_stat: null,
  exam_tickets: null
}


export default function candidates (state = initialState, action) {
  switch (action.type) {
      
    case types.SET_SETTINGS_CANDIDATES_RATE:
      return {
        ...state,
        rate: action.payload
      }
      
    case types.SET_SETTINGS_CANDIDATES_PARAMS:
      return {
        ...state,
        params: action.payload
      }
      
    case types.SET_SETTINGS_CANDIDATES_NOTICES:
      return {
        ...state,
        notices: action.payload
      }
      
    case types.SET_SETTINGS_CANDIDATES_NOTICES_STAT:
      return {
        ...state,
        notices_stat: action.payload
      }
      
    case types.SET_SETTINGS_CANDIDATES_EXAM_TICKETS:
      return {
        ...state,
        exam_tickets: action.payload
      }

    default:
      return state;
  }
}