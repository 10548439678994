import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { Input } from '../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import moment from 'moment'
import { createHolidays, updateHolidays } from '../../actions/holidays'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),
  text: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),
})

export default function ModalComponent({ data, closeModal, height, addMode }) {

  const dispatch = useDispatch()

  const handleCreate = (values) => {
    dispatch(createHolidays(values))
    closeModal()
  }

  const handleUpdate = (values) => {
    dispatch(updateHolidays(values))
    closeModal()
  }

  useEffect(() => {
    !values.date && setFieldValue('date', moment().format('YYYY-MM-DD'))
    // !values.timestamp && setFieldValue('timestamp', moment().format('YYYY-MM-DD'))
  }, [])

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: addMode ? {
      name: '',
    } : data,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]


  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      center
      classNames={{
        // overlay: styles.overlay,
        modal: styles.custom
      }}
    >
      <>
        <div className={styles.modalLocal}  >
          <div className={styles.tabWrapper} >

            <div className={styles.inputsColomn}>
              <Input
                error={values.date === '' ? 'Обязательное поле' : ''}
                type='date'
                label='Дата'
                name='date'
                value={moment(values.date).format('YYYY-MM-DD')}
                onChange={e => handleChange(e)}
              />
              <Input
                label='Название'
                name='name'
                value={values.name}
                error={error('name')}
                onChange={handleChange}
                style={{ width: '600px', marrginTop: '10px' }}
              />
              <div style={{ margin: '20px' }}></div>

              <Input
                error={values.timestamp === '' ? 'Обязательное поле' : ''}
                type='date'
                label='Перенос на'
                name='timestamp'
                value={values.timestamp ? moment(values.timestamp).format('YYYY-MM-DD') : null}
                onChange={e => handleChange(e)}
              />
              <Input
                label='Примечание'
                name='text'
                value={values.text}
                error={error('text')}
                onChange={handleChange}
                style={{ width: '600px' }}
              />
            </div>

          </div>
        </div>

        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </>
    </Modal >

  )
}
