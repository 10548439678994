import * as types from "../actions/types";



const initialState = {
  list: null,
  year: '',
  loading: false
};


export default function vd_schedule_variants (state = initialState, action) {
  switch (action.type) {
    case types.SET_VD_SCHEDULE_VARIANTS_YEAR:
      return {
        ...state,
        year: action.payload
      };
    case types.SET_VD_SCHEDULE_VARIANTS_LIST:
      return {
        ...state,
        list: action.payload
      };
    case types.SET_VD_SCHEDULE_VARIANTS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}