import React, { useEffect, useState } from 'react'
import styles from './ModalTheme.module.css'
import Modal from 'react-responsive-modal';
import TabPlan from './Tabs/Plans';
import Button from '../../Button';
import TabHomeWork from './Tabs/HomeWork';
import TabFilesPlan from './Tabs/Files_plan';
import TabFilesFact from './Tabs/Files_fact';
import { useDispatch, useSelector } from 'react-redux';
import { getGradeValue, setClassbook, setClassbookHomework } from '../../../actions/classbook';


const ModalTheme = ({ modalData, setModalData, closeModal, formData }) => {

  const dispatch = useDispatch();

  const { lessons, scClass, studyPeriod, scYear } = useSelector(state => state.classbook)

  const [selectedTab, setSelectedTab] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    setSelectedTab(0);
  }, [modalData])

  useEffect(() => {
  console.log('selectedTab::: ', selectedTab);
  dispatch(setClassbookHomework(data))
  setData([])
  }, [selectedTab])
  
  const handleInputChange = (e) => {
    const {name, value} = e.target
    setData({
        ...data,
        [name]: value
    })
}
  
  const getTab = (selectedTab) => {
    switch (selectedTab) {
      case 0:
        return <TabPlan modalData={modalData} setModalData={setModalData} closeModal={closeModal} handleInputChange={handleInputChange}/>
      case 1:
        return <TabHomeWork formData={formData} lessons={lessons} scClass={scClass} scYear={scYear} studyPeriod={studyPeriod} modalData={modalData}  handleInputChange={handleInputChange} data={data}/>
      case 2:
        return <TabFilesFact formData={formData} modalData={modalData}/>
      case 3:
        return <TabFilesPlan formData={formData} modalData={modalData}/>
      default:
        return <div></div>
    }
  }

  return (
    <Modal
        open={!!modalData}
        onClose={closeModal}
        onEscKeyDown={false}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        animationDuration={1}
        styles={{modal: {width: '60vw'}}}
        classNames={{modal: styles.reportModal}}
      >
        <div className={styles.tabsBlock}>
          <div className={styles[selectedTab === 0 ? 'activeTab' : 'tab']} onClick={() => setSelectedTab(0)}>Планирование</div>
          <div className={styles[selectedTab === 1 ? 'activeTab' : 'tab']} onClick={() => setSelectedTab(1)}>Домашнее задание</div>
          <div className={styles[selectedTab === 2 ? 'activeTab' : 'tab']} onClick={() => setSelectedTab(2)}>Файлы факт</div>
          <div className={styles[selectedTab === 3 ? 'activeTab' : 'tab']} onClick={() => setSelectedTab(3)}>Файлы план</div>
        </div>
        
        {getTab(selectedTab)}
        
        <div className={styles.buttonsBlock}>
          <Button onClick={closeModal}>Сохранить</Button>
          <br/>
          <Button onClick={closeModal}>Закрыть</Button>
        </div>

      </Modal>
  )
}

export default ModalTheme