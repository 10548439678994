import { BACKEND_URL_PREFIX } from "../assets/const";
import { checkAndUpdateToken } from "./auth";
import { GET_STUDY_PLANS, GET_SUBJECT_BY_YEAR_STUDY, SET_ROOM_TYPES_LOAD } from "./types";
import { buildDefaultHeaders } from "./helper";
import { createNotification } from "../components/Notifications/Notifications";

export const getSubjectsByYearStudy = (trid) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    // dispatch({ type: SET_ROOM_TYPES_LOAD, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/study_plans/content/${trid}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: GET_SUBJECT_BY_YEAR_STUDY, payload: data});
    }
  }
  // dispatch({ type: SET_ROOM_TYPES_LOAD, payload: false });
};

export const getStudy_plans = (trid) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/study_plans`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: GET_STUDY_PLANS, payload: data.plans});
    }
  }
};

// export const editRoomType = (data) => async (dispatch, getState) => {
//     try {
//       await dispatch(checkAndUpdateToken());
//       if (getState().auth.token) {
//         dispatch({ type: SET_ROOM_TYPES_LOAD, payload: true });
//         const response = await fetch(`${BACKEND_URL_PREFIX}/room_types/${data.rtid}`, {
//           method: "PUT",
//           ...buildDefaultHeaders(getState),
//           body: JSON.stringify(data)
//         });
//         if (response.ok) {
//           createNotification('success', 'Успешно');
//           dispatch(getRoomTypesList())
//         }
//       }
//     } catch (ERR) {
//     } finally {
//       dispatch({ type: SET_ROOM_TYPES_LOAD, payload: false });
//     }
//   };

  export const updateSubjectToStudyYear = (data) => async (dispatch, getState) => {
    try {
      await dispatch(checkAndUpdateToken());
      if (getState().auth.token) {
        // dispatch({ type: SET_ROOM_TYPES_LOAD, payload: true });
        const response = await fetch(`${BACKEND_URL_PREFIX}/study_plans/content/${data.xp_key}`, {
          method: "PUT",
          ...buildDefaultHeaders(getState),
          body: JSON.stringify(data)
        });
        if (response.ok) {
          createNotification('success', 'Успешно');
          dispatch(getSubjectsByYearStudy(data.trid));
        }
      }
    } catch (ERR) {
    } finally {
      // dispatch({ type: SET_ROOM_TYPES_LOAD, payload: false });
    }
  };

  // export const deleteSubjectToStudyYear = (data) => async (dispatch, getState) => {
  //   console.log('data::: ', data);
  //   await dispatch(checkAndUpdateToken());
  //   if (getState().auth.token) {
  //    dispatch({ type: SET_ROOM_TYPES_LOAD, payload: true });
  //     const response = await fetch(`${BACKEND_URL_PREFIX}/study_plans/content/${data.xp_key}`, {
  //       method: "DELETE",
  //       ...buildDefaultHeaders(getState),
  //     });
  //     if (response.ok) {
  //       createNotification('success', 'Успешно');
  //       dispatch(getSubjectsByYearStudy(data.trid));
  //     }
  //   }
  //   dispatch({ type: SET_ROOM_TYPES_LOAD, payload: false });
  // };
