import { api } from "../App";
import { SET_PHYSICAL_GROUP_LIST, SET_PHYSICAL_GROUP_LOADING } from "./types";




export const getPhysicalGroups = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_PHYSICAL_GROUP_LOADING, payload: true });
    const { data } = await api.get('/physical_group');
    dispatch({ type: SET_PHYSICAL_GROUP_LIST, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_PHYSICAL_GROUP_LOADING, payload: false });
  }
}

export const createPhysicalGroup = (data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_PHYSICAL_GROUP_LOADING, payload: true });
    await api.post('/physical_group', data);
    await dispatch(getPhysicalGroups())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_PHYSICAL_GROUP_LOADING, payload: false });
    return success
  }
}

export const updatePhysicalGroup = (id, data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_PHYSICAL_GROUP_LOADING, payload: true });
    await api.put(`/physical_group/${id}`, data);
    await dispatch(getPhysicalGroups())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_PHYSICAL_GROUP_LOADING, payload: false });
    return success
  }
}

export const deletePhysicalGroup = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_PHYSICAL_GROUP_LOADING, payload: true });
    await api.delete(`/physical_group/${id}`);
    await dispatch(getPhysicalGroups())
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_PHYSICAL_GROUP_LOADING, payload: false });
  }
}