import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import Title from '../../components/Title'
import Table from '../../components/Table'
import Empty from '../../components/Empty'
import {Pagination} from '../../components/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { deleteStudyPlans, getStudyPlans, createCopyStudyPlan, setPage } from '../../actions/studyPlans'
import { BiTrash } from 'react-icons/bi'
import moment from 'moment'
import Confirmation from '../../components/Confirmation/Confirmation'
import Modal from './Modal'
import Button from '../../components/Button'
const Index = () => {

    const dispatch = useDispatch()
    const {list, pageCount, pageNumber, loading} = useSelector(state => state.studyPlans)

    const [confirmation, setConfirmation] = useState(false);
    const [modalOpen, setModalOpen] = useState(null);
    const [select, setSelect] = useState(null);

    const pageClick = ({selected}) => dispatch(setPage(selected))

    const acceptConfirmation = () => { // Подтвердить удаление
        dispatch(deleteStudyPlans(confirmation?.trid));
        closeModal();
        setConfirmation(false);
      };
      const rejectConfirmation = () => { // Отменить подтверждение
        setConfirmation(false);
      };

    useEffect(() => {
      dispatch(getStudyPlans())
    }, [])

    const openModal = (data) => () => { // Открыть модалку
        setModalOpen(data)
    }

    const closeModal = () => { // Закрыть модалку
        setModalOpen(null)
      }

    const handleConfirmation = (e, data) => {
        e.stopPropagation() // Отмена действия по умолчанию (открытие модалки)
        setConfirmation(data)
    }

    const handleSelect = (data) => {
        if (select?.trid === data.trid) {
            setSelect(null)
        } else {
          setSelect(data)
        }
    }

    useEffect(() => () => dispatch(setPage(0)), []);

    useEffect(() => {
      dispatch(getStudyPlans(pageNumber))
    }, [pageNumber])

  return (
    <div className={styles.wrapper}>
        <Title title="Учебные планы"/>

        <div className={styles.buttons}>
          {select?.trid && <div className={styles.copy}><Button onClick={() => dispatch(createCopyStudyPlan(select?.trid))}>Создать копию</Button></div>}
          <Button 
            style={{backgroundColor: "#3689FF", fontSize : "16px"}} 
            onClick={() => setModalOpen({
              name: '',
              edupart: '',
              periodfrom: '',
              periodtill: '',
              periodkind: '',
              trid: 'new',
              isbasic: false,
              category: '',
              
          })}>Добавить</Button>
        </div>

        {list ? list.length === 0 && <Empty/> : ''}
        <Pagination
          pageCount={pageCount}
          pageNumber={pageNumber}
          onPageChange={pageClick}
          loading={loading}
        >
        {list ? list.length !== 0 && 
        <Table className={styles.table}>
        <thead>
          <tr>
              <th style={{width: '100px'}}>№ п/п</th>           
              <th>Название</th>           
              <th >Ступень</th>           
              <th style={{width: '200px'}}>Период</th>           
              <th style={{width: '100px'}}>С</th>           
              <th style={{width: '100px'}}>По</th>           
              <th style={{width: '100px'}}>Действует</th>           
              <th style={{width: '100px'}}>Удалить</th>           
          </tr>
          </thead>
         <tbody>

        {
            list.map((data, index) => (
                <tr 
                  key={index} 
                  onClick={() => handleSelect(data)}
                  onDoubleClick={openModal(data)}
                  style={{backgroundColor: select?.trid === data.trid ? '#3689FF': ''}}
                  >
                    <td style={{color: select?.trid === data.trid ? 'white': ''}}>{index+1}</td>
                    <td style={{color: select?.trid === data.trid ? 'white': ''}}>{data.name}</td>
                    <td style={{color: select?.trid === data.trid ? 'white': ''}}>{data.edupart}</td>
                    <td style={{color: select?.trid === data.trid ? 'white': ''}}>{data.periodkind}</td>
                    <td style={{color: select?.trid === data.trid ? 'white': ''}}>{moment(data?.periodfrom).format('DD.MM.YYYY')}</td>
                    <td style={{color: select?.trid === data.trid ? 'white': ''}}>{moment(data?.periodtill).format('DD.MM.YYYY')}</td>
                    <td style={{color: select?.trid === data.trid ? 'white': ''}}>{data.isbasic ? 'Да' : 'Нет'}</td>

                    <td onClick={(e) => handleConfirmation(e, data)}>
                        <i className={styles.trash}><BiTrash/></i>
                    </td>
                </tr>
            ))
        }

          </tbody>
        </Table>
        : ''
        }
        </Pagination>

        {modalOpen?.trid && (
            <div className={styles.modal}><Modal modalOpen={modalOpen} closeModal={closeModal}/></div>
        )}

        {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title="Удалить данную запись?"
          confirmation={confirmation?.name}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}

    </div>
  )
}

export default Index