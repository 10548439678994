import React, { useContext, useEffect, useState } from 'react'
import { Select } from '../../../components/ModalInputs'
import { useDispatch, useSelector } from 'react-redux'
import { getCourses, getDataStructure, getPeoples, getProfiles, getSchoolYears } from '../../../actions/disciplineStructure'
import styles from '../styles.module.scss'
import { DisciplineStructureContext } from '../../../utils/context'

const EDU_TYPES = [
  {name: 'Основное', value: 0},
  {name: 'Дополнительное', value: 1},
  {name: 'Внеурочная деятельность', value: 2}
]

export default function Form() {
  
  const {form, initialValues} = useContext(DisciplineStructureContext)

  const {values, setFieldValue, setValues, handleChange} = form
  const dispatch = useDispatch()
  const {schoolYears, peoples, courses, profiles} = useSelector(state => state.discipline_structure.filters)
  
  useEffect(() => {
    dispatch(getSchoolYears())
    dispatch(getProfiles())
  }, [])
  
  useEffect(() => {
    // if (!values.year) return;
    const params = {course_type: values.educationType}
    dispatch(getPeoples(values.year, params))
    // вставить запрос на образёвание
  }, [values.educationType, values.year])
  
  useEffect(() => {
    const params = {course_type: values.educationType}
    console.log('params::: ', params);
    dispatch(getCourses(values.year, values.teacher, params))
    dispatch(getDataStructure(params))
  }, [values.educationType, values.year, values.teacher])
  
  const renderOptions = (item) => {
    const {xp_key, trp_id, name, cid, mid, fio, title} = item 
    const optionName = name || title || fio
    const optionValue = xp_key || trp_id || cid || mid
    return <option key={optionValue} value={optionValue}>{optionName}</option>
  }

  const handleSetEduType = (educationType) => () => {
    setValues({...initialValues, educationType})
  }

  return (
    <form className={styles.form}>
      
      <div className={styles.eduTypeBlock}>
        <p>Образование</p>
        {EDU_TYPES.map(({name, value}) =>
          <label>
            <input
              type='radio'
              name='edu_type'
              checked={value === values.educationType}
              onChange={handleSetEduType(value)}
            />
            {name}
          </label>
        )}
      </div>

      <div className={styles.filtersBlock}>
        <Select
          label='Учебный год'
          name='year'
          value={values.year}
          style={{backgroundColor: 'white'}}
          onChange={handleChange}
        >
          <option value={0}>Не выбран</option>
          {schoolYears?.map(renderOptions)}
        </Select>
        
        <Select
          label='Преподаватель'
          name='teacher'
          value={values.teacher}
          style={{backgroundColor: 'white'}}
          onChange={handleChange}
        >
          <option value={0}>Не выбран</option>
          {peoples?.map(renderOptions)}
        </Select>
        
        <Select
          label='Предмет'
          name='discipline'
          value={values.discipline}
          style={{backgroundColor: 'white'}}
          onChange={handleChange}
        >
          <option value={0}>Не выбран</option>
          {courses?.map(renderOptions)}
        </Select>
        
        <Select
          label='Профиль'
          name='profile'
          value={values.profile}
          style={{backgroundColor: 'white'}}
          onChange={handleChange}
        >
          <option value={0}>Не выбран</option>
          {profiles?.map(renderOptions)}
        </Select>
      </div>
      
    </form>
  )
}
