import * as types from "../actions/types";



const initialState = {
  competenceList:[]
};


export default function competence (state = initialState, action) {
  switch (action.type) {
    case types.GET_COMPETENCE:
      return {
        ...state,
        competenceList: action.payload,
      };
  
    default:
      return state;
  }
}