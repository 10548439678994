import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import Confirmation from '../../components/Confirmation/Confirmation'
import { getMeal, getDish, createMenu, getMenuOnDate, copyDishPeriod, copyMenu } from '../../actions/directoryCanteen'
import { Input, } from '../../components/ModalInputs'
import moment from 'moment'


export default function MenuDay() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { meal, dish, menuOnDate } = useSelector(state => state.directoryCanteen)
  const meal2 = meal && meal.map((el, i) => ({ ...el, id: i + 1 }))


  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedDay, setSelectedDay] = useState({ date: moment().format('YYYY-MM-DD') })
  const [confirmation, setConfirmation] = useState(false);

  const [createRow, setCreateRow] = useState(null);
  const [arrRows, setArrRows] = useState([])
  const [selectedDish, setSelectedDish] = useState(null)
  const [newRow, setNewRow] = useState(false)
  const [insideRow, setInsideRow] = useState(false)
  const [arrayForRequest, setArrayForRequest] = useState([])
  console.log('arrayForRequest::: ', arrayForRequest);


  // useEffect(() => {
  //   menuOnDate && setArrRows(menuOnDate)
  // }, [menuOnDate])

  useEffect(() => {
    !selectedDish && dispatch(getMeal())
    createRow && selectedDish && setCreateRow((prev) =>
      prev?.map(el => el.c_dishclass === selectedDish?.c_dishclass ?
        { ...el, ...selectedDish } : el)) // добавление в первичный массив с c_dishclass выбранного блюда
  }, [selectedDish])

  useEffect(() => {
    insideRow && selectedDish && setArrRows((prev) =>
      prev?.map(ele => ele.map(el =>
        el.c_dishclass === selectedDish?.c_dishclass && el?.level === insideRow ?
          { ...el, ...selectedDish } : el

      ))) // добавление в  массив  ПО УРОВНЮ с c_dishclass выбранного блюда
  }, [selectedDish])

  useEffect(() => {
    newRow && selectedDish && createRow && setArrRows((prev) =>
      [...prev, createRow]) // добавление в глобальный массив
  }, [createRow])

  useEffect(() => {
    const arr = arrRows.flat()
    setArrayForRequest(arr.filter(el => !!el.xp_key && el))

    if (arrRows && selectedDish) {
      setNewRow(false)
      setCreateRow(null)
      setSelectedDish(null)
    }
  }, [arrRows])

  useEffect(() => {
    newRow && setArrRows((prev) => [...prev, meal2?.map(el => ({ c_dishclass: el.sort, level: arrRows.length + 1, sort: arrRows.length }))]) // первичный массив +level для определения
  }, [newRow])

  useEffect(() => {
    console.log('meal2::: ', meal2);
    if (!menuOnDate) return
    if (menuOnDate) {
      const sortedDishes = menuOnDate?.reduce((acc, item) => {
        if (!acc[item.sort]) {
          acc[item.sort] = [];
        }
        acc[item.sort]?.push(item)
        return acc;
      }, {});
      const sortedDishesArray = Object.values(sortedDishes);
      setArrRows(sortedDishesArray)
    }
  }, [menuOnDate])

  useEffect(() => {
    dispatch(getMenuOnDate(selectedDay))
  }, [selectedDay])


  const openModal = (data) => () => {
    const selCell = (Object.values(data)).join('')
    setSelectedItem(selCell)
    dispatch(getDish(data))
    setInsideRow(data?.level)
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    // dispatch(deleteTypesOfDisease(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderHeader = (data) => {
    const { xp_key, name, } = data
    return (
      <th >{name}</th>
    )
  }
  const renderLi = (data) => {
    const { level, name, c_dishclass, dish_name, sort, dish_period_key, period_key } = data
    return (

      <td key={dish_period_key} onClick={openModal(data)}>{name || dish_name}</td>


    )
  }

  const handleChange = (e) => {
    setSelectedDay(
      { [e.target.name]: e.target.value }
    )

  }


  const handleCreateRow = () => {
    setNewRow(true)
  }



  return (
    <div className={styles.wrapper} style={{ margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {console.log('selectedItem::: ', selectedItem)}
      {selectedItem &&
        <Modal
          closeModal={closeModal}
          data={dish}
          height={'430px'}
          // addMode={(!selectedItem?.name && selectedItem !== null)}
          addMode={selectedItem === 'add'}
          setSelectedDish={setSelectedDish}
          selectedDay={selectedDay}
        />
      }
      <h1>Меню на день</h1>
      <Input
        label='Дата'
        type='date'
        name='date'
        value={selectedDay?.date || moment().format('YYYY-MM-DD')}
        onChange={handleChange}
      />
      { }
      <Table className={styles.tables}>
        <thead>
          {meal2?.map(renderHeader)}
        </thead>
        <tbody>
          {arrRows?.map(el => <tr>{el?.map(renderLi)}</tr>)}


          {/* {arrRows.length ? arrRows?.map(el => <tr>{el?.map(renderLi)}</tr>) : createRow?.map(renderLi)} */}
          {/* {createRow?.map(renderLi)} */}

        </tbody>
      </Table>
      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={handleCreateRow}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={() => dispatch(createMenu(arrRows))}
        >
          <IoIosAdd size={30} />
          Сохранить
        </Button>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={() => dispatch(copyMenu(arrRows))}
        >
          <IoIosAdd size={30} />
          Сохранить как
        </Button>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={() => dispatch(copyDishPeriod(arrRows))}
        >
          <IoIosAdd size={30} />
          Скопировать блюда на другой период
        </Button>
      </div>
    </div>
  )
}
