export const pack = ( data, lvl = 0 ) => { //приводим линеный массив в иерархию
    const childs = id => 
        data?.filter( item => item.parent == id )
          .map( 
            (item) => ({...item, columns: childs(item.id)}) 
          ).map(
            (el) => {
              const elCopy = {...el};
              !el.columns?.length && delete el.columns
              return elCopy
              }
            );
      return childs(lvl);
    }