import { BACKEND_URL_PREFIX } from "../../assets/const";
import { createNotification } from "../../components/Notifications/Notifications";
import { checkAndUpdateToken } from "../auth";
import { buildDefaultHeaders } from "../helper";
import {
    GET_MKB_TEN_TOP,
    GET_MKB_TEN_MID,
    GET_MKB_TEN_BOT
} from "../types";


export const getMKBtenTop = () => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
  
      const response = await fetch(`${BACKEND_URL_PREFIX}/voz_diagnoses/top`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const result = (await response.json());
        dispatch({ type: GET_MKB_TEN_TOP, payload: result });
      }
    }
  
  };
  
  export const getMKBtenMid = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
  
      const response = await fetch(`${BACKEND_URL_PREFIX}/voz_diagnoses/mid/${id}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const result = (await response.json());
        dispatch({ type: GET_MKB_TEN_MID, payload: result });
      }
    }
  
  };
  
  export const getMKBtenBot = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
  
      const response = await fetch(`${BACKEND_URL_PREFIX}/voz_diagnoses/bot/${id}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const result = (await response.json());
        dispatch({ type: GET_MKB_TEN_BOT, payload: result });
      }
    }
  
  };