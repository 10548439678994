import React, { useEffect } from 'react'
import styles from './styles.module.css'
import { BiTrash } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux';
import { getEquipmentsByRoom } from '../../../../../../actions/rooms';

const Table = ({setConfirmation, modalOpen}) => {
  
    const dispatch = useDispatch();

    const {equipmentsByRoom} = useSelector(state => state.rooms);

    useEffect(() => {
        modalOpen?.rid && dispatch(getEquipmentsByRoom(modalOpen?.rid)); //Получение списка оборудования
    }, [modalOpen?.rid])

    return (
    <table className={styles.table}>
        <thead>
            <tr>
                <th>Инвентарный номер</th>
                <th>Название</th>
                <th>Состояние</th>
                <th>Удалить</th>
            </tr>
        </thead>
        <tbody>
            {equipmentsByRoom
                ?.map(el => <tr key={el.id}>
                    <td>{el?.inventnumber}</td>
                    <td>{el?.equipname}</td>
                    <td>{el?.status}</td>
                    <td onClick={() => setConfirmation(el)}>
                        <i className={styles.trash}><BiTrash/></i>
                    </td>
                </tr>)}
        </tbody>
    </table>
  )
}

export default Table