import {BACKEND_URL_PREFIX, itemsOnPage, recipientActionTypes} from "../assets/const";
import {createNotification} from "../components/Notifications/Notifications";
import {checkAndUpdateToken} from "./auth";
import {buildDefaultHeaders} from "./helper";
import {
    SET_STUDY_PLANS,
    SET_STUDY_PLANS_LOADING,
    SET_STUDY_PLANS_PAGE,
    SET_STUDY_PLANS_COUNT,
    SET_STUDY_PLAN,
    SET_STUDY_PLAN_LOADING,
    SET_STUDY_PLAN_LIST,
    SET_STUDY_PLAN_LIST_LOADING,
    SET_TRACK_PROFILE,
    SET_TRACK_PROFILE_LOADING
} from "./types";

export const setPage = (pageNumber) => ({type: SET_STUDY_PLANS_PAGE, payload: pageNumber })


export const getStudyPlans = (params) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());

    const { pageNumber } = getState().studyPlans
    const max_results = itemsOnPage
    const paginate_from = itemsOnPage * pageNumber
    let copyParams = {
        ...params,
        limit: max_results,
        offset: paginate_from
    }

    const urlParams = new URLSearchParams(copyParams).toString();

    if (getState().auth.token) {
        dispatch({type: SET_STUDY_PLANS_LOADING, payload: true});
        
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/study_plans?${urlParams}`, {
                method: "GET",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                const {count, plans: data} = await response.json();
                const pageCount = Math.ceil(count / itemsOnPage)
                
                dispatch({type: SET_STUDY_PLANS, payload: data});
                dispatch({ type: SET_STUDY_PLANS_COUNT, payload: {pageCount, count} });
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            dispatch({type: SET_STUDY_PLANS_LOADING, payload: false});
        }
    }
};

export const deleteStudyPlans = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      dispatch({ type: SET_STUDY_PLANS_LOADING, payload: true });
      const response = await fetch(`${BACKEND_URL_PREFIX}/study_plans/${id}`, {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      });
      const result = await response.json();
      if (response.ok) {
        createNotification('success', 'Успешно');
        // const {list, pageNumber} = getState().plans
        // list.length < 2 && dispatch(setPage(pageNumber - 1)) 
        await dispatch(getStudyPlans());
      } else createNotification('error', result.error);
    }
    dispatch({ type: SET_STUDY_PLANS_LOADING, payload: false });
  };

  export const getStudyPlan = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      dispatch({ type: SET_STUDY_PLAN_LOADING, payload: true });
      const response = await fetch(`${BACKEND_URL_PREFIX}/study_plans/content/${id}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const payload = await response.json();
        dispatch({ type: SET_STUDY_PLAN, payload });
      }
    }
    dispatch({ type: SET_STUDY_PLAN_LOADING, payload: false });
  };

  export const editStudyPlan = (data) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      dispatch({ type: SET_STUDY_PLAN_LOADING, payload: true });
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/study_plans/${data.trid}`, {
                method: "PUT",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else { 
                const error = await response.json();
                createNotification('error', error.error);
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getStudyPlans());
        }
    }
    dispatch({ type: SET_STUDY_PLAN_LOADING, payload: false });
};

export const createStudyPlan = (data) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      dispatch({ type: SET_STUDY_PLAN_LOADING, payload: true });
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/study_plans`, {
                method: "POST",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                const error = await response.json();
                createNotification('error', error.error);
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getStudyPlans());
        }
    }
    dispatch({ type: SET_STUDY_PLAN_LOADING, payload: false });
};

export const createCopyStudyPlan = (id) => async(dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_STUDY_PLAN_LOADING, payload: true });
      try {
          const response = await fetch(`${BACKEND_URL_PREFIX}/study_plans/${id}/copy`, {
              method: "POST",
              // body: JSON.stringify(data),
              ...buildDefaultHeaders(getState)
          });
          if (response.ok) {
              createNotification('success', 'Успешно');
          } else {
              const error = await response.json();
              createNotification('error', error.error);
          }
      } catch (error) {
          createNotification('error', 'Ошибка');
      } finally {
          await dispatch(getStudyPlans());
      }
  }
  dispatch({ type: SET_STUDY_PLAN_LOADING, payload: false });
};

export const deleteStudyPlansContent = (data) => async (dispatch, getState) => {

  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_STUDY_PLANS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/study_plans/content/${data.xp_key}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getStudyPlan(data.trid));
    } else createNotification('error', result.error);
  }
  dispatch({ type: SET_STUDY_PLANS_LOADING, payload: false });
};

export const getStudyPlanList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_STUDY_PLAN_LIST_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/courses`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      const courses = payload.courses;
      dispatch({ type: SET_STUDY_PLAN_LIST, payload: courses});
    }
  }
  dispatch({ type: SET_STUDY_PLAN_LIST_LOADING, payload: false });
};

export const createStudyPlanContent = (data) => async(dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_STUDY_PLAN_LIST_LOADING, payload: true });
      try {
          const response = await fetch(`${BACKEND_URL_PREFIX}/study_plans/content`, {
              method: "POST",
              body: JSON.stringify(data),
              ...buildDefaultHeaders(getState)
          });
          if (response.ok) {
              createNotification('success', 'Успешно');
          } else {
            const error = await response.json();
              createNotification('error', error.error || 'Ошибка');
          }
      } catch (e) {
        createNotification('error', 'Ошибка')
      } finally {
          await dispatch(getStudyPlan(data.trid));
      }
  }
  dispatch({ type: SET_STUDY_PLAN_LIST_LOADING, payload: false });
};

export const editStudyPlanContent = (data) => async(dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_STUDY_PLAN_LIST_LOADING, payload: true });
      try {
          const response = await fetch(`${BACKEND_URL_PREFIX}/study_plans/content/${data.xp_key}`, {
              method: "PUT",
              body: JSON.stringify(data),
              ...buildDefaultHeaders(getState)
          });
          if (response.ok) {
              createNotification('success', 'Успешно');
          } else {
              createNotification('error', 'Ошибка');
          }
      } catch (error) {
          createNotification('error', 'Ошибка');
      } finally {
          await dispatch(getStudyPlan(data.trid));
      }
  }
  dispatch({ type: SET_STUDY_PLAN_LIST_LOADING, payload: false });
};

export const getStudyTrackProfile = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_TRACK_PROFILE_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/track_profile`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_TRACK_PROFILE, payload });
    }
  }
  dispatch({ type: SET_TRACK_PROFILE_LOADING, payload: false });
};