import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import Li from '../../components/Li'
import { IoIosAdd, IoIosClose } from 'react-icons/io'
import {thrash} from '../../assets/svg/other'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import { useState } from 'react'
import Modal from './Modal'
import { Pagination } from '../../components/Pagination'
import { setPage, getTermsList, deleteTerm } from '../../actions/terms'
import Table from '../../components/Table'
import { Select } from '../../components/ModalInputs'
import { getSchoolYears } from '../../actions/common'
import Confirmation from '../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi'



export default function Terms () {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {list, pageNumber, pageCount, loading} = useSelector(state => state.terms);
  const {schoolYears} = useSelector(state => state.common);

  const t = list && [...list]
  const highLow = t?.sort((a,b)=>  b.trmid - a.trmid )

  const [selectedItem, setSelectedItem] = useState(null)

  const [search, setSearch] = useState({year: 0});
  const [termForDelete, setTermForDelete] = useState(null)


  useEffect(() => {
    dispatch(setPage(0));
    dispatch(getSchoolYears());
  }, [])
  
  useEffect(() => {
    dispatch(getTermsList())
  }, [pageNumber])

  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }

  const closeModal = () => setSelectedItem(null)
  
  const goHome = () => navigate('/')

  const handleSerach = (name, value) => {
    setSearch(prev => ({...prev, [name]: value}));
    dispatch(getTermsList(value));
  }

  // const thrashClickHandler = (id) => (e) => {
  //   e.stopPropagation()
  //   dispatch(deleteTerm(id))
  // }

  const renderLi = (data) => {
    const {title, school_year_name, term_begin, term_end, term_type_name, trmid} = data
    return (
      <tr key={trmid}>
        <td  onClick={openModal(data)}>{school_year_name}</td>
        <td  onClick={openModal(data)}>{title}</td>
        <td  onClick={openModal(data)}>{term_begin}</td>
        <td  onClick={openModal(data)}>{term_end}</td>
        <td  onClick={openModal(data)}>{term_type_name}</td>
        <td><i className={styles.trash} onClick={deleteClickHandler(data)}><BiTrash/></i></td>
      </tr>
    )
  }

  // const pageClick = ({selected}) => dispatch(setPage(selected))


  const deleteClickHandler = (data) => (e) => {
    setTermForDelete(data)
  }
  
  const acceptConfirm = () => {
    dispatch(deleteTerm(termForDelete))
    setTermForDelete(null)
  }  
  
  const rejectConfirm = () => {
    setTermForDelete(null)
  }

  return (
    <div className={styles.wrapper}>
        {termForDelete &&
          <Confirmation
            title='Вы уверены что хотите удалить период?'
            acceptConfirmation={acceptConfirm}
            rejectConfirmation={rejectConfirm}
          />
        }
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          height={'600px'}
        />
        <h1>Учебные периоды</h1>
        <div className={styles.selectWrapper}>
          <Select 
            label={'Учебный год'}
            name={'year'}
            onChange={({target: {name, value}}) => handleSerach(name, value)}
            value={search.year || schoolYears?.[0]?.xp_key}
            >
            {[{name: 'Все', xp_key: ''}, ...schoolYears]?.map(el => <option value={el.xp_key}>{el.name}</option>)}
          </Select>
        </div>
        <Table style={{marginTop: '2rem', backgroundColor: 'white'}}>
         <thead>
          <tr>
              <th>Учебный год</th>
              <th>Название</th>
              <th>Начало</th>
              <th>Окончание</th>
              <th>Тип</th>
              <th/>
          </tr>
          </thead>
         <tbody>
  
          {highLow?.map(renderLi)}

          </tbody>
        </Table>

        <Button
          style={{padding: '.5rem 1.5rem'}}
          onClick={openModal()}
        >
          <IoIosAdd size={30}/>
          Добавить учебный период
        </Button>
        <footer className={styles.footer}>
            <Button mode='white' onClick={goHome}>Закрыть</Button>
        </footer>
    </div>
  )
}
