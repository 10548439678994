import { api } from "../../App";
import { SET_STUDENTS_LIST, SET_STUDENTS_LOADING } from "../types";



export const getStudents = () => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_STUDENTS_LOADING, payload: true });
      const {data} = await api.get('/students')
      dispatch({ type: SET_STUDENTS_LIST, payload: data })
    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({ type: SET_STUDENTS_LOADING, payload: false });
    }
};