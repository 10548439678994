import * as types from "../actions/types";



const initialState = {
  pageNumber: 0,
  pageCount: 0,
  count: 0,
  list: null,
  currentTerm: {},
  loading: false
};


export default function terms (state = initialState, action) {
  switch (action.type) {
    case types.SET_TERMS_COUNT:
      return {
        ...state,
        ...action.payload
      };
    case types.SET_TERMS_PAGE:
      return {
        ...state,
        pageNumber: action.payload
      };
    case types.SET_TERMS_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case types.SET_CURRENT_TERM:
      return {
        ...state,
        currentTerm: action.payload
      };
    case types.SET_TERMS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}