import { BACKEND_URL_PREFIX, itemsOnPage } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import {
  SET_SCHOOL_WEEKS_LOADING,
  SET_SCHOOL_WEEKS_LIST,
  SET_CURRENT_SCHOOL_WEEK,
  SET_SCHOOL_WEEKS_PAGE,
  SET_SCHOOL_WEEKS_COUNT
} from "./types";


export const setPage = (pageNumber) => ({type: SET_SCHOOL_WEEKS_PAGE, payload: pageNumber })

export const getSchoolWeeksList = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHOOL_WEEKS_LOADING, payload: true });
    const { scYear: xp_key, studyPeriod: trmid} = params;
    const { pageNumber } = getState().school_weeks
    const limit = 220
    const offset = itemsOnPage * pageNumber
    const urlParams = new URLSearchParams({ limit, offset}).toString();
    const response = await fetch(
      xp_key > '0' ?
      `${BACKEND_URL_PREFIX}/week/${xp_key}/${trmid}?${urlParams}` 
      :
      `${BACKEND_URL_PREFIX}/week?${urlParams}`, {
        
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const {cnt: count, weeks} = await response.json();
      const pageCount = Math.ceil(count / itemsOnPage)

      dispatch({ type: SET_SCHOOL_WEEKS_LIST, payload: weeks });
      dispatch({ type: SET_SCHOOL_WEEKS_COUNT, payload: {pageCount, count} });

    }
  }
  dispatch({ type: SET_SCHOOL_WEEKS_LOADING, payload: false });
};


export const getCurrentSchoolWeek = (weekId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHOOL_WEEKS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/week/${weekId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CURRENT_SCHOOL_WEEK, payload });
    }
  }
  dispatch({ type: SET_SCHOOL_WEEKS_LOADING, payload: false });
};

export const deleteSchoolWeek = (weekId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHOOL_WEEKS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/week/settings/${weekId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const {list, pageNumber} = getState().school_weeks
      list.length < 2 && dispatch(setPage(pageNumber - 1)) 
      await dispatch(getSchoolWeeksList(0,0));
    }
  }
  dispatch({ type: SET_SCHOOL_WEEKS_LOADING, payload: false });
};

export const updateSchoolWeek = (weekId, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHOOL_WEEKS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/week/settings/${weekId}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });

    const payload = await response.json()
    if (response.ok) {
      createNotification('success', payload.message )
      await dispatch(getSchoolWeeksList(0,0));
    } else {
      const errorText = response.status === 404 ? "Нарушение уникальности введенных данных" : "Неделя создана "
      createNotification('error', errorText)
    }
  }
  dispatch({ type: SET_SCHOOL_WEEKS_LOADING, payload: false });
};

export const createSchoolWeek = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHOOL_WEEKS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/week/settings`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    const payload = await response.json()
    if (response.ok) {
      createNotification('success', payload.message )
      await dispatch(getSchoolWeeksList(0,0));
    } else {
      const errorText = response.status === 404 ? "Нарушение уникальности введенных данных" : "Неделя создана "
      createNotification('error', errorText)
    }
  }
  dispatch({ type: SET_SCHOOL_WEEKS_LOADING, payload: false });
};