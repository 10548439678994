import React, { useEffect } from 'react'
import Modal from 'react-responsive-modal'
import Button from '../../components/Button'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { Input } from '../../components/ModalInputs'
import { useDispatch } from 'react-redux'
import { createPermissionGroup, updatePermissionGroup } from '../../actions/permissions'
import * as Yup from 'yup'

const validationSchema = Yup.object({
  name: Yup.string().trim().matches(/^[aA-zZ, аА-яЯ\s]+$/, 'Введите текст').required('обязательное поле')
})

export default function RoleModal ({
    open,
    closeModal,
    data,
    addMode
}) {
  
  useEffect(()=> handleReset ,[open])
 

  const modalTitle = data?.name || 'Добавление роли'
  
  const dispatch = useDispatch()

  const updateGroup = ({pmid, name}) => {
    const payload = { name }
    if(payload.name.length>0){
      dispatch(updatePermissionGroup(pmid, payload))
      closeModal()
    }
  }
  
  const createGroup = (values) => {
    dispatch(createPermissionGroup(values))
    closeModal()
    
  }

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset
  } = useFormik({
    initialValues: data || {},
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? createGroup : updateGroup,

  })
  const error = (fieldName) => (touched[fieldName]) && errors[fieldName]

 
  return (
    <Modal
        open={open}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        showCloseIcon={false}  
        animationDuration={1}
        classNames={{
            modal: styles.modal
        }}
    >
        <header>{modalTitle}</header>
        <main>
          <Input
            label='Название'
            name='name'
            value={values.name}
            error={error('name')}
            onChange={handleChange}
            onBlur={handleBlur}

          />
        </main>
        <footer className={styles.footer}>
            <Button mode='white' onClick={handleSubmit}>Сохранить</Button>
            <Button mode='white' onClick={closeModal}>Закрыть</Button>
        </footer>
    </Modal>
  )
}
