import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { Checkbox, Input, Select } from '../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import { createManners, updateManners } from '../../actions/directoryBasicEducation'
import moment from 'moment'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),

})

export default function ModalComponent({ data, closeModal, height, addMode, classGroup, handleCreate, handleUpdate }) {
  console.log('class::: ', classGroup);

  const dispatch = useDispatch()

  // const handleCreate = (values) => {
  //   dispatch(createManners(values))
  //   closeModal()
  // }

  // const handleUpdate = (values) => {
  //   dirty && dispatch(updateManners(values))
  //   closeModal()
  // }

  const { journalControlCourses } = useSelector(state => state.directoryBasicEducation)
  console.log('journalControlCourses::: ', journalControlCourses);

  const {
    values,
    errors,
    touched,
    dirty, // проверяет есть ли изменения
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validateOnChange: true,
    // validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const renderOptions = ({ name, xp_key, gid, f, title, cid }) => <option value={gid || xp_key || cid}>{name || f || title}</option>

  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      classNames={{
        // overlay: styles.overlay,
        modal: styles.custom
      }}
      center
    >
      <>
        <div className={styles.modalLocal} >
          <div className={styles.tabWrapper} >

            <div className={styles.inputsRow}>
              {!addMode ?
                <Input
                  label='Дата'
                  // type='date'
                  name='date'
                  value={values.date}
                  // value={values.date}
                  onChange={handleChange}
                />
                :
                <Input
                  label='Дата'
                  type='date'
                  name='date'
                  value={values.date}
                  // value={values.date}
                  onChange={handleChange}
                />
              }
              <Select
                label='Класс'
                name='group'
                value={values?.group}
                onChange={handleChange}
                options={classGroup}
              >
                <option value=''>{values?.group}</option> // TODO не совпадают id  и название
                {classGroup?.map(renderOptions)}
              </Select>
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Замечания'
                name='checktext'
                value={values.checktext}
                error={error('checktext')}
                onChange={handleChange}
                style={{ width: '600px' }}
              />

            </div>
            <div className={styles.inputsRow}>

              <Select
                label='Предмет'
                name='cid'
                value={values.cid}
                onChange={handleChange}
                options={journalControlCourses}
              >
                <option value=''>{values.cid}</option>
                {journalControlCourses?.map(renderOptions)}
              </Select>
              <Select
                label='Проверяющий'
                name='xp_f_get_mid_fio'
                value={values?.xp_f_get_mid_fio}
                onChange={handleChange}
              // options={typeOfAppeal}
              >
                <option value=''>{values?.xp_f_get_mid_fio !== null ? values?.xp_f_get_mid_fio : "Не выбран"}</option>
                {/* {typeOfAppeal?.map(renderOption)} */}
              </Select>
            </div>

            <div className={styles.inputsRow}>
              <Checkbox label="Выполнено" />
              <Input
                label='Дата'
                type='date'
                name='visitdate'
                // value={values.visitdate}
                // value={values.visitdate}
                // onChange={handleChange}
                disabled
              />
            </div>
            <div className={styles.inputsRow}>

              <Select
                label='Выполняющий'
                name='visitorder'
                // value={values?.visitorder}
                // onChange={handleChange}
                // options={typeOfAppeal}
                disabled

              >
                <option value=''>Выбрать</option>
                {/* {typeOfAppeal?.map(renderOption)} */}
              </Select>

            </div>
          </div>
        </div>

        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </>
    </Modal>

  )
}
