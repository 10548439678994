import * as types from "../actions/types";


const initialState = {
  personnelList: [],
  loading: false
};


export default function schedule (state = initialState, action) {
  switch (action.type) {
 
    case types.GET_PERSONNEL_LIST:
      return {
        ...state,
        personnelList: action.payload
      };
    case types.GET_PERSON:
      return {
        ...state,
        person: action.payload
      };
    case types.GET_PERSON_TABLE:
      return {
        ...state,
        personTable: action.payload
      };
  
      case types.SET_PERSONNEL_LOADING:
        return {
          ...state,
          loading: action.payload
        };
    default:
      return state;
  }
}