import React, {  useRef } from 'react'
import Button from '../../components/Button'
import styles from './styles.module.scss'
import Select from '../../components/Select'
import { useState } from 'react'
import Modal from './modal'
import { useNavigate } from 'react-router-dom'
import Table from '../../components/Table'
import { Pagination } from '../../components/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCandidatesList, setPage } from '../../actions/candidates'
import { getCLasses, getSchoolYears } from '../../actions/common'
import useDoubleClick from 'use-double-click'
import classNames from 'classnames'
import { Checkbox } from '../../components/ModalInputs'
import Input from '../../components/Input'
import { getStudentMain } from '../../actions/students/main'
import { getDiagnoses, getList } from '../../actions/medCenter/medCard'
import {  getDischarge  } from '../../actions/medCenter/discharge'


const stat = [
  { name: "Текущие", value: 'main' },
  { name: "Отчислены", value: 'expelled' },
  { name: "Выпускники", value: 'issue' },
]
const current = [
  { name: "Текущий Олег", value: 1 },
  { name: "Отчисленый Игнат", value: 2 },
  { name: "Выпускник Иван", value: 3 },
]

export default function MedicalCards() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { schoolYears, classes } = useSelector(state => state.common)
  const { diagnosesList, list } = useSelector(state => state.directoryMedCenter)
  const { data } = useSelector(state => state.students)

  const studentData = data && data.main?.[0]

  const [studentId, setStudentId] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])
  const [diagnosisOpen, setDiagnosisOpen] = useState(false)
  const [formData, setFormData] = useState({ type: "main" })

  useEffect(() => {
    dispatch(getCandidatesList())
    dispatch(getCLasses())
    dispatch(getSchoolYears())
    dispatch(getDiagnoses())
    dispatch(getDischarge())

  }, [])

  useEffect(() => {
    studentId && dispatch(getStudentMain(studentId.mid))
  }, [studentId])

  const openModal = (id) => () => setStudentId(id)

  const closeModal = () => setStudentId(null)


  const goHome = () => navigate('/')

  const select = (id) => () => {
    const newSelectedIds = selectedIds.includes(id)
      ? selectedIds.filter(i => i !== id)
      : [...selectedIds, id]
    setSelectedIds(newSelectedIds)
  }

  const renderOption = (option, index) => {
    const { xp_key, value, trmid, cid, gid, work_status, id, pmid, did, name, title, year, access, diagnosis, iddiagnosis } = option
    const optionValue = xp_key || value || trmid || cid || gid || work_status || id || pmid || did || iddiagnosis;
    const optionTitle = name || title || value || year || work_status || access || diagnosis;
    return <option key={`${optionValue}+${index}`} value={optionValue}>{optionTitle}</option>
  }

  const pageClick = ({ selected }) => dispatch(setPage(selected))

  // const selectedEmails = useMemo(() => list?.reduce((acc, { xp_key, id, email }) => selectedIds.includes(xp_key) ? [...acc, { id, email }] : acc, []), [selectedIds])

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }
  const handleGetList = () => {
    console.log('handleGetList::: ', formData);
    dispatch(getList(formData))
  }
  return (
    <div className={styles.wrapper}>
      <Modal
        student={studentId}
        isOpen={!!studentId}
        closeModal={closeModal}
        studentData={studentData}
      />

      <h1>Медкарты</h1>
      <div className={styles.filters}>
        <div>
          <Select
            title='Статус'
            name='type'
            value={formData?.type}
            onChange={handleInputChange}
            options={stat}>
            <option value=''>Выбрать</option>
            {stat?.map(renderOption)}
          </Select>
          {formData.type == "main" &&
            <Select
              title='Класс'
              name='gid'
              value={formData?.gid}
              onChange={handleInputChange}
              options={classes}>
              <option value=''>Выбрать</option>
              {classes?.map(renderOption)}
            </Select>
          }
          {formData.type !== "main" &&
            <Select
              title='Учебный год'
              name='xp_key'
              value={formData?.xp_key}
              onChange={handleInputChange}
              options={schoolYears}>
              <option value=''>Выбрать</option>
              {schoolYears?.map(renderOption)}
            </Select>
          }
          <Checkbox
            label="Все карты"
            onChange={() => setDiagnosisOpen(false)}
            checked={!diagnosisOpen}
          />
          <Checkbox
            label="С диагнозом"
            onChange={() => setDiagnosisOpen(true)}
            checked={diagnosisOpen}
          />
          {diagnosisOpen &&
            <>
              <Select
                name='iddiagnosis'
                value={formData?.iddiagnosis}
                onChange={handleInputChange}
                options={diagnosesList}>
                {/* <option value=''>Выбрать</option> */}
                {diagnosesList?.map(renderOption)}
              </Select>
              <Input
                title='C'
                type='date'
                name='from'
                value={formData?.from}
                onChange={handleInputChange} />
              <Input
                title='По'
                type='date'
                name="to"
                value={formData?.to}
                onChange={handleInputChange} />
            </>
          }
          <Button
            onClick={handleGetList}
          >
            Обновить
          </Button>
        </div>
      </div>

      <Pagination
      // pageCount={pageCount}
      // pageNumber={pageNumber}
      // onPageChange={pageClick}
      // loading={loading}
      >
        {formData?.type == 'main' &&
          <Table className={styles.tableMed}>
            <thead style={{ backgroundColor: '#cce5ff' }}>
              <tr>
                <th>ФИО</th>
                <th>Класс</th>
              </tr>
            </thead>
            <tbody>
              {list && list.map((candidate, pageIndex) =>
                <CandidateItem
                  key={candidate.value}
                  data={candidate}
                  openModal={openModal}
                // select={select}
                />
              )}
              {/* {list && list.map((student) =>
                <tr>
                  <td>{student.fio}</td>
                  <td>{student.school_class}</td>
                </tr>
              )} */}
            </tbody>
          </Table>
        }
        {formData?.type == "expelled" &&
          <Table style={{ marginTop: '1rem', maxHeight: 'none', }}>
            <thead style={{ backgroundColor: '#cce5ff' }}>
              <tr>
                <th>ФИО</th>
                <th>Класс</th>
                <th>Логин</th>
                <th>Льготы</th>
                <th>Приказ</th>
              </tr>
            </thead>
            <tbody>
              {list && list.map((student) =>
                <tr>
                  <td>{student.fio}</td>
                  <td>{student.school_class}</td>
                  <td>{student.login}</td>
                  <td>{student.privilege_name}</td>
                  <td>{student.prikaz}</td>
                </tr>
              )}
            </tbody>
          </Table>
        }
        {formData?.type == "issue" &&
          <Table style={{ marginTop: '1rem', maxHeight: 'none', }}>
            <thead style={{ backgroundColor: '#cce5ff' }}>
              <tr>
                <th>ФИО</th>
                <th>Класс</th>
                <th>Приказ</th>
              </tr>
            </thead>
            <tbody>
              {list && list.map((student) =>
                <tr>
                  <td>{student.fio}</td>
                  <td>{student.school_class}</td>
                  <td>{student.prikaz}</td>
                </tr>
              )}
            </tbody>
          </Table>
        }
      </Pagination>
      <footer className={styles.footer}>
        <div>
          {/* <Button className={styles.footerButton} mode='bordered' onClick={openModal}>{add()}Добавить</Button>
          <Button className={styles.footerButton} mode='bordered'>{remove()}Удалить</Button>
          <Button className={styles.footerButton} mode='bordered' onClick={openModal}>{change()}Изменить</Button>
          <Button className={styles.footerButton} mode='bordered'>{status()}Изменить статус</Button>
          <Button className={styles.footerButton} style={{ padding: '.5rem 0' }}>{excel()}В Excel</Button>
          <Button className={styles.footerButton} style={{ padding: '.5rem 0' }}>{print()}Печать экзаменационных билетов</Button>
          <Button className={styles.footerButton} style={{ padding: '.5rem 0' }}>{interview()}Лист собеседования</Button>
          <Button className={styles.footerButton} style={{ padding: '.5rem 0' }}>{copy()}Копировать</Button> */}
        </div>
        <Button mode='white' onClick={goHome}>Закрыть</Button>
      </footer>
    </div >
  )
}


const CandidateItem = ({
  data,
  openModal,
  select
}) => {

  const { fio, value, school_class, mid } = data
  // const index = pageNumber * itemsOnPage + pageIndex + 1
  // const selected = selectedIds.includes(xp_key)
  const rowRef = useRef()

  useDoubleClick({
    onSingleClick: openModal(data),
    // onDoubleClick: openModal(data),
    ref: rowRef,
    latency: 200
  });


  return (
    <tr className={classNames({})} ref={rowRef}>
      {/* <td>{index}</td> */}
      <td>{fio}</td>
      <td>{school_class}</td>
      {/* <td>{child_class}</td>
      <td>{ }</td>
      <td>{ }</td>
      <td>{ }</td>
      <td>{ }</td>
      <td>{ }</td>
      <td>{checked()}</td>
      <td>Подтверждение</td> */}
    </tr>
  )
}