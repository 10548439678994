import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { Checkbox, Input, Select } from '../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import { createManners, updateManners } from '../../actions/directoryBasicEducation'
import moment from 'moment'
import ModalHistory from '../../components/ModalHistory/ModalHistory'
import { getGroupHistory } from '../../actions/groups'
import Button from '../../components/Button'
import { History } from '../Classing/ModalTabs'
import { ClassingContext } from '../../utils/context'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),

})

export default function ModalComponent({ data, closeModal, height, addMode, classGroup, handleCreate, handleUpdate }) {

  const dispatch = useDispatch()
  const [main, setMain] = useState(true)
  const { groupHistory, modalLoading: loading } = useSelector(state => state.groups)
  const { stageStudyList } = useSelector(state => state.stages_study)
  console.log('stageStudyList::: ', stageStudyList);

  const gid = classGroup && classGroup[0].gid

  useEffect(() => {
    gid && dispatch(getGroupHistory(gid))
  }, [gid])

  // const handleCreate = (values) => {
  //   dispatch(createManners(values))
  //   closeModal()
  // }

  // const handleUpdate = (values) => {
  //   dirty && dispatch(updateManners(values))
  //   closeModal()
  // }

  const { journalControlCourses } = useSelector(state => state.directoryBasicEducation)

  const {
    values,
    errors,
    touched,
    dirty, // проверяет есть ли изменения
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validateOnChange: true,
    // validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const renderOptions = ({ name, xp_key, gid, f, title, cid }) => <option value={gid || xp_key || cid}>{name || f || title}</option>

  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      classNames={{
        // overlay: styles.overlay,
        modal: styles.custom
      }}
      center
    >
      <>
        <div className={styles.inputsRow}>
          <Button onClick={() => setMain(true)}>Главная</Button>
          <Button onClick={() => setMain(false)} disabled={!classGroup} >История</Button>
        </div>
        {main ?
          <div className={styles.modalLocal} >
            <div className={styles.tabWrapper} >
              <div className={styles.inputsRow}>
                <Input
                  label='Название'
                  name='checktext'
                  value={values.checktext}
                  error={error('checktext')}
                  onChange={handleChange}
                  style={{ width: '600px' }}
                />

              </div> <div className={styles.inputsRow}>
                <Input
                  label='Короткое название'
                  name='smallName'
                  value={values.checktext}
                  // error={error('checktext')}
                  onChange={handleChange}
                  style={{ width: '600px' }}
                />

              </div>
              <div className={styles.inputsColumn} >

                <Select
                  label='Специализация'
                  name='spec'
                  value={values?.spec}
                  onChange={handleChange}
                  options={classGroup}
                  style={{ width: '300px' }}
                >
                  <option value=''>{values?.group}</option> // TODO
                  {classGroup?.map(renderOptions)}
                </Select>

                <Select
                  label='Язык обучения'
                  name='spec'
                  value={values?.spec}
                  onChange={handleChange}
                  options={classGroup}
                  style={{ width: '300px' }}
                >
                  <option value=''>{values?.group}</option> // TODO
                  {classGroup?.map(renderOptions)}
                </Select>

                <Select
                  label='Режим обучения'
                  name='spec'
                  value={values?.spec}
                  onChange={handleChange}
                  options={classGroup}
                  style={{ width: '300px' }}
                >
                  <option value=''>{values?.group}</option> // TODO
                  {classGroup?.map(renderOptions)}
                </Select>

                <Select
                  label='Смена'
                  name='spec'
                  value={values?.spec}
                  onChange={handleChange}
                  options={classGroup}
                  style={{ width: '300px' }}
                >
                  <option value=''>{values?.group}</option> // TODO
                  {classGroup?.map(renderOptions)}
                </Select>
                <Select
                  label='Профиль'
                  name='spec'
                  value={values?.spec}
                  onChange={handleChange}
                  options={classGroup}
                  style={{ width: '300px' }}
                >
                  <option value=''>{values?.group}</option> // TODO
                  {classGroup?.map(renderOptions)}
                </Select>
                <Select
                  label='Форма обучения'
                  name='spec'
                  value={values?.spec}
                  onChange={handleChange}
                  options={classGroup}
                  style={{ width: '300px' }}
                >
                  <option value=''>{values?.group}</option> // TODO
                  {classGroup?.map(renderOptions)}
                </Select>
                <Select
                  label='Ступень обучения'
                  name='spec'
                  value={values?.spec}
                  onChange={handleChange}
                  options={classGroup}
                  style={{ width: '300px' }}
                >
                  <option value=''>{values?.group}</option> // TODO
                  {classGroup?.map(renderOptions)}
                </Select>
              </div>



            </div>
          </div>
          :
          classGroup &&
          <ClassingContext.Provider value={{ classId: gid, }}>
            <History subGroups />
          </ClassingContext.Provider>

        }

        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </>
    </Modal >

  )
}
