import { api } from "../../App";
import { getObjectFromArray } from "../../utils/smallHelpers";
import { SET_SETTINGS_CONTROL_DATA, SET_SETTINGS_LOADING } from "../types";



export const getControlData = () => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_SETTINGS_LOADING, payload: true });
      const { data } = await api.get('/administration_options')
      const payload = getObjectFromArray(data.options)
      dispatch({ type: SET_SETTINGS_CONTROL_DATA, payload })

    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({ type: SET_SETTINGS_LOADING, payload: false });
    }
};