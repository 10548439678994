import { BACKEND_URL_PREFIX } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import { GET_CLASSES_FOR_EDUCATIONAL_STRUCTURE, GET_COURSES_FOR_EDUCATIONAL_STRUCTURE, GET_EDUCATIONAL_STRUCTURE, GET_EDUCATIONAL_STRUCTURE_DEPARTMENTS, GET_EDUCATIONAL_STRUCTURE_DEPARTMENTS_INFO,  } from "./types";



  export const getEducationalStructures =() => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
    
      const response = await fetch(`${BACKEND_URL_PREFIX}/staff_structure`, { 
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const result =(await response.json());
        dispatch({ type: GET_EDUCATIONAL_STRUCTURE, payload: result });
      }
    }

  };

  export const getCoursesForEducationalStructures =() => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/staff_structure/courses`, { 
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const result =(await response.json());
        dispatch({ type: GET_COURSES_FOR_EDUCATIONAL_STRUCTURE, payload: result });
      }
    }}

  export const getClassesForEducationalStructures =() => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/plans/complex_theme_plan_stream`, { 
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const result =(await response.json());
        dispatch({ type: GET_CLASSES_FOR_EDUCATIONAL_STRUCTURE, payload: result });
      }
    }

  };
  //  GET all departments
  // export const getEducationalStructuresDepartments =() => async (dispatch, getState) => {
  //   await dispatch(checkAndUpdateToken());
  //   if (getState().auth.token) {
    
  //     const response = await fetch(`${BACKEND_URL_PREFIX}/departments`, { 
  //       method: "GET",
  //       ...buildDefaultHeaders(getState),
  //     });
  //     if (response.ok) {
  //       const result =(await response.json());
  //       dispatch({ type: GET_EDUCATIONAL_STRUCTURE_DEPARTMENTS, payload: result });
  //     }
  //   }

  // };
  //  GET department by did
  export const getEducationalStructuresDepartmentsInfo = (did) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
    
      const response = await fetch(`${BACKEND_URL_PREFIX}/departments/${did}`, { 
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const result =(await response.json());
        dispatch({ type: GET_EDUCATIONAL_STRUCTURE_DEPARTMENTS_INFO, payload: result });
      }
    }

  };

  export const updateChildrensLine= (did, direction) => async (dispatch, getState) => { // ОЧЕРЕДЬ ДЕТЕЙ НА ОДНОМ УРОВНЕ
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(`${BACKEND_URL_PREFIX}/departments/${did}/location/${direction}`, {
          method: "PUT",
                body: JSON.stringify(did,direction),
                ...buildDefaultHeaders(getState),
          });
          if (response.ok) {
            // await dispatch(getEducationalStructures());
            createNotification('success', 'Данные успешно обновлены')
          } else {
            createNotification('error', 'Ошибка обновления данных')
          }
    };
  }
  export const updateChildrensLevel= (did, owner_did) => async (dispatch, getState) => { // ОЧЕРЕДЬ ДЕТЕЙ НА ОДНОМ УРОВНЕ
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(`${BACKEND_URL_PREFIX}/departments/${did}/owner/${owner_did}`, {
          method: "PUT",
                body: JSON.stringify(did,owner_did),
                ...buildDefaultHeaders(getState),
          });
          if (response.ok) {
            // await dispatch(getEducationalStructures());
            createNotification('success', 'Данные успешно обновлены')
          } else {
            createNotification('error', 'Ошибка обновления данных')
          }
    };
  }

//

  export const createChildren = (data) => async (dispatch, getState) => {
    console.log('data::: ', data);
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/departments`, {//TODO
        method: "POST",
        body: JSON.stringify(data),
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        createNotification('success', 'Успешно');
      } else createNotification('error', "Не удалось создать запись");
    }
  };