import { api } from "../../App";
import { SET_STUDENTS_DATA_MAIN, SET_STUDENTS_DATA_OTHER, SET_STUDENTS_LOADING } from "../types";



export const getStudentOtherData = (entity, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_STUDENTS_LOADING, payload: true });
      const { data } = await api.get(`/xp_personal_file/${entity}/user/${id}`)
      dispatch({ type: SET_STUDENTS_DATA_OTHER, payload: data, entity })
    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({ type: SET_STUDENTS_LOADING, payload: false });
    }
};

export const updateStudentOtherData = (entity, id, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    await api.put(`/xp_personal_file/${entity}/${id}`, data)
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};

export const createStudentOtherData = (entity, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_STUDENTS_LOADING, payload: true });
  await api.post(`/xp_personal_file/${entity}`, data)
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};

export const deleteStudentOtherData = (entity, id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    await api.delete(`/xp_personal_file/${entity}/${id}`)
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};