import React, { useState } from 'react'
import styles from './Comment.module.css'
import Button from '../../Button'


const Comment = ( { data, setCommentOpen, postComment } ) => {
    
    const [comment, setComment] = useState(data || '');
  
    const HandleAddComment = () => postComment(comment);

    return (
    <div className={styles.wrapperBg}>
        <div className={styles.wrapper}>
            <p className={styles.title}>Комментарий</p>
            <textarea className={styles.textarea} value={comment} onChange={(e) => setComment(e.target.value)}/>

        <div className={styles.buttons}>
            <Button style={{backgroundColor: "#3689FF", fontSize : "16px"}} onClick={() => HandleAddComment()}>Сохранить</Button>
            <Button style={{backgroundColor: "#3689FF", fontSize : "16px"}} onClick={() => setCommentOpen({mid: null, lessonid: null})}>Отменить</Button>
        </div>

        </div>
    </div>
  )
}

export default Comment