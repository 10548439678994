import { BACKEND_URL_PREFIX } from "../assets/const";
import { checkAndUpdateToken } from "./auth";
import { GET_COMPETENCE, SET_ROOM_TYPES, SET_ROOM_TYPES_LOAD } from "./types";
import { buildDefaultHeaders } from "./helper";
import { createNotification } from "../components/Notifications/Notifications";

export const getCompetence = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_ROOM_TYPES_LOAD, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/competence`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: GET_COMPETENCE, payload: data});
    }
  }
  dispatch({ type: SET_ROOM_TYPES_LOAD, payload: false });
};

export const createCompetence = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/competence`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getCompetence());
    } else {
      createNotification('error', 'Ошибка')
    }
  }
};


export const updateCompetence = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/competence/${data.id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getCompetence());
    }
  }
};

export const deleteCompetence = (competenceId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/competence/${competenceId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getCompetence());
    }
  }
};