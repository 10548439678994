import { BACKEND_URL_PREFIX } from "../../assets/const";
import { createNotification } from "../../components/Notifications/Notifications";
import { checkAndUpdateToken } from "../auth";
import { buildDefaultHeaders } from "../helper";
import {
    GET_DISCHARGE
} from "../types";

export const getDischarge = () => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(`${BACKEND_URL_PREFIX}/discharges`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({ type: GET_DISCHARGE, payload: result });
        }
    }

};

export const updateDischarge = (params) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(`${BACKEND_URL_PREFIX}/discharges/${params.iddischarge}`, {
            method: "PUT",
            body: JSON.stringify(params),
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            await dispatch(getDischarge());
        }
    };
}

export const createDischarge = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/discharges`, {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getDischarge());
        } else createNotification('error', "Не удалось создать запись");
    }
};

export const deleteDischarge = (groupsId) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/discharges/${groupsId}`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getDischarge());
        }
    }
};
