import React from 'react'
import styles from './styles.module.css'
import {Input, Select, Checkbox} from '../../../components/ModalInputs'
import { sex, socialstatus } from '../../../assets/const'
import { useContext } from 'react'
import { StudentsContext } from '../../../utils/context'
import classNames from 'classnames'


export default function Education ({active}) {

  const { mainForm } = useContext(StudentsContext)
  const {values, handleChange} = mainForm

  const renderOptions = ({name, value}) => <option value={value}>{name}</option> 


  return (
    <div className={classNames(styles.tabWrapper, {[styles.dislplayNone]: !active})}>
        <h3>До обучения в учебном заведении</h3>
        <div className={styles.inputsRow}>
          <Input label='Дет. сад (кол.лет)'/>
        </div>
        <div className={styles.inputsRow}>
          <Input label='Учебное заведение до поступления'/>
        </div>
        <div className={styles.inputsRow}>
        <Input
          label='Дата рождения'
          type='date'
          // value={values.child_birth}
          // name='child_birth'
          // onChange={handleChange}
        />
        </div>
        <h3>Зачисление в учебное заведение</h3>
        <div className={styles.inputsRow}>
          <Select 
            label='Социальная группа' 
            name='socialstatus'
            value={values.socialstatus}
            onChange={handleChange}
            >
            {socialstatus.map(renderOptions)}
        </Select>
          <Checkbox label='Группа повышенного психолого-педагогического внимания' />
        </div>
        <div className={styles.inputsRow}>
          <Select label='Наличие специального звания'>{sex.map(renderOptions)}</Select>
        </div>
        <div className={styles.inputsRow}>
          <Select label='Комната проживания'>{sex.map(renderOptions)}</Select>
        </div>
        <h3>Обучение</h3>
        <div className={styles.inputsRow}>
         <Select label='Награжден медалью "За особые успехи в учении"'>{sex.map(renderOptions)}</Select>
        </div>

        <h3>Выбытие из учебного заведения</h3>
        <div className={styles.inputsRow}>
        <Select label='Дата выбытия из учебного заведения'>{sex.map(renderOptions)}</Select>
        
         </div>

        <div className={styles.inputsRow}>
          <Input label='№ протокола Педсовета об отчислении'/>
          <Input label='Причины отчисления'/>
        </div>
        <div className={styles.inputsRow}>
          <Input label='№ приказа по учебному заведению'/>
          <Input label='примечания к отчислению'/>
        </div>
        <div className={styles.inputsRow}>
          <Input style={{width: '500px'}} label='Место выбытия'/>
        </div>
        <div className={styles.inputsRow}>
          <Checkbox label='Переход в гражд. школу' />
          <Checkbox label='Переход в прочие ОУ' />
          <Checkbox label='В гражд. ВУЗ' />
          <Checkbox label='В воен. ВУЗ' />
          <Checkbox label='В ВУЗ др. силовых структур' />
        </div>
     
    </div>
  )
}


// <Select label='Тип восприятия информации '>{sex.map(renderOptions)}</Select>
