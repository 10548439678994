import React, {useEffect, useState} from 'react'
import styles from './styles.module.css'
import Modal from 'react-responsive-modal'
import {Textarea} from '../../../components/ModalInputs'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { createNotification } from '../../../components/Notifications/Notifications'
import { useDispatch, useSelector } from 'react-redux'
import { createMessage, editMessage, getFiles } from '../../../actions/FAQ'
import Files from '../Files'

const defaultParams = { message: '' }; // Параметры по умолчанию
 
const Index = ({type, setType, params, modalClose, modalOpen}) => {

    const question = type?.message || ''
    const dispatch = useDispatch()
    const [values, setValues] = useState(defaultParams)
    const [files, setFiles] = useState([])
    const [filesDelete, setFilesDelete] = useState([])

    const {files_list} = useSelector(state => state.FAQ)

    const handleSubmit = () => { // отправка формы
        if (!values.message || values.message.length < 3) {
            return createNotification('error', 'Минимальная длина сообщения 3 символа')
        } else {
            if (type && type.edit) {
                dispatch(editMessage({message: values.message}, type.chat, Number(type.id), files, filesDelete))
            } else {
                dispatch(createMessage({message: values.message, chat: type.chat, parent: type.id}, files, filesDelete))
            }
        }

        modalClose()
    }

    useEffect(() => {
        if (files_list && files_list?.[type?.id]) {
            let newArr = type.edit === true ? [...files_list?.[type?.id]] : []
            setFiles([...newArr])
        }
    }, [type?.id])

    useEffect(() => {
      if (type && type.edit) {
        setValues(type) 
      } else {
        setValues(defaultParams)
      }
    }, [type])

    // useEffect(() => {
    //   if (type && type.chat && type.id, filesList == null) {
    //       dispatch(getFiles(type.chat, type.id))
    //   }
    // }, [type.chat, type.id, filesList])
    
    

    return (
        <Modal
            close={modalClose}
            open={modalOpen}
            closeOnOverlayClick={true}
            closeOnEsc={false}
            center
            showCloseIcon={false}
            animationDuration={1}
            classNames={{
            modal: styles.modal
        }}
            style={{
            padding: '0',
            margin: '0'
        }}>
            <div className={styles.wrapper}>
                <div className={styles.title}>{type && !type.edit
                        ? 'Ответить на'
                        : 'Редактировать'} сообщение</div>
                
                <div className={styles.body}>
                    {!type.edit && <Textarea
                        className={styles.textarea}
                        label='Сообщение'
                        name='question'
                        value={question}
                        disabled
                        />}
                    <Textarea
                        className={styles.textarea}
                        label='Ответ'
                        name='message'
                        value={values.message}
                        onChange={(e) => setValues({
                        ...values,
                        message: e.target.value
                    })}/>
                </div>

            <div className={styles.files}><Files type={type.edit} files={files} setFiles={setFiles} filesDelete={filesDelete} setFilesDelete={setFilesDelete}/></div>
            
            </div>

            <div className={styles.footer}>
                <ModalFooter closeModal={modalClose} handleSubmit={handleSubmit} isShowInfo={false}/>
            </div>
        </Modal>
    )
}

export default Index