import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox, Input, Select } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { createDish, createPeriodNutrition, getDishFile, getPeriodNutritionFiles, updateDish, updatePeriodNutrition } from '../../../actions/directoryCanteen'
import { api } from '../../../App'
import { GET_DISH_FILE } from '../../../actions/types'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),
  // sort: Yup.number().required('обязательное поле').typeError('Пожалуйста, введите число').nullable().max(999999999, 'Должно быть не более 9 символов'),
  // xp_eat_group: Yup.string().required('обязательное поле'),
})

export default function Main({ closeModal, data, addMode }) {
  console.log('addMode::: ', addMode);

  const dispatch = useDispatch()
  const { groupNutritionList, files, dishFile, dishTypeList } = useSelector(state => state.directoryCanteen)

  const [image, setImage] = useState(null);
  console.log('image::: ', image);
  const [parallels, setParallels] = useState([])
  console.log('parallels::: ', parallels);


  useEffect(() => {
    if (!dishFile) return;
    setImage(dishFile)
    //   fetchPhoto(dishFile[0]?.file_name, values?.xp_key)
    addMode &&  dispatch({ type: GET_DISH_FILE, payload: null})
  }, [dishFile])

  useEffect(() => {
    data?.xp_key && dispatch(getDishFile(data.xp_key))
    data?.eat_period && setParallels(data?.eat_period?.map(el => el.eat_period_xp_key))
    console.log('data?.length::: ', data?.length);
  }, [data])

  useEffect(() => {
    setValues({ ...values, xp_eat_period: parallels })
  }, [parallels])

  const handleCreate = (values) => {
    dispatch(createDish(values, image))
    closeModal()
  }

  const handleUpdate = ({ xp_key, ...values }) => {
    dispatch(updateDish(xp_key, values, image))
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    // validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const handlePick = () => {
    document.getElementById('filePicker').click()
  }

  const handleChangeFile = (e) => {
    setImage((Object.values(e.target.files)[0]))
  }

  // const fetchPhoto = async (file_name, id) => { // file_name ИЗ-ЗА МАССИВА С ФОТО ОТ СЕРВЕРА
  //   const { data } = id && await api.get(`/xp_eat_period/file/${id}/${file_name}`, { responseType: 'blob' })
  //   // const url = URL.createObjectURL(data)
  //   // dispatch({ type: GET_NUTRITION_PERIOD_FILE, payload: url })
  //   setImage(data)
  // }

  const handleChangeCheck = (e) => {
    if (parallels?.find(el => el === +e.target.value)) {
      setParallels(parallels.filter((el, i) => parallels.indexOf(+e.target.value) !== i))
    }
    else { setParallels((prev) => prev ? [...prev, +e.target.value] : +e.target.value) }
    // setParallels((prev) => prev.slice(parallels.indexOf( e.target.value),1))

  }

  return (
    <>
      <div className={styles.modalMain} >
        <div className={styles.tabWrapper} style={{ display: "flex", justifyContent: 'space-evenly', flexDirection: 'column' }}>

          <div className={styles.inputsRow}>
            <Input
              label='Название'
              name='name'
              value={values.name}
              error={error('name')}
              onChange={handleChange}
              style={{ width: '300px' }}
            />
            <div style={{ padding: '15px' }}></div>
            <Input
              label='Выход (по умолчанию)'
              name='exit'
              value={values.exit}
              error={error('exit')}
              onChange={handleChange}
              style={{ width: '300px' }}
            />
            <div style={{ padding: '15px' }}></div>
            <Select
              value={values.edizm}
              error={error('edizm')}
              onChange={handleChange}
              name='edizm'>
              <option value=''>Выберите</option>
              <option value='гр'>гр</option>
              <option value='шт'>шт</option>
              {/* {dishTypeList?.map(({ name, xp_key }) => <option value={xp_key}>{name}</option>)} */}

            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Калорийность (ккал)'
              name='kcal'
              value={values.kcal}
              error={error('kcal')}
              onChange={handleChange}
              style={{ width: '300px' }}
            />
            <div style={{ padding: '15px' }}></div>
            <Select
              label='Тип блюда'
              value={values.c_dishclass}
              error={error('c_dishclass')}
              onChange={handleChange}
              style={{ width: "300px" }}
              name='c_dishclass'>
              <option value=''>Выберите</option>
              {dishTypeList?.map(({ name, c_dishclass }) => <option value={c_dishclass}>{name}</option>)}
            </Select>
          </div>

          <div className={styles.inputsRow}>
            <Input
              label='Цена'
              name='price'
              value={values.price}
              error={error('price')}
              onChange={handleChange}
              style={{ width: '300px' }}
            />
          </div>
          <br />
          <div className={styles.inputsRow} style={{ alignItems: "flex-start" }}>
            <div className={styles.inputsColumn}>

              {dishTypeList?.map(par => {
                console.log('par::: ', par);
                const che = parallels?.find(el => +par.c_dishclass == +el)
                console.log('che::: ', che);
                return (
                  <Checkbox
                    label={par.name}
                    checked={!!che}
                    value={par.c_dishclass}
                    onChange={(e) => handleChangeCheck(e)}
                    name='parallels'
                  // disabled={disabledList2?.find(el => el == par)}
                  />
                )
              }
              )}
            </div>


            <div className={styles.buttons_wrap}>
              <div className={styles.buttons}>
                <>
                  <Button onClick={handlePick}>{image ? 'Изменить фото' : 'Загрузить фото'}</Button>
                  <input
                    className={styles.hidden}
                    type='file'
                    onChange={(e) => handleChangeFile(e)}
                    accept="image/*"
                    id='filePicker'
                  />
                </>

              </div>
              <br />
              {console.log('image:  :: ', typeof image)}
              {!!image &&
                <img
                  // src={typeof image !=='string' ? image}
                  src={URL.createObjectURL(image)}
                  alt="0_o"
                  height={350}
                  width={450}
                />
              }
            </div>

          </div>
        </div>
      </div>

      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}
