import * as types from "../actions/types";



const initialState = {
  list: null,
  loading: false,
  eventTypeById: null,
  loadingById: false,
};


export default function event_type (state = initialState, action) {
  switch (action.type) {
    case types.SET_EVENT_TYPES:
      return {
        ...state,
        list: action.payload
      };
    case types.SET_EVENT_TYPES_LOADING:
      return {
        ...state,
        loading: action.payload
      };
      case types.SET_EVENT_TYPE_BY_ID:
        return {
          ...state,
          eventTypeById: action.payload
          };
      case types.SET_ADS_BY_ID_LOADING:
        return {
          ...state,
          loadingById: action.payload
        };
          default:
      return state;
  }
}