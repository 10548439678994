import { combineReducers } from 'redux'
import { data } from './data'
import { filters } from './filters'
import { loading } from './loading'


const candidates = combineReducers({
    data,
    filters,
    loading
})

export default candidates