import React from 'react'
import styles from './styles.module.css'
import Empty from '../../../components/Empty'
import Loader from '../../../components/Loader'
import ThemeItem from '../ThemeItem'

const Index = ({data, loading, params}) => {

    const getList = (data) => {
        return data.map((item, index) => {
            return (
                <div key={index}>
                    <ThemeItem item={item} params={params}/>
                </div>
            )
        })
    }

    return (
        <div>
            {loading
                ? <Loader/>
                : !!data
                    ?.length
                        ? <div className={styles.wrapper}>{getList(data)}</div>
                        : <Empty/>}
        </div>
    )
}

export default Index