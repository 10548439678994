import React from 'react'
import { useContext, useEffect } from 'react'
import { CandidatesContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.scss'
import Button from '../../../components/Button'
import { getVaccinationModal, getVaccineExclusionsModal } from '../../../actions/medCenter/medCard'
import Contraindications from './Contraindications'
import Inoculations from './Inoculations'


export default function Vaccinations() {

  const { student, closeModal } = useContext(CandidatesContext)

  const dispatch = useDispatch()
  const { vacExclusions, vaccinModal } = useSelector(state => state.directoryMedCenter)

  useEffect(() => {
    dispatch(getVaccineExclusionsModal(student.mid))
    dispatch(getVaccinationModal(student.mid))
  }, [])

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <Contraindications />
          <Inoculations />
        </div>
      </div>
      <div className={styles.modalFooter}>
        <div>
          <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
        </div>
        <Button mode='white' onClick={closeModal}>Сохранить</Button>
        <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </>
  )
}
