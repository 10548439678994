import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getResultsData } from '../../../actions/settings/results'
import styles from '../styles.module.scss'
import Range from '../../../components/Range'
import { SettingsContext } from '../../../utils/context'
import { Checkbox } from '../../../components/ModalInputs'

export default function Results () {
  
  const dispatch = useDispatch()
  const { results } = useSelector(state => state.settings)
  const { form } = useContext(SettingsContext)

  const { values, handleChange, handleBlur, setFieldValue } = form


  useEffect(() => {
    !results && dispatch(getResultsData())
  }, [])
  
  
  const setNewRangeValues = (name) => (values) => {
    setFieldValue(`${name}_from`, numberToString(values[0]))
    setFieldValue(`${name}_to`, numberToString(values[1]))
  }

  const numberToString = (value) => value.toString().replace('.', ',')


  const checkboxClick = (e) => {
    const {name} = e.target
    const value = values[name] === '1' ? '0' : '1'
    setFieldValue(name, value)
  }

  return (
    <div className={styles.resultsWrapper}>
      <h2>Укажите, какой диапазон средних баллов соответствует каждой оценке за учебный год</h2>

      <p>Диапазон средних баллов</p>
      <div className={styles.rangeRow}>
        <Range
          minValue={values.grade_2_from}
          maxValue={values.grade_2_to}
          step={0.01}
          min={1}
          max={5}
          onChange={setNewRangeValues('grade_2')}
          width={'300px'}
        />
        <span>= 2</span>
      </div>
      
      <div className={styles.rangeRow}>
        <Range
          minValue={values.grade_3_from}
          maxValue={values.grade_3_to}
          step={0.01}
          min={1}
          max={5}
          onChange={setNewRangeValues('grade_3')}
          width={'300px'}
        />
        <span>= 3</span>
      </div>
      
      <div className={styles.rangeRow}>
        <Range
          minValue={values.grade_4_from}
          maxValue={values.grade_4_to}
          step={0.01}
          min={1}
          max={5}
          onChange={setNewRangeValues('grade_4')}
          width={'300px'}
        />
        <span>= 4</span>
      </div>
      
      <div className={styles.rangeRow}>
        <Range
          minValue={values.grade_5_from}
          maxValue={values.grade_5_to}
          step={0.01}
          min={1}
          max={5}
          onChange={setNewRangeValues('grade_5')}
          width={'300px'}
        />
        <span>= 5</span>
      </div>

      <Checkbox
        label='Автоматически выставлять оценки за учебный год'
        name='avtomaticheski_vistavlat_otsenki'
        checked={parseInt(values.avtomaticheski_vistavlat_otsenki)}
        onChange={checkboxClick}
      />

      <Checkbox
        label='Разрешить ручную корректировку'
        name='razreshit_ruchnuu_korrektirovku'
        checked={parseInt(values.razreshit_ruchnuu_korrektirovku)}
        onChange={checkboxClick}
      />

    </div>
  )
}
