import * as types from "../../actions/types";



const initialState = {
  data: null,
  video: null
}


export default function kiosk (state = initialState, action) {
  switch (action.type) {
      
    case types.SET_SETTINGS_KIOSK_DATA:
      return {
        ...state,
        data: action.payload
      }
      
    case types.SET_SETTINGS_KIOSK_VIDEO_DATA:
      return {
        ...state,
        video: action.payload
      }

    default:
      return state;
  }
}