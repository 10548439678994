import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { thrash } from '../../assets/svg/other'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { getFormOfEducation, deleteFormOfEducation } from '../../actions/directory'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { createPeriodNutritionFile, deleteDish, deletePeriodNutrition, getDish, getDishList, getGroupNutritionList, getMenuOnDate, getPeriodNutritionList } from '../../actions/directoryCanteen'
import { GET_DISH_FILE } from '../../actions/types'


export default function Dishes() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { formOfEducation } = useSelector(state => state.directory)
  const { periodNutritionList, groupNutritionList, forFile, filePNG, dish } = useSelector(state => state.directoryCanteen)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getFormOfEducation())
    dispatch(getPeriodNutritionList())
    dispatch(getGroupNutritionList())

    dispatch(getDishList())
    dispatch(getDish())
  }, [])


  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }

  const closeModal = () =>
    {
      dispatch({ type: GET_DISH_FILE, payload: null})
      setSelectedItem(null)
    } 

  const goHome = () => navigate('/')


  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteDish(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { name, kcal, exit, edizm, xp_key, price, dishclass_name, has_photo, eat_period } = data
    return (
      <tr >
        <td onClick={openModal(data)} style={{ wordBreak: 'break-all', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</td>
        <td onClick={openModal(data)}>{exit}</td>
        <td onClick={openModal(data)}>{edizm}</td>
        <td onClick={openModal(data)}>{kcal}</td>
        <td onClick={openModal(data)}>{has_photo == 1 ? '+' : ''}</td>
        <td onClick={openModal(data)}>{dishclass_name}</td>
        {periodNutritionList?.map(el =>
          <td onClick={openModal(data)}>{ eat_period.find(eat => eat.eat_period_xp_key == el.xp_key) ? "+" : ""}</td> /// УСЛОВИЕ С ОДНИМ ЭЛЕМЕНТОМ В МАССИВЕ
        )}
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: xp_key, name })}><BiTrash /></i></td>

      </tr>
    )
  }


  return (
    <div className={styles.wrapper} style={{}}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {!!selectedItem &&
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          width={'830px'}
          addMode={!selectedItem?.xp_key}
        />}
      <h1>Блюда</h1>

      <Table className={styles.tables}>
        <thead>
          <tr>
            <th>Название</th>
            <th>Выход (по умолчанию)</th>
            <th>Ед. изм</th>
            <th>Ккал</th>
            <th>Фото</th>
            <th>Тип блюда</th>
            {periodNutritionList?.map(el =>
              <th>{el.name}</th>
            )}
          </tr>
        </thead>
        <tbody>

          {dish?.map(renderLi)}

        </tbody>
      </Table>

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
    </div>
  )
}
