import { BACKEND_URL_PREFIX } from "../../assets/const";
import { createNotification } from "../../components/Notifications/Notifications";
import { checkAndUpdateToken } from "../auth";
import { buildDefaultHeaders } from "../helper";
import {
    GET_SPEC_MED_GROUPS,
    GET_SPEC_MED_GROUPS_TEACHERS,
    GET_SPEC_MED_GROUPS_STUDENTS,
    GET_SPEC_MED_GROUPS_GROUPS,
    GET_ATTACHED_STUDENTS
} from "../types";

export const getSpecMedGroups = (year) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const urlParams = new URLSearchParams(year).toString();
        const response = await fetch(`${BACKEND_URL_PREFIX}/specmedgroup?${urlParams}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({ type: GET_SPEC_MED_GROUPS, payload: result });
        }
    }

};

export const getAttachedStudents = (aid) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/specmedgroup/${aid}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({ type: GET_ATTACHED_STUDENTS, payload: result });
        }
    }
};

export const getSpecMedGroupsTeachers = () => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/teachers/smg`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({ type: GET_SPEC_MED_GROUPS_TEACHERS, payload: result });
        }
    }

};
export const getSpecMedGroupsStudents = (group) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const urlParams = group !== null ? new URLSearchParams(group).toString() : '';
        const response = await fetch(`${BACKEND_URL_PREFIX}/people/student/smg?${urlParams}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({ type: GET_SPEC_MED_GROUPS_STUDENTS, payload: result });
        }
    }

};
export const getSpecMedGroupsGroups = () => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/groups/year/liter`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({ type: GET_SPEC_MED_GROUPS_GROUPS, payload: result });
        }
    }

};

export const updateSpecMedGroup = (params, aid) => async (dispatch, getState) => {
    console.log('params::: ', params);
    const year = { school_year: params.school_year }
    console.log('year::: ', year);
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/specmedgroup/${aid}`, {
            method: "PUT",
            body: JSON.stringify(params),
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            await dispatch(getSpecMedGroups(year));
        }
    };
}

export const createSpecMedGroup = (data) => async (dispatch, getState) => {
    console.log('data::: ', data);
    const year = { school_year: data.school_year }
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/specmedgroup`, {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getSpecMedGroups(year));
        } else createNotification('error', "Не удалось создать запись");
    }
};

export const deleteSpecMedGroup = (year, groupsId) => async (dispatch, getState) => {
    console.log('groupsId::: ', groupsId);
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/specmedgroup/${groupsId}`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getSpecMedGroups(year));
        }
    }
};
//////////