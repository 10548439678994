import React, { useContext } from 'react'
import styles from '../styles.module.scss'
import Button from '../../../components/Button'
import { DisciplineStructureContext } from '../../../utils/context'
import { useNavigate } from 'react-router-dom'


export default function Footer() {
  
  const {editRow, saveData, selectedRow, buttonsBlockRef, createRow,} = useContext(DisciplineStructureContext)
  const navigate = useNavigate()

  const goBack = () => navigate(-1)
  return (
    <footer className={styles.footer} ref={buttonsBlockRef}>
        <div>
            <Button
              className={styles.footerButton}
              mode='bordered'
              onClick={()=> createRow('new')}
              disabled={!selectedRow}
            >
                +
            </Button>
            <Button
              className={styles.footerButton}
              mode='bordered'
              onClick={()=> createRow()}
              disabled={!selectedRow}
            >
                + ↴
            </Button>
            <Button
              className={styles.footerButton}
              mode='bordered'
              // onClick={()=> createRow()}
              disabled={!selectedRow}
            >
              -
            </Button>
            <Button
              className={styles.footerButton}
              mode='bordered'
              onClick={()=> editRow('up')}
              // disabled={move}
              disabled={ !selectedRow}
            >
              ⭡
            </Button>
            <Button
              className={styles.footerButton}
              mode='bordered'
              onClick={()=> editRow('down')}
              // disabled={move}
              disabled={!selectedRow}
            >
              ⭣
            </Button>
            <Button
              className={styles.footerButton}
              mode='bordered'
              disabled={true}
            >
              ⬎
            </Button>
            <Button
              className={styles.footerButton}
              mode='bordered'
              disabled={true}
            >
              ⬏
            </Button>
        </div>
        <div>
          <Button mode='white' onClick={saveData}>Сохранить</Button>
          <Button mode='white' onClick={goBack}>Закрыть</Button>
        </div>
    </footer>
  )
}
