import { BACKEND_URL_PREFIX } from "../../assets/const";
import { createNotification } from "../../components/Notifications/Notifications";
import { checkAndUpdateToken } from "../auth";
import { buildDefaultHeaders } from "../helper";
import {
    GET_HEALTH_GROUP
} from "../types";

export const getHealthgroup = () => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(`${BACKEND_URL_PREFIX}/healthgroup `, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({ type: GET_HEALTH_GROUP, payload: result });
        }
    }

};

export const updateHealthgroup = (params) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(`${BACKEND_URL_PREFIX}/healthgroup/${params.hid}`, {
            method: "PUT",
            body: JSON.stringify(params),
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            await dispatch(getHealthgroup());
        }
    };
}

export const createHealthgroup = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/healthgroup`, {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getHealthgroup());
        } else createNotification('error', "Не удалось создать запись");
    }
};

export const deleteHealthgroup = (groupsId) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/healthgroup/${groupsId}`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getHealthgroup());
        }
    }
};