import React from 'react'
import { useContext } from 'react'
import { CandidatesContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCandidateAdditionalEducation } from '../../../actions/candidates'
import styles from './styles.module.scss'
import Button from '../../../components/Button'
import { Checkbox, Input } from '../../../components/ModalInputs'
import { useFormik } from 'formik'


export default function Education () {
  
  const {candidateId, closeModal} = useContext(CandidatesContext)
  const dispatch = useDispatch()
  const { additional_education: data } = useSelector(state => state.candidates.data)

  useEffect(() => {
    dispatch(getCandidateAdditionalEducation(candidateId))
  }, [])
  
  const {
    values,
    errors,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: data || {},
    enableReinitialize: true,
    onSubmit: console.log
  })
  
  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
            <Input
              style={{width: '750px'}}
              label='Художественное'
              name='arteduc'
              value={values.arteduc}
              onChange={handleChange}
            />
          </div>
          <div className={styles.inputsRow}>
            <Input
              style={{width: '750px'}}
              label='Музыкальное'
              name='musiceduc'
              value={values.arteduc}
              onChange={handleChange}
            />
          </div>
          <div className={styles.inputsRow}>
            <Input
              style={{width: '750px'}}
              label='Спортивное'
              name='sporteduc'
              value={values.arteduc}
              onChange={handleChange}
            />
          </div>
          <div className={styles.inputsRow}>
            <Input
              style={{width: '750px'}}
              label='Другое'
              name='othereduc'
              value={values.arteduc}
              onChange={handleChange}
              />
          </div>
          <div className={styles.inputsRow}>
            <Input
              style={{width: '70px'}}
              label='Оценка общественных творческих и спортивных достижений'
              name='art_grade'
              value={values.arteduc}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className={styles.modalFooter}>
          <div>
              <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
              <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          </div>
          <Button mode='white' onClick={handleSubmit}>Сохранить</Button>
          <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </>
  )
}
