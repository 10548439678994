
import { BACKEND_URL_PREFIX } from "../../assets/const";
import { createNotification } from "../../components/Notifications/Notifications";
import { checkAndUpdateToken } from "../auth";
import { buildDefaultHeaders } from "../helper";
import {
    GET_TYPES_OF_VACCINATIONS,
    GET_VACCINATIONS,
    GET_VACCINATIOS_CURR,
    SET_MEDBLOCK_LOADING
} from "../types";

export const getVaccinations = () => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(`${BACKEND_URL_PREFIX}/c_vaccine`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({ type: GET_VACCINATIONS, payload: result });
        }
    }
};
// ТИП ВАКЦИНЫ (ВТОРАЯ МОДАЛКА)
// сами вакцины - короткий typesVaccinations:
export const getTypesOfVaccinations = () => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_MEDBLOCK_LOADING, payload: true });
        const response = await fetch(`${BACKEND_URL_PREFIX}/c_vac_type`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({ type: GET_TYPES_OF_VACCINATIONS, payload: result });
        }
    }
    dispatch({ type: SET_MEDBLOCK_LOADING, payload: false });
};

// коетр прививка - длинная
export const getVaccinationsCurr = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_MEDBLOCK_LOADING, payload: true });

        const response = await fetch(`${BACKEND_URL_PREFIX}/c_vaccine/${id} `, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = (await response.json());

            dispatch({ type: GET_VACCINATIOS_CURR, payload: result });
        }
    }
    dispatch({ type: SET_MEDBLOCK_LOADING, payload: false });

};

// сами вакцины - короткий
export const updateTypesOfVaccinations = (params) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(`${BACKEND_URL_PREFIX}/c_vac_type/${params.c_vac_type}`, {
            method: "PUT",
            body: JSON.stringify(params),
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            await dispatch(getTypesOfVaccinations());
        }
    };
}
// сами вакцины - короткий
export const createTypesOfVaccinations = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/c_vac_type`, {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getTypesOfVaccinations());
        } else createNotification('error', "Не удалось создать запись");
    }
};

// СЕЛЕКТ С КОРОТКИМИ ВАКЦИНАМИ typesVaccinations
export const createVaccine = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/c_vaccine`, {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getVaccinations());
        } else createNotification('error', "Не удалось создать запись");
    }
};

export const updateVaccine = (params) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(`${BACKEND_URL_PREFIX}/c_vaccine/${params.c_vac_type}`, {
            method: "PUT",
            body: JSON.stringify(params),
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            await dispatch(getVaccinations());
        }
    };
}

export const deleteTypesOfVaccinations = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/c_vac_type/${id}`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getTypesOfVaccinations());
        }
    }
};

export const deleteVaccinations = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/c_vaccine/${id}`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getVaccinations());
        }
    }
};