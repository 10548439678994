import React from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { Checkbox, Input, Select } from '../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import { createSubjectsExtraEdu, updateSubjectsExtraEdu } from '../../actions/directoryExtraEducation'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').min(4, 'Должно быть не менее 4 символов').max(30, 'Должно быть не более 30 символов'),
  status: Yup.string().required('обязательное поле'),
  type: Yup.string().required('обязательное поле')
})

const statusList = [
  { status: "Идет" },
  { status: "Закрыт" }
]
const type = [
  { type: 'другое', typeId: 1 },
  { type: 'спортивный', typeId: 2 },
  { type: 'военный', typeId: 3 },
]

export default function ModalComponent({ data, closeModal, height, addMode }) {

  const dispatch = useDispatch()

  const handleCreate = (values) => {
    console.log('handleCreatevalues::: ', values);
    dispatch(createSubjectsExtraEdu(values))
    closeModal()
  }

  const handleUpdate = (values) => {
    console.log('handleUpdatevalues::: ', values);
    dispatch(updateSubjectsExtraEdu(values))
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const checkboxClick = (e) => {
    const {name, value} = e.target
;
    const newValue = values[name] ? null :1

    setFieldValue(name, newValue)
  }

  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      classNames={{
        modal: styles.custom
      }}
    >
      <>
        <div className={styles.modalLocal} >
          <div className={styles.tabWrapper} >

            <div className={styles.inputsColumn}>
              <Input
                label='Название'
                name='name'
                value={values.name}
                error={error('name')}
                onChange={handleChange}
                style={{ width: '600px' }}
              />

              <Select
                label='Статус'
                value={values.status }
                onChange={handleChange}
                name='status'
                error={error('status')}
              >
                <option value='' >Выбрать статус</option>
                {statusList?.map(({ status }) => <option value={status}>{status}</option>)}
              </Select>
              <Select
                label='Тип'
                value={values.type}
                onChange={handleChange}
                name='type'
                error={error('type')}
              >
                <option value='' >Выбрать тип</option>
                {type?.map(({ type, typeId }) => <option value={typeId}>{type}</option>)}
              </Select>
            </div>

            <Checkbox
              label='Действующие освобождения от физкультуры'
              checked={values.osvobojdeniefiz}
              value={values.osvobojdeniefiz}
              onChange={checkboxClick}
              name='osvobojdeniefiz'
              style={{ margin: '15px' }}
            />

          </div>
        </div>

        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </>
    </Modal>

  )
}
