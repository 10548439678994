import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { thrash } from '../../assets/svg/other'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal/Modal'
import Table from '../../components/Table'
import { getFormOfEducation, deleteFormOfEducation } from '../../actions/directory'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { getHomeworkList } from '../../actions/directoryBasicEducation'
import { Input, Select } from '../../components/ModalInputs'
import moment from 'moment'
import { getCLasses } from '../../actions/common'



export default function Homework() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { canteenStaffList, canteenStaff, image, } = useSelector(state => state.directoryCanteen)
  const { getHomeworks } = useSelector(state => state.directoryBasicEducation)
  const { schoolYears, courses, classes } = useSelector(state => state.common)

  const individualDZ = getHomeworks?.homeworks?.map(el=> !!el.students.length && el.students)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);
  const [selectedDay, setSelectedDay] = useState({ date: moment().format('YYYY-MM-DD') })
  
  const [date, setDate] = useState([])
  const [arr, setArr] = useState([])
  console.log('arr::: ', arr);

  useEffect(() => {
    dispatch(getCLasses())
    date?.date &&  dispatch(getHomeworkList(date))
  }, [date])


  const openModal = (data) => () => setSelectedItem(data || {})

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')


  const acceptConfirmation = () => {
    closeModal();
    // dispatch(deleteCanteenStaff(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLiRigth = (data) => {
    const { fio, student_homework_text, student_mid } =   data
    // console.log('student_homework_text::: ', student_homework_text);
    return (
      <tr key={student_mid}>
        <td onClick={openModal(data)}>{fio}</td>
        <td onClick={openModal(data)}>{student_homework_text}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ student_mid, fio })}><BiTrash /></i></td>
      </tr>
    )
  }
  const renderLiLeft = (data) => {
    console.log('data::: ', data);
    const { lesson_name, homework_text, sheid } =   data
    // console.log('student_homework_text::: ', student_homework_text);
    return (
      <tr key={sheid}>
        <td onClick={openModal(data)}>{lesson_name}</td>
        <td onClick={openModal(data)}>{sheid}</td>
        <td onClick={openModal(data)}>{homework_text}</td>
        {/* <td><i className={styles.trash} onClick={() => setConfirmation({ student_mid, fio })}><BiTrash /></i></td> */}
      </tr>
    )
  }

  const renderOption = (item) => {
    const { name, gid, xp_key, title, cid } = item
    const optionName = name || title
    const optionValue = gid || xp_key || cid
    return <option key={optionValue} value={optionValue}>{optionName}</option>
  }

  const handleChange = (e) => {
    const {name, value}= e.target
    setDate({...date,
      [name]: value
    })
  }


  function createStudentArray(data) {
    return data?.map(item => {
      if (!item.students || item.students.length === 0) {
        return null; 
      }
      return item.students.map(student => (!!student.fio && student));
    }).filter(Boolean); 
  }
  const studentArray = createStudentArray(getHomeworks?.homeworks);


  return (
    <div className={styles.wrapper}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {selectedItem &&
        <Modal
          closeModal={closeModal}
          data={selectedItem}
        />
      }
      <h1>Домашние задания</h1>
      <div className={styles.inputsRow} style={{ alignItems: 'flex-end' }}>
        <Select
          label='Класс'
          name='gid'
          onChange={handleChange}
        >
          <option value=''>Выберите класс</option>
          {classes?.map(renderOption)}
        </Select>
        <Input
          label='Дата'
          type='date'
          name='date'
          value={date.date || selectedDay?.date  }
          onChange={handleChange}
        />
        <Button >Обновить</Button>
      </div>
      <div className={styles.inputsRow}>
        <Table className={styles.tables}>
          <thead>
            <tr>
              <th>Предмет</th>
              <th>Подгруппа</th>
              <th>Домашнее задание</th>
            </tr>
          </thead>
          <tbody>

            {getHomeworks?.homeworks?.map(renderLiLeft)}

          </tbody>
        </Table>
        <Table className={styles.tables}>
          <thead>
            <tr>
              <th colSpan={2}>Индивидуальное дз</th>
            </tr>
            <tr>
              <th>ФИО</th>
              <th>Текст</th>
            </tr>
          </thead>
          <tbody>
            {/* // другой массив 2 коло(нки */}
            {studentArray?.map(el => el.map(renderLiRigth))}

          </tbody>
        </Table>
        <div style={{ width: '20vw', height: '400px', backgroundColor: "#e5e5e5" }}>
          <p>Прикрепленные файлы</p>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
    </div >
  )
}
