import React, { useEffect, useRef, useState } from 'react';
import styles from "./styles.module.css"
import { useOnClickOutside } from '../../hooks/useOnClickOutside';



//СЫРОЙ СЕЛЕКТ - НЕОРИГИНАЛ
const SelectPal = ({ options, values, title, onChange, width, setFieldValue, valuesName, disabled }) => {


    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const [arr, setArr] = useState()

    const select = useRef()
    // 
    useEffect(() => {
        options && !options.find(el => el.name == selectedValue) && setSelectedValue("Выбрать")
        // options && !!options.length && setSelectedValue("Выбрать")
    }, [options])

    // useEffect(() => {
    //     console.log('options.find(el => el.value !== values?.trmid)::: ', options?.find(el => el.value !== values?.trmid));
    //     values?.trmid && options.find(el => el.value !== values) && setSelectedValue('Выбрать')
    // }, [values?.trmid && options])

    const handleSelectChange = (e, name) => {
        const nameAtt = e.target.getAttribute('name');
        setSelectedValue(name);
        setIsOpen(false);
        setFieldValue(nameAtt, e.target.value)
    };

    const handleHoverOff = () => {
        setIsOpen(false);
    }
    useOnClickOutside(select, handleHoverOff)

    return (
        <div
            className={styles.customSelect}
            ref={select}

        >
            <p>{title}</p>
            <div
                onClick={() => setIsOpen(true)}
                className={styles.inputSel}
                style={disabled ? { opacity: 0.6, cursor: 'not-allowed' } : { width: width }}
            > {!!selectedValue.length ? selectedValue : "Выбрать"}
            </div>

            {isOpen && (
                <ul className={styles.optionsList}
                >
                    {options?.map((el) => {
                        return <li key={el.value} value={el.value} name={valuesName} onClick={(e) => handleSelectChange(e, el.name)}>{el.name}</li>
                    })}
                </ul>
            )
            }

        </div >

    );
};

export default SelectPal;
