import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { getRoomsList, editRooms, addRooms, deleteRooms, getTFOTypeList } from '../../actions/rooms';
import { BiTrash } from 'react-icons/bi';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Confirmation from '../../components/Confirmation/Confirmation';
import ModalComponent from './Modal';
import { useFormik } from 'formik';
import * as Yup from 'yup'


const validationSchema = Yup.object().shape({
    name: Yup.string().required('обязательное поле').max(255, 'Должно быть не более 255 символов'),
    short_name: Yup.string().required('обязательное поле').max(100, 'Должно быть не более 100 символов'),
    // floor: Yup.string().required('обязательное поле').max(9, 'Должно быть не более 9 символов').matches(/^[0-9\x2D\x2C]+$/, "Пожалуйста, введите число"),
    volume: Yup.number().typeError('Пожалуйста, введите число').required('обязательное поле').max(999999, 'Должно быть не более 6 символов'),// TODO регулярка не работает + СЕРВЕР берет только цифры
    floor: Yup.number().typeError('Пожалуйста, введите число').required('обязательное поле').test('max-value', 'Диапазон значений от -127 до 127', (value) => { return Number.isInteger(value) && value >= -127 && value <= 127 }),// TODO регулярка не работает + СЕРВЕР берет только цифры
    volume_groups: Yup.number().typeError('Пожалуйста, введите число').required('обязательное поле').max(999999, 'Должно быть не более 6 символов'),
    area: Yup.number().typeError('Пожалуйста, введите число').required('обязательное поле').max(99999, 'Должно быть не более 5 символов'),
    volume_m3: Yup.number().typeError('Пожалуйста, введите число').required('обязательное поле').max(99999, 'Должно быть не более 5 символов'),
    xp_building: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),
    section: Yup.string().required('обязательное поле').max(15, 'Должно быть не более 15 символов'),
    extra_info: Yup.string().max(255, 'Должно быть не более 255 символов'),
})

const Rooms = () => {

    const dispatch = useDispatch();
    const { list, loading, tfo_types } = useSelector(state => state.rooms)

    const [modalOpen, setModalOpen] = useState(null);

    const [confirmation, setConfirmation] = useState(false)

    const acceptConfirmation = () => {
        dispatch(deleteRooms(confirmation.rid));
        setConfirmation(false);
    };
    const rejectConfirmation = () => {
        setConfirmation(false);
    };

    useEffect(() => {
        dispatch(getRoomsList());
        dispatch(getTFOTypeList());
    }, []);


    const handleCreateRoom = (values) => {
        // const data = values.extra_info.length <0 ? [...values, extra_info = ' '] : values
        dispatch(addRooms(values))
        closeModal()
    }

    const handleUpdateRoom = () => {
        // const data = values.extra_info.length <0 ? [...values, extra_info = ' '] : values
        dispatch(editRooms(values))
        closeModal()
    }
    const {
        values,
        errors,
        touched,
        submitCount,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues: !modalOpen ? {} : modalOpen,
        enableReinitialize: true,
        validateOnChange: true,
        // validateOnBlur: true,
        validationSchema: validationSchema,
        onSubmit: modalOpen?.name ? handleUpdateRoom : handleCreateRoom
    })

    const error = (fieldName) => errors[fieldName]

    const handleModal = (data) => {
        setModalOpen(data)
    }

    const openModal = () => setModalOpen({
        capacity_range: 0,
        name: '',
        short_name: '',
        tfoid: 0,
        type: 0,
        mid: 0,
        extra_info: ' ',
        xp_building: '',
    });
    const closeModal = () => setModalOpen(false);

    // const handleSubmit = () => {

    //     if (modalOpen?.name || modalOpen?.mid || modalOpen?.tfoid || modalOpen?.type) {
    //         if (modalOpen
    //             ?.rid) {
    //             dispatch(editRooms(modalOpen));
    //         } else {
    //             dispatch(addRooms(modalOpen));
    //         }
    //         setModalOpen(false);
    //     } else {
    //         createNotification('error', `Заполните все обязательные поля`);
    //     }
    // }

    return (
        <div className={styles.wrapper}>
            {confirmation && (<Confirmation
                top="30px"
                left="40px"
                title="Удалить кабинет?"
                confirmation={'"' + confirmation
                    ?.name + '"'}
                acceptConfirmation={() => acceptConfirmation(confirmation)}
                rejectConfirmation={rejectConfirmation} />)}
            {modalOpen != null
                ? <ModalComponent
                    modalOpen={modalOpen}
                    closeModal={closeModal}
                    setModalOpen={setModalOpen}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    values={values}
                    error={error}
                />
                : <></>}

            {loading && <div className={styles.loader}><Loader /></div>}
            <h1>Кабинеты</h1>
            {!loading
                ? <> <div className={styles.tableWrapper}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>Название</th>
                                <th>Номер</th>
                                <th>Этаж</th>
                                <th>Корпус</th>
                                <th>Ответственный</th>
                                <th>Тип</th>
                                <th>Посадочных мест</th>
                                <th>Удалить</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list
                                ?.map(el => <tr key={el.rtid}>

                                    <td onClick={() => handleModal(el)}>{el?.name}</td>
                                    <td onClick={() => handleModal(el)}>{el?.short_name}</td>
                                    <td onClick={() => handleModal(el)}>{el?.floor}</td>
                                    <td onClick={() => handleModal(el)}>{el?.section}</td>
                                    <td onClick={() => handleModal(el)}>{el?.teacher}</td>
                                    <td onClick={() => handleModal(el)}>{el?.type !== 0 ? el?.type : ''}</td>
                                    <td onClick={() => handleModal(el)}>{el?.volume}</td>
                                    <td onClick={() => setConfirmation(el)}>
                                        <i className={styles.trash}><BiTrash /></i>
                                    </td>
                                </tr>)}
                        </tbody>
                    </table>
                </div>

                    <div className={styles.buttons}>
                        <Button onClick={openModal}>Добавить</Button>
                    </div>
                </>
                : <></>}
        </div>
    )
}

export default Rooms