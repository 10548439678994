import moment from "moment"

export const getCurrentPeriodUtils =  (periodList) => {
    const period = periodList?.find(({term_begin, term_end}) => moment().isBetween(moment(term_begin, 'DD.MM.YYYY'),moment(term_end, 'DD.MM.YYYY')) )
return period?.trmid || ''
}

export const getCurrentYearUtils =  (list, selectedDate) => { /// текущий год 
    const year = list?.find(({begdate, enddate}) => moment(selectedDate).isBetween(moment(begdate, 'YYYY.MM.DD'),moment(enddate, 'YYYY.MM.DD')) )
return year?.xp_key || ''
}

// for school_weeks - trmid_list
export const getCurrentTermUtils =  (list, selectedDate) => {
    let r = []
    const year = list?.filter(({term_begin, term_end}) => moment(selectedDate,'DD.MM.YYYY' ).isBetween(moment(term_begin, 'DD.MM.YYYY'),moment(term_end, 'DD.MM.YYYY')))
    r.push(year.map(el => el.trmid))
return r[0]  || ''
}
