import { BACKEND_URL_PREFIX, itemsOnPage } from "../assets/const";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import { GET_THEME_PLANNING, SET_THEME_PLANNING_LOAD } from "./types";


export const getThemePlanning = (mid, cid, week) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_THEME_PLANNING_LOAD, payload: true });
    
    const response = await fetch(`${BACKEND_URL_PREFIX}/tema_planner/${mid}/${cid}/${week}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();

      dispatch({ type: GET_THEME_PLANNING, payload: data });
    }
  }
  dispatch({ type: SET_THEME_PLANNING_LOAD, payload: false });

};

