import React from 'react'
import Button from '../Button'
import styles from './Confirmation.module.css'

export default function Confirmation({
    absolute = true,
    top = "",
    left = "",
    confirmation = "",
    title = "Вы уверены что хотите удалить",
    color = '#000',
    acceptConfirmation = () => {},
    rejectConfirmation = () => {}    
}) {
  return (
    <div className={absolute ? styles.absolute : null}>
      <div className={styles.confirmation} style={absolute ? null : {position: "absolute", top : top, left : left}}>
      <div className={styles.textBlock} styles={{color: color}}>
        {title}
        {confirmation ? 
           <span> {confirmation}</span>
        :
        ""  
        }
        
      </div>
      <div className={styles.buttons}>
        <Button style={{backgroundColor: "#3689FF", fontSize : "16px"}} onClick={() => acceptConfirmation(confirmation)}>Да</Button>
        <Button style={{backgroundColor: "#3689FF", fontSize : "16px"}} onClick={() => rejectConfirmation()}>Нет</Button>
        </div> 
    </div>
    </div>
  )
}
