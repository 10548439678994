import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import Modal from 'react-responsive-modal'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { studyPlansModes } from '../../../assets/const'
import cn from 'classnames'
import Main from './Main'
import Settings from './Settings'
import { useDispatch, useSelector } from 'react-redux'
import { getStudyPlan, editStudyPlan, createStudyPlan } from '../../../actions/studyPlans'
import moment from 'moment'
import { createNotification } from '../../../components/Notifications/Notifications'

const Index = ({modalOpen, closeModal, props}) => {
    
    const [mode, setMode] = useState('main')
    const [values, setValues] = useState({})
    const {item} = useSelector(state => state.studyPlans)


    const dispath = useDispatch()

    useEffect(() => {
        setValues(modalOpen)
    }, [modalOpen?.trid])

    useEffect(() => {
        if (modalOpen.trid !== 'new') {
            dispath(getStudyPlan(modalOpen.trid))
        }
    }, [modalOpen.trid])

    const handleSubmit = () => {
        if ( values.name && values.edupart && values.periodfrom && values.periodtill ) {
            if (values.trid && values.trid !== 'new') {
                dispath(editStudyPlan({
                    ...values,
                    periodfrom: moment(values.periodfrom).format('YYYY-MM-DD'),
                    periodtill: moment(values.periodtill).format('YYYY-MM-DD')
                }))
                closeModal()
            } else {
                const data = values 
                delete data.trid
                dispath(createStudyPlan(data))
                closeModal()
            }
        } else {
            createNotification('error', 'Заполните все обязательные поля')
        }
    }

    const renderLi = ({ name, value, icon }) => {
        if (values.trid === 'new' && value === 'settings') return
        const selected = value === mode
        return (
            <li
                onClick={() => setMode(value)}
                className={cn(styles.modalMenuItem, {
                    [styles.selectedMenuItem]: selected
                })}
            >
                {icon()}
                {name}
            </li>
        )
    }

    const renderTab = () => {
        switch (mode) {

            case 'main': return <Main values={values} setValues={setValues}/>
            case 'settings': return <Settings data={item} values={values} setValues={setValues}/>
            default: return
        }
    }

    return (

        <Modal
            classNames={{
            modal: styles.modalMain
        }}
            open={modalOpen}
            onClose={closeModal}
            closeOnOverlayClick={true}
            closeOnEsc={true}
            showCloseIcon={true}
            animationDuration={1}
            style={{
                modal: {
                    background: 'white',
                    width: '1200px'
                }
            }}
            >

                <div className={styles.modalContent}>
                    <div className={styles.modalMenu}>
                        <ul>{studyPlansModes.map(renderLi)}</ul>
                    </div>
                    {renderTab()}
                </div>

                <ModalFooter isShowInfo={true}  closeModal={closeModal} handleSubmit={handleSubmit}
                />
            </Modal>
    )
}

export default Index