import * as types from "../../actions/types";



const initialState = null


export function data (state = initialState, action) {
  switch (action.type) {
    case types.SET_DISCIPLINE_STRUCTURE_DATA:
      return action.payload
    default:
      return state;
  }
}