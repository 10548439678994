import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCandidatesRate } from '../../../../actions/settings/candidates'
import styles from './styles.module.scss'
import Table from '../../../../components/Table'

const Enrolment = ({ form }) => {

  const dispatch = useDispatch()
  const { rate } = useSelector(state => state.settings?.candidates)

  useEffect(() => {
    dispatch(getCandidatesRate(form.values.year))
  }, [form.values.year])



  const renderLi = (data) => {
    const { places, year, places_male, places_female } = data
    return (
      <tr >
        <td >{year}</td>
        <td >{places}</td>
        <td >{places_male}</td>
        <td >{places_female}</td>
      </tr>
    )
  }


  return (
    <div className={styles.wrapper} style={{ width: '700px', margin: '0 50px ' }}>

      <Table style={{ marginTop: '2rem', backgroundColor: 'white', }}>
        <thead>
          <tr>
            <th colSpan={4}>Количество мест для зачисления</th>
          </tr>
          <tr>
            <th>№ параллели</th>
            <th>кол-во мест</th>
            <th>(мальчики)</th>
            <th>(девочки)</th>
          </tr>
        </thead>
        <tbody>
          {rate?.map(renderLi)}
        </tbody>
      </Table>
    </div>
  )
}

export default Enrolment