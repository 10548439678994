import React, { useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox, Input } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import { createPeriod, updatePeriod } from '../../../actions/periods'
import moment from 'moment'
import { getSchoolYears, getShifts } from '../../../actions/common'
import cn from 'classnames'
import Table from '../../../components/Table'
import { methodOfficeTableClean, getMethodOfficeAccess, getMethodOfficeCycle, getMethodOfficeRoles, getMethodOfficeAccessFilteredPersonnel, getMethodOfficeAccessPersonnel, getMethodOfficeFiles, createFile, createFolder } from '../../../actions/methodOffice'
import Select from '../../../components/Select'
import { MethodOfficeContext } from '../../../utils/context'


const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле')
})


export default function Access () {


  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.terms)
  const { schoolYears, shifts } = useSelector(state => state.common)
  const { cycle, roles, access, access_personnel_table } = useSelector(state => state.method_office)


  const {formik, closeModal, data, addMode, folder, onSubmit, createFolderModal} = useContext(MethodOfficeContext) 
  const {values, setFieldValue, handleChange, resetForm } = formik
  

  useEffect(() => {
    !schoolYears && dispatch(getSchoolYears())
    !shifts && dispatch(getShifts())
  }, [])

  useEffect(()=> {
   dispatch( getMethodOfficeCycle())
   dispatch( getMethodOfficeRoles())
   dispatch( getMethodOfficeAccess())
   dispatch( getMethodOfficeAccessPersonnel())


  }, [])

  const getCorrectPayload = (values) => {
    let {starttime, stoptime} = values
    starttime = moment.duration(starttime).asMinutes()
    stoptime = moment.duration(stoptime).asMinutes()
    return {...values, starttime, stoptime}
  }

  const handleCreatePeriod = async (values) => {
    const payload = getCorrectPayload(values)
    await dispatch(createPeriod(payload))  
    closeModal()
    dispatch(methodOfficeTableClean())
  }
  
  const handleUpdatePeriod = async (values) => {
    let {lid, ...data} = values
    const payload = getCorrectPayload(data)
    await dispatch(updatePeriod(lid, payload))
    closeModal()
    dispatch(methodOfficeTableClean())
  }
const close =() => {
  onSubmit()
  closeModal()
  resetForm()
  dispatch(methodOfficeTableClean())
}

  useEffect(()=> {
    values?.role && 
    getTable(values)

   }, [values?.role])

  const renderLi = (data) => {
    const {username, perstype, accessgroup, access, mid } = data

    return (
      <tr key={mid} onClick={()=>  setFieldValue('mid', mid)} className={values.mid === mid && styles.selectedUser}>
        <td>{username}</td>
        <td>{perstype}</td>
        <td>{accessgroup}</td>
        <td>{access !==0 ? access === 1 ? "Чтение" : "Полный" : "Нет"}</td>
      </tr>
    )
  }

  const getTable = values => {
    dispatch(getMethodOfficeAccessFilteredPersonnel(values))
  }

  const renderOption = (option, index) => {
    const {  xp_key,  value, trmid, cid, gid, work_status, id, pmid, did ,  name, title, year, access, } = option
    const optionValue =   xp_key || value ||trmid ||cid || gid || work_status || id || pmid || did
    const optionTitle =  name || title || value || year || work_status || access
    return <option key={`${optionValue}-${index}`} value={optionValue}>{optionTitle}</option>
  }

  return (
    <>
      <div className={styles.modalMain}>  
          <div className={styles.tabWrapper}>
            <div className={styles.inputsRow}>
   
            <Select
              title="Цикл"
              name='cycle'
              options={[{name:"Выбрать"}, ...cycle]}
              value={values?.cycle}
              withoutArrowMode
              bigInput
              onChange={handleChange}
            >    
                 <option value=''>Выбрать</option>
                 {cycle?.map(renderOption)}
            </Select>
   
            <Select
              title="Роль"
              name='role'
              options={[{name:"Выбрать"}, ...roles]}
              value={values?.role}
              withoutArrowMode
              bigInput
              onChange={handleChange}
            >  
              <option value=''>Выбрать</option>
              {roles?.map(renderOption)}
            </Select>
   
         
          </div>
        </div>
 
          <div className={styles.wrapper}>
          
              <Table style={{marginTop: '2rem', backgroundColor: 'white'}}>
               <thead>
                <tr>
                    <th>Пользователь</th>
                    <th>Роль</th>
                    <th>Должность</th>
                    <th>Доступ</th>
                </tr>
                </thead>
               <tbody>
              { access_personnel_table?.map(renderLi)}

      
                </tbody>
              </Table>
      
           
         
          </div>

      </div>

     

      <div className={styles.modalFooter}>
      
        <Select
        title="Доступ"
        name='access'
        options={[{name:"Выбрать"}, ...access]}
        value={values?.access}
        withoutArrowMode
        methodOfficeModalFooter
        onChange={handleChange}
      > 
          <option value=''>Выбрать доступ</option>
          {access?.map(renderOption)}
      </Select>
  
        <Button  mode='white' loading={loading} onClick={()=> {}}>Установить</Button>
        <Button mode='white' onClick={ () => createFolderModal()} style={{marginRight: '10px'}}>Сохранить</Button>
        <Button mode='white' onClick={close}>Закрыть</Button>
      </div>
    </>
  )
}
