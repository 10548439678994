import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getEventTypes, deleteEventType, createEventType, editEventType } from '../../actions/ads'
import Li from '../../components/Li'
import Button from '../../components/Button'
import Modal from 'react-responsive-modal'
import { useFormik } from 'formik'
import { Checkbox, Input } from '../../components/ModalInputs'
import Confirmation from '../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi'
import * as Yup from 'yup'



const INITIAL_VALUES = {
  ev_type: '',
}

const validationSchema = Yup.object().shape({
  ev_type: Yup.string().required('обязательное поле').max(256, 'Должно быть не более 256 символов'),
})

const EventTypes = () => {
  
  const dispatch = useDispatch()
  const { list, loading } = useSelector(state => state.event_type)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null)


  useEffect(() => {
    dispatch(getEventTypes())
  }, [])
  
  const deleteItem = (id) => (e) => {
    e.stopPropagation()
    setConfirmation(id)
  }

  const openModal = (data) => () => setSelectedItem(data || {})

  const closeModal = () => setSelectedItem(null)

  const renderLi = (item) => (
    <Li onClick={openModal(item)}>
      <span>{item.ev_type}</span>
      <BiTrash size={24} color='red' title='Удалить' onClick={deleteItem(item.ev_type_id)}/>
    </Li>
  )
  
  const acceptConfirmation = () => {
    dispatch(deleteEventType(confirmation))
    setConfirmation(null)
  }
  
  const rejectConfirmation = () => {
    setConfirmation(null)
  }

  return (
    <div className={styles.wrapper}>
      <h1>Типы событий</h1>
      <ul>{list?.map(renderLi)}</ul>
      <Button onClick={openModal()}>Добавить</Button>
      <ModalComponent
        open={!!selectedItem}
        closeModal={closeModal}
        data={selectedItem}
        addMode={!selectedItem?.ev_type_id}
      />
      {confirmation && (
        <Confirmation
            top="30px"
            left="40px"
            title="Уверены что хотите удалить запись?"
            acceptConfirmation={acceptConfirmation}
            rejectConfirmation={rejectConfirmation}
        />
      )}
    </div>
  )
}


const ModalComponent = ({open, closeModal, data, addMode}) => {
  
  const dispatch = useDispatch()

  useEffect(() => {
    if (!open) return;
    setValues(addMode ? INITIAL_VALUES : data)
  }, [open])
  

  const createItem = async (values) => {
    const success = await dispatch(createEventType(values))
    success && closeModal()
  }
  
  const updateItem = async (values) => {
    const success = await dispatch(editEventType(values))
    success && closeModal()
  }

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue
  } = useFormik({
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    validationSchema,
    onSubmit: addMode ? createItem : updateItem
  })
  
  return (
    <Modal
      open={open}
      onClose={closeModal}
      classNames={{modal: styles.modal}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      animationDuration={2}
      center
    >
      <div className={styles.modalMain}>
        <h2>{addMode ? 'Добавление записи' : 'Редактирование записи'}</h2>
        <Input
          name='ev_type'
          label='Название'
          value={values.ev_type}
          error={touched.ev_type && errors.ev_type}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Checkbox
          name='reporthse'
          label='отчет ВШЭ'
          checked={values.reporthse}
          onChange={() => setFieldValue('reporthse', Number(!values.reporthse))}
        />
      </div>
      <div className={styles.modalFooter}>
          <div>
              <div></div>
              <div></div>
          </div>
          <Button mode='white' type="submit" onClick={handleSubmit}>Сохранить</Button>
          <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </Modal>
  )
}


export default EventTypes