import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox, Input } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { getParallelsForGroupNutrition, createGroupNutrition, updateGroupNutrition } from '../../../actions/directoryCanteen'

const allParallels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

const validationSchema = Yup.object({
  sort: Yup.number().typeError('Пожалуйста, введите число').required('обязательное поле').max(999999999, 'Должно быть не более 9 символов'),
  name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),

})


export default function Main({ closeModal, data, addMode }) {

  const dispatch = useDispatch()
  const { parallelList } = useSelector(state => state.directoryCanteen)
  const [parallels, setParallels] = useState(data?.parallels)

  const handleCreate = async () => {

    dispatch(createGroupNutrition(values))
    closeModal()
  }

  const handleUpdate = async (values) => {
    // setFieldValue('parallels', parallels)
    dispatch(updateGroupNutrition(values))
    closeModal()
  }

  useEffect(() => {
    dispatch(getParallelsForGroupNutrition())
  }, [])

  useEffect(() => {
    setFieldValue('parallels', parallels)
  }, [parallels])

  const handleChangeCheck = (e) => {
    if (parallels?.find(el => el === +e.target.value)) {
      setParallels(parallels.filter((el, i) => parallels.indexOf(+e.target.value) !== i))
    }
    else { setParallels((prev) => prev ? [...prev, +e.target.value] : +e.target.value) }
    // setParallels((prev) => prev.slice(parallels.indexOf( e.target.value),1))
  }


  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    setFieldValue,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? {
      name: "",
      parallels: [],
      sort: '',
      xp_key: ''
    } : data,
    enableReinitialize: true,
    // validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const disabledList = values?.parallels?.map(el => (parallelList?.find(par => +el == +par) ? '' : el))
  const disabledList2 = parallelList?.map(el => (values?.parallels?.find(par => +el == +par) ? null : el))

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  return (
    <>
      <div className={styles.modalMain} >
        <div className={styles.tabWrapper} style={{ display: "flex", justifyContent: 'space-evenly', flexDirection: 'column' }}>

          <div className={styles.inputsRow}>
            <Input
              label='Название'
              name='name'
              value={values?.name}
              error={error('name')}
              onChange={handleChange}
              style={{ width: '300px' }}
            />
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Сортировка'
              name='sort'
              value={values?.sort}
              error={error('sort')}
              onChange={handleChange}
              style={{ width: '300px' }}
            />
          </div>
          <div className={styles.inputsColumn}>
            <p>Параллели</p>

            {allParallels?.map(par => {
              const che = parallels?.find(el => +par == +el)
              return (
                <Checkbox
                  label={par}
                  checked={!!che}
                  value={par}
                  onChange={(e) => handleChangeCheck(e)}
                  name='parallels'
                  disabled={disabledList2?.find(el => el == par)}
                />
              )
            }
            )}
          </div>

        </div>
      </div>

      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}
