import * as types from "../actions/types";

const initialState = {
  loading: false,
  midList: []
};

// МЕДПУНКТ СПРАВОЧНИКИ

export default function directoryMedCenter(state = initialState, action) {
  switch (action.type) {
    case types.GET_TYPES_DISEASE:
      return {
        ...state,
        diseaseList: action.payload
      };
    case types.GET_HEALTH_GROUP:
      return {
        ...state,
        healthgroup: action.payload
      };
    case types.GET_DISCHARGE:
      return {
        ...state,
        discharge: action.payload
      };
    case types.GET_VACCINATIONS:
      return {
        ...state,
        vaccinations: action.payload
      };
    case types.GET_VACCINATIOS_CURR:
      return {
        ...state,
        vaccinationsCurr: action.payload
      };
    case types.GET_MKB_TEN_TOP:
      return {
        ...state,
        topList: action.payload
      };
    case types.GET_MKB_TEN_MID:
      return {
        ...state,
        midList: action.payload
      };
    case types.GET_MKB_TEN_BOT:
      return {
        ...state,
        botList: action.payload
      };


    case types.GET_TYPES_OF_VACCINATIONS:
      return {
        ...state,
        typesVaccinations: action.payload
      };
    case types.GET_SPEC_MED_GROUPS:
      return {
        ...state,
        specMedGroupsList: action.payload
      };
    case types.GET_SPEC_MED_GROUPS_TEACHERS:
      return {
        ...state,
        specMedGroupsTeacherList: action.payload
      };
    case types.GET_SPEC_MED_GROUPS_STUDENTS:
      return {
        ...state,
        specMedGroupsStudentList: action.payload
      };
    case types.GET_SPEC_MED_GROUPS_GROUPS:
      return {
        ...state,
        specMedGroupsGroupsList: action.payload
      };
    case types.GET_ATTACHED_STUDENTS:
      return {
        ...state,
        attachedStudent: action.payload
      };

    case types.GET_DIAGNOSES:
      return {
        ...state,
        diagnosesList: action.payload
      };
    // case types.GET_MES_VISITES:
    //   return {
    //     ...state,
    //     medVisitesList: action.payload
    //   };
    case types.GET_LIST:
      return {
        ...state,
        list: action.payload
      };
    case types.GET_APPEALS:
      return {
        ...state,
        appeals: action.payload
      };
    case types.GET_VACCINE_EXCLUSIONS:
      return {
        ...state,
        vacExclusions: action.payload
      };
    case types.GET_VACCINATION_MODAL:
      return {
        ...state,
        vaccinModal: action.payload
      };



    case types.SET_MEDBLOCK_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}