import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Input from '../../../components/Input'
import Button from '../../../components/Button';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {userMessageTypes} from '../../../assets/const';
import {Select} from '../../../components/ModalInputs';
import {getFAQ, getFioList} from '../../../actions/FAQ';

const Index = ({params, setParams, dateFormat, setType}) => {

    const {fioList, pageNumber} = useSelector(state => state.FAQ);

    const dispatch = useDispatch();

    const handleGetData = () => { // Запрос на получение данных
        dispatch(getFAQ({...params, pageNumber}));
    }

    useEffect(() => {
      dispatch(getFioList());
    }, [])
    

    const handleChange = (name, value) => { // Обработчик изменения параметров поиска
        setParams({
            ...params,
            [name]: value
        });
    }

    const handleAdd = () => { // Вызов модального окна для создания объявления
        setType('ADD');
    }

    return (
        <div className={styles.container}>
            <div className={styles.inputsRow}>
                <div className={styles.container_select}>
                    <Select
                        name='type_id'
                        label='Тип'
                        onChange={(e) => handleChange(e.target.name, Number(e.target.value))}>
                        {Object
                            .keys(userMessageTypes)
                            .map(el => <option value={el}>{userMessageTypes[el]}</option>)}</Select>
                    <Select
                        name='fio_id'
                        label='ФИО'
                        onChange={(e) => handleChange(e.target.name, Number(e.target.value))}>
                        <option value={0}>Все</option>
                        {fioList?.map(el => <option value={el.mid}>{el.username}</option>)}</Select>
                </div>
            <div className={styles.container_date}>
                <span className={styles.label}>Период:</span>
                <Input
                    type='date'
                    title='От'
                    value={params.from_date}
                    onChange={(e) => handleChange('from_date', moment(e.target.value).format(dateFormat))}/>
                <Input
                    type='date'
                    title='До'
                    value={params.to_date}
                    onChange={(e) => handleChange('to_date', moment(e.target.value).format(dateFormat))}/>
                <Button className={styles.button} onClick={() => handleGetData()}>Обновить</Button>
            </div>
                {/* </div> */}

                
            </div>

            <div className={styles.container_params}>
                    <Input
                        name='hidden'
                        fz={14}
                        className={styles.checkbox}
                        onChange={(e) => handleChange(e.target.name, Number(!params[e.target.name]))}
                        type='checkbox'
                        title='Показать скрытые'/>
                    <Input
                        name='only_new'
                        fz={14}
                        className={styles.checkbox}
                        onChange={(e) => handleChange(e.target.name, Number(!params[e.target.name]))}
                        type='checkbox'
                        title='Только новые'/>
                    <Input
                        name='graduates'
                        fz={14}
                        className={styles.checkbox}
                        onChange={(e) => handleChange(e.target.name, Number(!params[e.target.name]))}
                        type='checkbox'
                        title='Показать выпускников'/>
                    <Input
                        name='dismissed'
                        fz={14}
                        className={styles.checkbox}
                        onChange={(e) => handleChange(e.target.name, Number(!params[e.target.name]))}
                        type='checkbox'
                        title='Показать Уволенных сотрудников'/>
                </div>
            {/* <div className={styles.container_date}>
                <span className={styles.label}>Период:</span>
                <Input
                    type='date'
                    title='От'
                    value={params.from_date}
                    onChange={(e) => handleChange('from_date', moment(e.target.value).format(dateFormat))}/>
                <Input
                    type='date'
                    title='До'
                    value={params.to_date}
                    onChange={(e) => handleChange('to_date', moment(e.target.value).format(dateFormat))}/>
                <Button className={styles.button} onClick={() => handleGetData()}>Обновить</Button>
            </div> */}

            <Button onClick={() => handleAdd()}>Новый чат</Button>
        </div>
    )
}

export default Index