import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox, Input, MultiSelect, Select } from '../../../components/ModalInputs'
import { createSchoolYear, updateSchoolYear } from '../../../actions/schoolYears'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { getTermGradeTypes, getTermTypes } from '../../../actions/common'
import * as _ from 'lodash';
import { createEducationalStatuses, updateEducationalStatuses } from '../../../actions/directory'


const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле'),
  begdate: Yup.string().required('обязательное поле'),
  enddate: Yup.string().required('обязательное поле'),
})


export default function Main ({closeModal, data, addMode}) {
  
  const dispatch = useDispatch()  

  const {educationalStatus} = useSelector(state => state.directory)
  const typeOfStatusForSelect =( _.uniqBy(educationalStatus, 'type').map(el => ({type :el.type})));
  const handleCreateStatus = (values) => {
    dispatch(createEducationalStatuses(values))
    closeModal()
  }

  const handleUpdateStatus = async (values) => {
    dispatch(updateEducationalStatuses(values))
    closeModal()
  }

  const {
    values, 
    errors,
    touched,
    submitCount,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit
  } = useFormik({
    // initialValues: addMode ? {xp_key: '', name: '', type: '', hidden: '', timestamp: ''} : data,
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    // validateOnChange: true,
    // validationSchema: validationSchema,
    onSubmit: addMode ? handleCreateStatus : handleUpdateStatus
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const renderOptions = ({type}) => <option value={ type}>{ type}</option>

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
          
            <Input 
              label='Значение'
              name='name'
              value={values.name}
              error={error('name')}
              onChange={handleChange}
              style={{width: '300px'}}
              />
          </div>
          <div className={styles.inputsColumn}>
            <Select
              label='Тип'
              name='type'
              value={values.type}
              options={typeOfStatusForSelect}
              onChange={handleChange}
            >
              {typeOfStatusForSelect?.map(renderOptions)}
            </Select>   
          </div>
        </div>
      </div>
      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}
