import React from 'react'
import { useContext } from 'react'
import { CandidatesContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCandidateParents } from '../../../actions/candidates'
import styles from './styles.module.scss'
import Button from '../../../components/Button'
import { Input, Select } from '../../../components/ModalInputs'


export default function Parents () {
  
  const {candidateId, closeModal} = useContext(CandidatesContext)
  const dispatch = useDispatch()
  const { parents } = useSelector(state => state.candidates.data)

  useEffect(() => {
    dispatch(getCandidateParents(candidateId))
  }, [])
  
  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
            <Select
              label='Законные представители для связи'
            />
            <Select
              label='Телефон для уведомлений'
            />
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Телефон домашний'
            />
            <Input
              label='E-mail'
            />
          </div>

          <>
            <h5>Мать</h5>
            <div className={styles.inputsRow}>
              <Input
                label='Фамилия'
              />
              <Input
                label='Имя'
              />
              <Input
                label='Отчество'
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Телефон мобильный'
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Место службы/работы'
              />
              <Input
                label='Телефон служебный'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Страна'
              />
              <Select
                label='Субъект РФ'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Военный округ'
              />
              <Select
                label='Вид, род войск'
              />
              <Input
                label='Номер в/ч'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Звание'
              />
              <Input
                label='Должность'
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Выслуга лет, мес.'
              />
              <Input
                label='Льготная выслуга лет, мес.'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Тип документа'
              />
              <Input
                label='Серия'
              />
              <Input
                label='Номер'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Когда выдан'
              />
              <Input
                label='Кем выдан'
              />
            </div>
          </>

          <>
            <h5>Отец</h5>
            <div className={styles.inputsRow}>
              <Input
                label='Фамилия'
              />
              <Input
                label='Имя'
              />
              <Input
                label='Отчество'
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Телефон мобильный'
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Место службы/работы'
              />
              <Input
                label='Телефон служебный'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Страна'
              />
              <Select
                label='Субъект РФ'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Военный округ'
              />
              <Select
                label='Вид, род войск'
              />
              <Input
                label='Номер в/ч'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Звание'
              />
              <Input
                label='Должность'
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Выслуга лет, мес.'
              />
              <Input
                label='Льготная выслуга лет, мес.'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Тип документа'
              />
              <Input
                label='Серия'
              />
              <Input
                label='Номер'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Когда выдан'
              />
              <Input
                label='Кем выдан'
              />
            </div>
          </>

          <>
            <h5>Опекун</h5>
            <div className={styles.inputsRow}>
              <Input
                label='Фамилия'
              />
              <Input
                label='Имя'
              />
              <Input
                label='Отчество'
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Телефон мобильный'
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Место службы/работы'
              />
              <Input
                label='Телефон служебный'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Страна'
              />
              <Select
                label='Субъект РФ'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Военный округ'
              />
              <Select
                label='Вид, род войск'
              />
              <Input
                label='Номер в/ч'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Звание'
              />
              <Input
                label='Должность'
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Выслуга лет, мес.'
              />
              <Input
                label='Льготная выслуга лет, мес.'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Тип документа'
              />
              <Input
                label='Серия'
              />
              <Input
                label='Номер'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Когда выдан'
              />
              <Input
                label='Кем выдан'
              />
            </div>
          </>

          <>
            <h5>Опекун 2</h5>
            <div className={styles.inputsRow}>
              <Input
                label='Фамилия'
              />
              <Input
                label='Имя'
              />
              <Input
                label='Отчество'
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Телефон мобильный'
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Место службы/работы'
              />
              <Input
                label='Телефон служебный'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Страна'
              />
              <Select
                label='Субъект РФ'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Военный округ'
              />
              <Select
                label='Вид, род войск'
              />
              <Input
                label='Номер в/ч'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Звание'
              />
              <Input
                label='Должность'
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Выслуга лет, мес.'
              />
              <Input
                label='Льготная выслуга лет, мес.'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Тип документа'
              />
              <Input
                label='Серия'
              />
              <Input
                label='Номер'
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Когда выдан'
              />
              <Input
                label='Кем выдан'
              />
            </div>
          </>
        </div>
      </div>
      <div className={styles.modalFooter}>
          <div>
              <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
              <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          </div>
          <Button mode='white' onClick={closeModal}>Сохранить</Button>
          <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </>
  )
}
