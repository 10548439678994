import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getActTypes, updateActType, createActType, deleteActType } from '../../actions/actTypes'
import Li from '../../components/Li'
import Button from '../../components/Button'
import Modal from 'react-responsive-modal'
import { useFormik } from 'formik'
import { Checkbox, Input } from '../../components/ModalInputs'
import Confirmation from '../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi'
import Table from '../../components/Table'
import * as Yup from 'yup'


const INITIAL_VALUES = {
  name: '',
  reporthse: 0
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('обязательное поле').max(256, 'Должно быть не более 256 символов'),
})

const ActivityTypes = () => {
  
  const dispatch = useDispatch()
  const { list, loading } = useSelector(state => state.act_types)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null)


  useEffect(() => {
    dispatch(getActTypes())
  }, [])
  
  const deleteItem = (id) => (e) => {
    e.stopPropagation()
    setConfirmation(id)
  }

  const openModal = (data) => () => setSelectedItem(data || {})

  const closeModal = () => setSelectedItem(null)

  const renderLi = (item) => (
    <tr onClick={openModal(item)}>
      <td>{item.name}</td>
      <td>
        <Checkbox checked={item.closed} disabled />
      </td>
      <td>
        <BiTrash size={24} color='red' title='Удалить' onClick={deleteItem(item.acid)}/>
      </td>
    </tr>
  )
  
  const acceptConfirmation = () => {
    dispatch(deleteActType(confirmation))
    setConfirmation(null)
  }
  
  const rejectConfirmation = () => {
    setConfirmation(null)
  }

  return (
    <div className={styles.wrapper}>
      <h1>Формы мероприятий</h1>
      <Table>
        <thead>
          <tr>
            <th>Наименование</th>
            <th>Закрыто</th>
            <th>Удалить</th>
          </tr>
        </thead>
        <tbody>
          {list?.map(renderLi)}
        </tbody>
      </Table>
      <Button onClick={openModal()}>Добавить</Button>
      <ModalComponent
        open={!!selectedItem}
        closeModal={closeModal}
        data={selectedItem}
        addMode={!selectedItem?.acid}
      />
      {confirmation && (
        <Confirmation
            top="30px"
            left="40px"
            title="Уверены что хотите удалить запись?"
            acceptConfirmation={acceptConfirmation}
            rejectConfirmation={rejectConfirmation}
        />
      )}
    </div>
  )
}


const ModalComponent = ({open, closeModal, data, addMode}) => {
  
  const dispatch = useDispatch()

  const [confirmation, setConfirmation] = useState(false)

  useEffect(() => {
    if (!open) return;
    setValues(addMode ? INITIAL_VALUES : data)
  }, [open])
  

  const createItem = async (values) => {
    const success = await dispatch(createActType(values))
    success && closeModal()
  }
  
  const updateItem = async ({acid, ...values}) => {
    const success = await dispatch(updateActType(acid, values))
    success && closeModal()
  }

  const {
    values,
    errors,
    touched,
    dirty,
    handleBlur,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue
  } = useFormik({
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    validationSchema,
    onSubmit: addMode ? createItem : updateItem
  })
  
  const handleCloseModal = () => {
    if (dirty) {
      setConfirmation(true)
    } else {
      closeModal()
    }
  }

  const acceptConfirmation = () => {
    closeModal()
    setConfirmation(false)
  }


  return (
    <Modal
      open={open}
      onClose={closeModal}
      classNames={{modal: styles.modal}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      animationDuration={2}
      center
    >
      <div className={styles.modalMain}>
        <h2>{addMode ? 'Добавление записи' : 'Редактирование записи'}</h2>
        <Input
          name='name'
          label='Название'
          value={values.name}
          error={touched.name && errors.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Checkbox
          name='closed'
          label='Закрыта'
          checked={values.closed}
          onChange={() => setFieldValue('closed', Number(!values.closed))}
        />
      </div>
      <div className={styles.modalFooter}>
          <div>
              <div></div>
              <div></div>
          </div>
          <Button mode='white' type="submit" onClick={handleSubmit}>Сохранить</Button>
          <Button mode='white' onClick={handleCloseModal}>Закрыть</Button>
      </div>
      {confirmation &&
        <Confirmation
          title="Есть несохраненные данные. Уверены что хотите выйти?"
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={() => setConfirmation(false)}
        />
      }
    </Modal>
  )
}


export default ActivityTypes