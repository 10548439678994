import React, { useState } from 'react'
import styles from './styles.module.css'
import noname from '../../../assets/images/noname.png'
import {IoMdCreate, IoMdDocument, IoMdTrash, IoMdUndo} from 'react-icons/io'
import { deleteMessage } from '../../../actions/FAQ'
import { useDispatch, useSelector } from 'react-redux'
import Confirmation from '../../../components/Confirmation/Confirmation'

const Index = ({item, setModalType, nesting, isDeLete}) => {

    const dispatch = useDispatch()
    const {files_list} = useSelector(state => state.FAQ)

    const [confirmation, setConfirmation] = useState(false)

    const acceptConfirmation = () => {
        dispatch(deleteMessage(confirmation.id, confirmation.chat));
        setConfirmation(false);
      };

      const rejectConfirmation = () => {
        setConfirmation(false);
      };

    return (
        <div className={styles.wrapper} style={{marginLeft: `5px`}}>
            {confirmation && <Confirmation 
                    top="30px"
                    left="40px"
                    title="Удалить сообщение?"
                    confirmation={confirmation.name} 
                    acceptConfirmation={() => acceptConfirmation(confirmation.postid)}
                    rejectConfirmation={rejectConfirmation}/>}

            <img className={styles.img} src={item.photo ?
            `data:image/png;base64, ${item.photo}` : 
            noname}/>
            <div className={styles.main}>
                <div className={styles.header}>
                    <span className={styles.name}>{item.username}</span>
                    <span className={styles.date}>{item.date}</span>
                </div>
                <span className={styles.message}>{item.message}</span>
            </div>
            
            {files_list?.[item?.id] ? <div className={styles.filesWrapper}>
                <i className={styles.icon_files} title='Файлы'><IoMdDocument/></i>
                <div className={styles.files}>
                    {
                        files_list?.[item?.id]?.map(file => 
                        <span 
                            className={styles.file} 
                            key={file.file_name}>
                                <a 
                                    href={`https://lms2.edumil.ru${file.file_path}`} 
                                    target='_blank' 
                                    rel="noreferrer">{file.file_name}
                                </a>
                        </span>)
                    }
                </div>
            </div> : <></>}
            <div className={styles.buttons}>
                <i onClick={() => setModalType(item)} className={styles.icon_undo} title='Ответить'><IoMdUndo className={styles.icon}/></i>
                {isDeLete && <i onClick={() => setModalType({...item, edit: true})} className={styles.icon_edit} title='Редактировать'><IoMdCreate className={styles.icon}/></i>}
                {isDeLete && <i onClick={() => setConfirmation(item)} className={styles.icon_trash} title='Удалить'><IoMdTrash className={styles.icon}/></i>}
            </div>
        </div>
    )
}

export default Index