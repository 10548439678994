import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { createEventType, editEventType, getEventTypeById } from '../../../../../actions/ads'
import { Input } from '../../../../../components/ModalInputs'
import Modal from 'react-responsive-modal'
import ModalFooter from '../../../../../components/ModalFooter/ModalFooter'


const Index = ({ id, closeModal }) => {
  
    const dispatch = useDispatch()
    const {loading, eventTypeById} = useSelector(state => state.event_type)

    const [item, setItem] = useState({})

    useEffect(() => {
        id && dispatch(getEventTypeById(id))
    }, [id])

    useEffect(() => {
        if (eventTypeById) {
            setItem(eventTypeById)
        } else {
            setItem({})
        }
    }, [eventTypeById])

    const handleSubmit = () => {
        if (id) {
            dispatch(editEventType(item))
        } else {
            dispatch(createEventType(item))
        }
        closeModal(null)
        dispatch({ type: 'SET_EVENT_TYPE_BY_ID', payload: null })
    }
        
    const handleClose = () => {
        closeModal(null)
        dispatch({ type: 'SET_EVENT_TYPE_BY_ID', payload: null })
    }

    return (
    <Modal
        open={true}
        onClose={() => false}
        showCloseIcon={false}
        center
        classNames={{
        overlay: styles.overlay,
        modal: styles.eventTypesModal
        }}
    >
        
        <div className={styles.wrapper}>
            <h1>{item.ev_type_id ? 'Редактирование' : 'Добавление'} события</h1>
            <Input style={{width: '100%'}} type='text' label='Название события' name='ev_type' value={item.ev_type} onChange={(e) => setItem({ ...item, ev_type: e.target.value })} />
            <br/>
            <br/>
            <Input type="checkbox" label="Отчет ВШЕ" name="reporthse" checked={item.reporthse} onChange={() => setItem({ ...item, reporthse: Number(!item.reporthse) })} />
        </div>
            <ModalFooter
                isShowInfo={false}
                handleSubmit={handleSubmit}
                closeModal={handleClose}/>
    </Modal>
  )
}

export default Index