import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import Table from '../../components/Table'
import Modal from './Modal'
import {  createFile, createFolder, deleteLowFolder, getMethodOfficeFiles, getMethodOfficeFolders } from '../../actions/methodOffice'
import { useDispatch, useSelector } from 'react-redux'
import { BACKEND_DOMAIN, BACKEND_URL_PREFIX } from '../../assets/const'

export default function MethodOffice() {

    const dispatch = useDispatch()
    const [selectedItem, setSelectedItem] = useState(null)
    const [file, setFile] = useState(null)
    const [selected, setSelected] = useState('')
    const [subSelected, setSubSelected] = useState('')

    const {folder, files, subfolder } = useSelector(state => state.method_office)
    
    const openModal = (data) => () => {
       setSelectedItem(data || {})
      }
    const closeModal = () => setSelectedItem(null)

    const handleInputChange = (e) => {
        setSelected(e)
        setFile(e)
        // setSelectedItem(e)
    }

    const handleInputChangeSub = (e) => {
        setSubSelected(e)
        setFile(e)
        // setSelectedItem(e)
        }
    useEffect(()=> { 
        dispatch(getMethodOfficeFolders(file?.mrid || 0))
    },[file])

    useEffect(()=> { 
        file?.mrid &&
        dispatch(getMethodOfficeFiles(file?.mrid))
    },[file])

    const fileLoad = async (e) => {
        const {files} = e.target
        await dispatch(createFile(files[0], file?.mrid ))
        }
        
    const createFileAt = () => {
        const fileInput = document.getElementById('fileInput')
        fileInput.click()
        }
       
    const createFolderAt = async () => {
        openModal()
      
       }

    const deleteLowFolderAt = async () => {

        await dispatch(deleteLowFolder(file?.mrid))
    }

    const download = (filename, text) => {
        return (
           <a href={'somelink' + text} download={filename} />
        )
    }

  return (
    <>
    <div className={styles.aboveMet}>
        <h1>Методический кабинет</h1>
    </div>
    <Modal
    closeModal={closeModal}
    data={selectedItem}
    file={file}
     />
    <div className={styles.met}>
        <div className={styles.underMet}>
         <div>   
                {folder  &&
                    folder.map((el)=> 
                    <>
                   { el.mr_parent === 0 &&
                    <div className={styles.folderMethod}style={{display: 'flex'}}>
                    <p className={styles.folderIconMethod}></p>
                    <p  onClick={()=> handleInputChange(el)} className={selected.mrid === el.mrid && styles.selectedUser} >{el.mr_name} </p>
                    </div>
                   }
                   {selected && (folder.filter((hi)=> hi.mr_parent === el.mrid)).map((subEl)=> 

                    
                 
                        (selected.mrid === subEl.mr_parent && subEl.mr_isfile!==1) && 
                        <div className={styles.folderMethodLow} style={{display: 'flex'}}>

                            <br/>
                            <p className={styles.folderIconMethodWith}></p>
                            <p onClick={()=> handleInputChangeSub(subEl)} className={subSelected.mrid === subEl.mrid && styles.selectedUser}>{subEl.mr_name}</p>

                        </div>
                    
                   
                    )
                    }
                    <div >
                    {files && selected.mrid === el.mrid &&
                        files.map((el)=> <li key={el.mrid} onClick={()=> 0}>{el.mr_name} </li>)}
                   </div>
                    </>
                   )
                }
         </div>

         <div style={{display: "flex",alignItems: "flex-end"}}>

                <Button onClick={openModal()} >Добавить папку</Button>
                
                <Button onClick= {deleteLowFolderAt} className={styles.button} disabled={!file}>Удалить</Button>
                
                <Button onClick={openModal(file)} disabled={!file} >Свойства</Button>
         
         </div>
         
         </div>    
         
         <div className={styles.underMet}>
     
                <div >
                    {files && files.map((el)=> {
                        const fileLink = `${BACKEND_DOMAIN}/files/methodical_room/${el.mrid}/${el.mr_name}`
                        return (
                                <li key={el.mrid}>
                                <a href={fileLink} download target='_blank'>
                                {el.mr_name} 
                                </a>
                                <img src={fileLink} width={'50'} height={'50'}/>
                                </li>
                         )
                    })}
                </div>
                <div style={{display: 'flex'}}>
            <div>
            <Button onClick={createFileAt} className={styles.button} disabled={!file}>Добавить файл</Button>
                <input
                type='file'
                id='fileInput'
                onChange={fileLoad}
                hidden
                />

            <Button onClick={openModal()} className={styles.button} disabled={!file}>Создать папку</Button>

            </div>

                <div style={{display: "flex", justifyContent: "center", flexDirection: 'column'}}>

                    <Button onClick= {deleteLowFolderAt} className={styles.button} disabled={!file}>Удалить</Button>

                    <Button onClick={openModal(file)} className={styles.button} disabled={!file}>Свойства</Button>

                </div>

            

                  <div>

                    <Button className={styles.button} disabled={!file}>Просмотр файла</Button>

                    <Button className={styles.button} disabled={!file}>Сохранить копию</Button>

                 </div>
                 </div>

                </div>

               </div>




         { false &&   <Table>
                <thead>
                        <tr>
                            <th>Файлы</th>
                            <th>Содержимое файла</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                    <th>Файл #1</th>
                    <th>Содержимое файла</th>
                   
                </tr>
                    </tbody>
            </Table>}
    </>
    )
}
