import React from 'react'
import { Checkbox } from '../../../components/ModalInputs'


export default function CheckboxComponent ({data, formik}) {
  
  const onChange = () => {
    formik.setFieldValue(data.name, Number(!formik.values[data.name]))
  }
  
  return (
    <Checkbox
      label={data.description}
      name={data.name}
      checked={!!formik.values[data.name]}
      onChange={onChange}
    />
  )
}
