import React from 'react'
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Input, } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { updateFormOfEducation, createFormOfEducation, } from '../../../actions/directory'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').max(50, 'Должно быть не более 50 символов'),

})


export default function Main({ closeModal, data, addMode }) {

  const dispatch = useDispatch()
  const { formOfEducation } = useSelector(state => state.directory)

  const handleCreate = async (values) => {

    dispatch(createFormOfEducation(values))
    closeModal()
  }

  const handleUpdate = async (values) => {

    dispatch(updateFormOfEducation(values))
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    setFieldValue,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? { value: '', text: '', isgrade: '', isdisease: '' } : data,
    enableReinitialize: true,
    // validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  return (
    <>
      <div className={styles.modalMain} >
        <div className={styles.tabWrapper} style={{ display: "flex", justifyContent: 'space-evenly' }}>

          <div className={styles.inputsRow}>
            <Input
              label='Название'
              name='name'
              value={values.name}
              error={error('name')}
              onChange={handleChange}
              style={{ width: '300px' }}
            />
          </div>

        </div>
      </div>

      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}
