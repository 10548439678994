import React from 'react'
import { useContext } from 'react'
import { CandidatesContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCandidateInfo } from '../../../actions/candidates'
import styles from './styles.module.scss'
import Button from '../../../components/Button'
import { Textarea } from '../../../components/ModalInputs'
import { useFormik } from 'formik'


export default function CandidateInfo () {
  
  const {candidateId, closeModal} = useContext(CandidatesContext)
  const dispatch = useDispatch()
  const { info: data } = useSelector(state => state.candidates.data)

  useEffect(() => {
    dispatch(getCandidateInfo(candidateId))
  }, [])
  
  const {
    values,
    errors,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: data || {},
    enableReinitialize: true,
    onSubmit: console.log
  })


  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
            <Textarea
              style={{resize: 'none', width: '750px', height: '180px'}}
              label='Выписка из педагогической характеристики'
              name='pedagogicalnote'
              value={values.pedagogicalnote}
              />
          </div>
          <div className={styles.inputsRow}>
            <Textarea
              style={{resize: 'none', width: '750px', height: '180px'}}
              label='Выписка из психологической характеристики'
              name='psychologicalnote'
              value={values.psychologicalnote}
              />
          </div>
          <div className={styles.inputsRow}>
            <Textarea
              style={{resize: 'none', width: '750px', height: '180px'}}
              label='Примечание'
              name='mainnote'
              value={values.mainnote}
            />
          </div>
        </div>
      </div>
      <div className={styles.modalFooter}>
          <div>
              <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
              <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          </div>
          <Button mode='white' onClick={handleSubmit}>Сохранить</Button>
          <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </>
  )
}
