import React, { useContext, useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { ClassingContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import { getGroupCurators } from '../../../actions/groups'
import Loader from '../../../components/Loader'
import Table from '../../../components/Table'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'


export default function Curator () {
  
  const dispatch = useDispatch()
  const { classId, closeModal,infoCreateChange } = useContext(ClassingContext)
  const { groupCurators, modalLoading: loading } = useSelector(state => state.groups)

  useEffect(() => {
    dispatch(getGroupCurators(classId))
  }, [])
  
  const renderRow = ({calc_fio, calc_status, calc_date}) => (
    <tr>
      <td>{calc_fio}</td>
      {/* <td>{calc_status}</td>  LMSTWO-63
      <td>{calc_date}</td> */}
    </tr>
  )

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          {loading
            ? <Loader/>
            : !!groupCurators?.length &&
                <Table>
                  <thead>
                    <tr>
                      <th>ФИО</th>
                      {/* <th>Статус</th>  LMSTWO-63
                      <th>Дата</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {groupCurators.map(renderRow)}
                  </tbody>
                </Table>
          }
        </div>
      </div>
      <ModalFooter closeModal={closeModal} handleSubmit={closeModal} infoCreateChange={infoCreateChange} />
    </>
  )
}
