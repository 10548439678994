import { BACKEND_URL_PREFIX, itemsOnPage } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import {
  SET_COURSES_COUNT,
  SET_COURSES_LIST,
  SET_COURSES_LOADING,
  SET_COURSES_PAGE,
  SET_CURRENT_COURSE,
  SET_COURSES_ALIAS_LIST,
  SET_COURSES_ALIAS_LIST_LOADING,
  SET_EDU_DIR_LIST_LOADING,
  SET_EDU_DIR_LIST,
  SET_CULTURE_LIST_LOADING,
  SET_CULTURE_LIST,
  SET_COMPETENCE_LIST,
  SET_COMPETENCE_LIST_LOADING,
} from "./types";

export const setPage = (pageNumber) => ({type: SET_COURSES_PAGE, payload: pageNumber })

export const getCoursesList = (pageNumber) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COURSES_LOADING, payload: true });
    const limit = 10;
    const offset = 10 * pageNumber;
    const urlParams = new URLSearchParams({limit, offset}).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/courses?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const {cnt: count, courses: courses} = await response.json();
      const pageCount = Math.ceil(count / 10);

      dispatch({ type: SET_COURSES_LIST, payload: {courses: courses} });
      dispatch({ type: SET_COURSES_COUNT, payload: {pageCount, count} });
    }
  }
  dispatch({ type: SET_COURSES_LOADING, payload: false });
};

export const getCurrentCourse = (courseId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COURSES_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/courses/${courseId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CURRENT_COURSE, payload });
    }
  }
  dispatch({ type: SET_COURSES_LOADING, payload: false });
};

export const deleteCourse = (courseId, pageNumber) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COURSES_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/courses/${courseId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const {list} = getState().courses
      list.length < 2 && dispatch(setPage(pageNumber - 1)) 
      await dispatch(getCoursesList(pageNumber));
    }
  }
  dispatch({ type: SET_COURSES_LOADING, payload: false });
};

export const updateCourse = (courseId, data, pageNumber = 0) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COURSES_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/courses/${courseId}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getCoursesList(pageNumber));
    }
  }
  dispatch({ type: SET_COURSES_LOADING, payload: false });
};

export const createCourse = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COURSES_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/courses`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getCoursesList());
    } else {
      createNotification('error', 'Ошибка')
    }
  }
  dispatch({ type: SET_COURSES_LOADING, payload: false });
};

export const getCoursesAlias = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COURSES_ALIAS_LIST_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/courses/alias`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COURSES_ALIAS_LIST, payload });
    }
  }
  dispatch({ type: SET_COURSES_ALIAS_LIST_LOADING, payload: false });
};

export const getEduDirList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_EDU_DIR_LIST_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/edu_direction`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_EDU_DIR_LIST, payload: payload.edu_direction});
    }
  }
  dispatch({ type: SET_EDU_DIR_LIST_LOADING, payload: false });
};

export const getCultureList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_CULTURE_LIST_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/culture`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CULTURE_LIST, payload: payload.culture });
    }
  }
  dispatch({ type: SET_CULTURE_LIST_LOADING, payload: false });
};

export const getCompetence = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_COMPETENCE_LIST_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/competence`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COMPETENCE_LIST, payload: payload });
    }
  }
  dispatch({ type: SET_COMPETENCE_LIST_LOADING, payload: false });
};