import { api } from "../../App";
import { SET_EXAM_INFO, SET_EXAM_LOADING } from "../types";




export const getExamInfo = (parallel) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EXAM_LOADING, payload: true });
    const { data } = await api.get('/exam_info', { params: { parallel }});
    dispatch({ type: SET_EXAM_INFO, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EXAM_LOADING, payload: false });
  }
}

export const createExamInfo = (data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_EXAM_LOADING, payload: true });
    await api.post('/exam_info', data);
    await dispatch(getExamInfo())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EXAM_LOADING, payload: false });
    return success
  }
}

export const updateExamInfo = (id, data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_EXAM_LOADING, payload: true });
    await api.put(`/exam_info/${id}`, data);
    await dispatch(getExamInfo())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EXAM_LOADING, payload: false });
    return success
  }
}

export const deleteExamInfo = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EXAM_LOADING, payload: true });
    await api.delete(`/exam_info/${id}`);
    await dispatch(getExamInfo())
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EXAM_LOADING, payload: false });
  }
}