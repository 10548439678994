import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import Modal from 'react-responsive-modal';
import { useDispatch, useSelector } from 'react-redux';
import MarkPanel from './MarkPanel/MarkPanel';

export  const TableCell = ({ 
  mark, 
  student, 
  panelOpen, 
  setPanelOpen, 
  formData, 
  commentOpen, 
  setCommentOpen, 
  setClassbook, 
  getActiveSell, 
  classbook, 
  selected, 
  setSelected, 
  handleActive
}) => {

    const dispatch = useDispatch();
    const {gradeValue} =  useSelector(state => state.classbook);
    const [editMode, setEditMode] = useState(false); 
    const [value, setValue] = useState(mark); 
    
     const editModeToggle = () => {
      setEditMode(prev => !prev)
      
    }
    
    const cellChanging = async (name, num) => {
        setValue(num)
        await dispatch(setClassbook({...student, [name]: num}, formData))
    }

    // const handleActive = ({mid, lessonid}) => {
    //   if (panelOpen.mid && panelOpen.lessonid) {
    //     setPanelOpen({mid: null, lessonid: null})
    //   } else if (selected.find(el => (el.mid == mid) && (el.lessonid == lessonid))) {
    //     const arr = selected.filter(el => el.mid != mid && el.lessonid != lessonid);
    //     setSelected(arr)
    //   } else {
    //     setSelected([...selected, {mid: mid, lessonid: lessonid}])
    //   }
    // }

    const CheckboxType = (student) => {
      if (student.gradecomment) return "lightgrey" 
      else return "white"
    }

    const handleRightClick = (e, {mid, lessonid}) => {
      e.preventDefault();
      if (e.button === 2) {
        if (selected.find(el => (el.mid == mid) && (el.lessonid == lessonid))) {
        setPanelOpen({mid: mid, lessonid: lessonid})
        } else {
          
        }
      }
    }

    const handleOpenComment = ({mid, lessonid}) => {
      setCommentOpen({mid: mid, lessonid: lessonid});
    }

    return (
    <>
    <td style={{padding: '0px', textAlign: 'center', userSelect: 'none'}}>
    {student.lessondate !== "Ср. балл" && student.student !== "Ср. балл" && student.lessondate !== "Учебный период" ?
        
        <div 
          onClick={() => handleActive({mid: student.mid, lessonid: student.lessonid})} 
          onContextMenu={(e) => e.preventDefault()}
          onMouseDown={(e) => handleRightClick(e, {mid: student.mid, lessonid: student.lessonid})}
          className={styles.cell}
          style={{backgroundColor: selected.find(el => (el.mid == student.mid) && (el.lessonid == student.lessonid)) ? '#3689FF' :  CheckboxType(student)}}
          title={student.gradecomment}
          >
          {student.grade}
          {(panelOpen.mid == student.mid) && (panelOpen.lessonid == student.lessonid) ? 
          <MarkPanel 
            classbook={classbook}
            setSelected={setSelected}
            selected={selected}
            student={student}  
            setPanelOpen={setPanelOpen} 
            gradeValue = {gradeValue} 
            cellChanging={cellChanging}
            handleOpenComment={handleOpenComment}/>: null
          }
        </div>
        :
        <span>{value}</span>
      }
        </td>
        </>
      )
    }

  