import React from 'react'
import {Input, Select, Textarea} from '../../../components/ModalInputs'
import styles from './styles.module.scss'
import { sex, sportGrade, streams, docTypes, armyDistricts } from '../../../assets/const'
import { useContext } from 'react'
import { CandidatesContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCandidateData, getCandidateExamCity, getCandidateLanguages } from '../../../actions/candidates'
import Button from '../../../components/Button'
import { useFormik } from 'formik'
import { getCountries, getFamilySocialComposit, getFamilyStatuses, getSchoolYears, getSchools, getSubjects } from '../../../actions/common'
import cn from 'classnames'
import TreeSelect from "react-dropdown-tree-select";
import { getTreeSelectData } from '../../../utils/smallHelpers'
import 'react-dropdown-tree-select/dist/styles.css';



export default function Main () {
  
  const {candidateId, closeModal} = useContext(CandidatesContext)
  const dispatch = useDispatch()
  const { data, exam_city, languages } = useSelector(state => state.candidates.data)
  const { schoolYears, countries, subjects, schools, familyStatuses, familyCompos } = useSelector(state => state.common)

  useEffect(() => {
    dispatch(getCandidateData(candidateId))
    !exam_city && dispatch(getCandidateExamCity())
    !languages && dispatch(getCandidateLanguages())
    !schoolYears.length && dispatch(getSchoolYears())
    !countries && dispatch(getCountries())
    !subjects && dispatch(getSubjects())
    !schools && dispatch(getSchools())
    !familyStatuses && dispatch(getFamilyStatuses())
    !familyCompos && dispatch(getFamilySocialComposit())
  }, [])
  

  
  const {
    values,
    errors,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: data || {},
    enableReinitialize: true,
    onSubmit: console.log
  })
  
  const renderOptions = ({name, value, xp_key, subject, idschool, schoolname}) =>
    <option value={value || xp_key || idschool}>
      {name || subject || schoolname}
    </option> 
  
  // console.log(familyCompos) 

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
            <Input
              label='Регистр. номер'
              name='caption'
              value={values.caption}
              onChange={handleChange}
              />
            <Select
              label='Год поступления'
              name='s_year_id'
              value={values.s_year_id}
              onChange={handleChange}
            >
              {schoolYears?.map(renderOptions)}
            </Select>
            <Select
              label='Город сдачи вступительных испытаний'
              name='exam_city'
              value={values.exam_city}
              onChange={handleChange}
            >
              {exam_city?.map(renderOptions)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Textarea
              style={{resize: 'none', width: '550px', height: '110px'}}
              label='Служебная информация'
              value={values.serviceinfo}
              name='serviceinfo'
              onChange={handleChange}
              />
            <Select
              label='Поток'
              value={values.stream}
              name='stream'
              onChange={handleChange}
            >
              {streams.map(renderOptions)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Фамилия'
              name='child_lastname'
              value={values.child_lastname}
              onChange={handleChange}
            />
            <Input
              label='Имя'
              name='child_firstname'
              value={values.child_firstname}
              onChange={handleChange}
            />
            <Input
              label='Отчество'
              name='child_midname'
              value={values.child_midname}
              onChange={handleChange}
            />
            <Select
              label='Пол'
              value={values.sex}
              name='sex'
              onChange={handleChange}
            >
              {sex.map(renderOptions)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Дата рождения'
              type='date'
              value={values.child_birth}
              name='child_birth'
              onChange={handleChange}
            />
            <Input
              value={values.child_class}
              label='В какой класс планирует поступать'
              name='child_class'
              onChange={handleChange}
            />
          </div>
          <div className={styles.inputsRow}>
            <Select
              style={{width: '380px'}}
              value={values.idcountry}
              label='Страна'
              name='idcountry'
              onChange={handleChange}
            >
              {countries?.map(renderOptions)}
            </Select>
            <Select
              style={{width: '380px'}}
              value={values.citizen}
              label='Гражданство'
              name='citizen'
              onChange={handleChange}
            >
              {countries?.map(renderOptions)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Select
              label='Субъект РФ'
              value={values.subject}
              name='subject'
              onChange={handleChange}
            >
              {subjects?.map(renderOptions)}
            </Select>
            <Select
              value={values.district}
              label='Военный округ'
              name='district'
              onChange={handleChange}
            >
              {armyDistricts?.map(renderOptions)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Input
              style={{width: '450px'}}
              label='Образовательное учреждение'
              name='child_edu'
              value={values.child_edu}
              onChange={handleChange}
              />
            <Input
              style={{width: '70px'}}
              label='Средний балл'
              value={values.child_mark}
              name='child_mark'
              onChange={handleChange}
            />
          </div>
          <div className={cn(styles.inputsRow, styles.flexBasisRow)}>
            <Input
              style={{width: '70px'}}
              label='Оценка по математике'
              value={values.child_mark_math}
              name='child_mark_math'
              onChange={handleChange}
            />
            <Input
              style={{width: '70px'}}
              label='Оценка по русскому языку'
              value={values.child_mark_russ}
              name='child_mark_russ'
              onChange={handleChange}
            />
            <Input
              style={{width: '70px'}}
              label='Оценка по иностранному языку'
              value={values.child_mark_lang}
              name='child_mark_lang'
              onChange={handleChange}
            />
          </div>
          <div className={cn(styles.inputsRow, styles.flexBasisRow)}>
            <Input
              style={{width: '70px'}}
              label='Оценка по физике'
              value={values.child_mark_phys}
              name='child_mark_phys'
              onChange={handleChange}
            />
            <Input
              style={{width: '70px'}}
              label='Оценка по информатике и ИКТ'
              value={values.child_mark_info}
              name='child_mark_info'
              onChange={handleChange}
            />
            <Select
              label='Оценка по физической культуре'
              value={values.child_mark_sport}
              name='child_mark_sport'
              onChange={handleChange}
            >
              {sportGrade.map(renderOptions)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Select
              label='Тип документа'
              value={values.documenttype}
              name='documenttype'
              onChange={handleChange}
            >
              {docTypes.map(renderOptions)}
            </Select>
            <Input
              label='Серия'
              value={values.documentseria}
              name='documentseria'
              onChange={handleChange}
            />
            <Input
              label='Номер'
              value={values.documentnumber}
              name='documentnumber'
              onChange={handleChange}
            />
          </div>
          <div className={styles.inputsRow}>
            <Input
              style={{width: '450px'}}
              label='Кем выдан'
              value={values.documentwhogive}
              name='documentwhogive'
              onChange={handleChange}
            />
          </div>
          <div className={styles.inputsRow}>
            <Input
              type='date'
              label='Когда выдан'
              value={values.documentwhengive}
              name='documentwhengive'
              onChange={handleChange}
            />
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Откуда Вы узнали о нас'
              value={values.source}
              name='source'
              onChange={handleChange}
            />
          </div>
          <div className={styles.inputsRow}>
            <Select
              label='Училище для поступления'
              value={values.idschool}
              name='idschool'
              onChange={handleChange}
            >
              {schools?.map(renderOptions)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Select
              label='Социальный статус'
              value={values.socialstatus}
              name='socialstatus'
              onChange={handleChange}
            >
              {familyStatuses?.map(({id, name}) => <option>{name}</option>)}
            </Select>
          </div>
          <div className={styles.treeSelectBlock}>
            <p>Социальный состав семьи</p>
            <TreeSelect
              className={styles.treeSelect}
              texts={{
                placeholder: "Поиск...",
                noMatches: "Не найдено"
              }}
              data={getTreeSelectData(familyCompos)}
              onChange={() => {}}
            />
          </div>
          <div className={styles.inputsRow}>
            <Select
              label='Изучаемые иностранные языки'
              value={values.calc_second_languages}
              name='calc_second_languages'
              onChange={handleChange}
            >
              {languages?.map(lang => <option>{lang}</option>)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Иностранный язык для прохождения ВИ'
              value={values.languagevi}
              name='languagevi'
              onChange={handleChange}
            />
          </div>
          <div className={styles.inputsRow}>
            <Textarea
              style={{resize: 'none', width: '550px', height: '110px'}}
              label='Достижения'
              value={values.body}
              name='body'
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className={styles.modalFooter}>
          <div>
              <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
              <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          </div>
          <Button mode='white' onClick={handleSubmit}>Сохранить</Button>
          <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </>
  )
}