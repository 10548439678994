import React from 'react'
import styles from './styles.module.css'
import Select from '../../../components/Select'
import Leaf from '../../../components/Leaf'
import Button from '../../../components/Button'
import { useDispatch } from 'react-redux'
import { createNotification } from '../../../components/Notifications/Notifications'
import { getThemePlanning } from '../../../actions/planning'

const SearchPanel = ({formData, setFormData, teachers, handleInputChange, weekNowToPast, schoolYears, studyPeriod, lessons, getStudyPeriod}) => {

  const dispatch = useDispatch();

  const handleGetList = () => {
    if (formData.teacher && formData.lesson && formData.week) {
      dispatch(getThemePlanning(formData.teacher, formData.lesson, formData.week));
    } else {
      createNotification('error', 'Заполните все обязательные поля');
    }
  } 

  return (
    <div className={styles.wrapper}>

                <div className={styles.row}>
                  
                  <Select
                  title="Учебный год"
                  name={"scYear"}
                  value = {formData?.scYear}
                  withoutArrowMode
                  onChange={handleInputChange}
                  options={schoolYears?.map(el => ({name: el.name, value: el.xp_key}))}/>
                  
                  <Select
                  title="Учебный период"
                  name={"studyPeriod"}
                  value = {formData?.studyPeriod}
                  withoutArrowMode
                  onChange={handleInputChange}
                  options={studyPeriod?.map(el => ({name: el.title, value: el.trmid}))}/>
                  
                  <Leaf
                  title="Неделя"
                  name={"week"}
                  options={weekNowToPast}
                  setFormData={setFormData}
                  formData={formData}
                  />
                </div>
                
                <div className={styles.row}>
                <Select
                title="Преподаватель"
                name={"teacher"}
                value = {formData?.teacher}
                withoutArrowMode
                onChange={handleInputChange}
                options={[{calc_fio: 'Выбрать', mid: '0'}, ...teachers?.teachers]?.map(el => ({name: el.calc_fio, value: el.mid}))}
                />

                <Select
                title="Предмет"
                name={"lesson"}
                value = {formData?.lesson}
                withoutArrowMode
                onChange={handleInputChange}
                options={[{title: 'Выбрать', cid: '0'}, ...lessons]?.map(el => ({name: el.title, value: el.cid}))}
                />

                </div>                
            
                <Button onClick={handleGetList}>Обновить</Button>
            
            </div>
  )
}

export default SearchPanel