import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { getCurrentYearUtils } from '../../utils/classBook'
import { useDispatch, useSelector } from 'react-redux'
import { getScClassSchedule, getScheduleWeek } from '../../actions/schedule'
import { getSchoolYears, getTeachersList } from '../../actions/common'
import { getLesson, getStudyPeriod } from '../../actions/classbook'
import SearchPanel from './SearchPanel'
import Table from './Table'
import Modal from './modal'
import { createNotification } from '../../components/Notifications/Notifications'

const ThematicPlanning = () => {

    const [formData, setFormData] = useState({class: '', scYear:'', week:'', teacher:'', lesson: '' });
    
    const [modalOpen, setModalOpen] = useState(false);
    const [data, setData] = useState({});

    const dispatch = useDispatch();

    const handleSubmit = () => {
        setModalOpen(false);
        createNotification('success', 'Успешно');
    }

    const { schoolYears, teachers = {teachers: []}} = useSelector(state => state.common);

    const { weeks } = useSelector(state => state.schedule);
    const { studyPeriod, lessons, scClass, classbook,loading } = useSelector(state => state.classbook);

    const weekNowToPast = [...weeks].reverse();

    useEffect(() => {
        dispatch(getTeachersList());
        dispatch(getSchoolYears());
    }, [])

    useEffect(() => {
        const currYear = getCurrentYearUtils(schoolYears)
        setFormData(prev => ({...prev, scYear:currYear }))
      }, [schoolYears])
    
    useEffect(() => {
        setFormData(prev => ({...prev, class:'' }))
    }, [formData.week])

   useEffect(() => {
    formData.scYear &&
    dispatch(getScheduleWeek(formData.scYear))
    }, [formData.scYear])

    useEffect(() => {
        formData.week &&
        dispatch(getScClassSchedule(formData))
    }, [formData.week])

    useEffect(() => {
        if (formData.week && formData.scYear) {
            dispatch(getLesson({scYear: formData.scYear, studyPeriod: formData.studyPeriod}));
        }
    }, [formData.week, formData.scYear])

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    useEffect(() => {
        schoolYears?.length &&
         dispatch(getStudyPeriod(formData)) &&
         studyPeriod.length && 
         dispatch(getLesson({scYear: formData.scYear, studyPeriod: formData.studyPeriod}))
       }, [formData?.scYear, formData?.studyPeriod, formData?.lesson])

  return (
    <div className={styles.wrapper}>
        <h1>Тематическое планирование</h1>
            
            <SearchPanel 
                lessons={lessons}
                formData={formData} 
                teachers={teachers} 
                schoolYears={schoolYears} 
                studyPeriod={studyPeriod} 
                setFormData={setFormData} 
                weekNowToPast={weekNowToPast} 
                getStudyPeriod={getStudyPeriod}
                handleInputChange={handleInputChange} />

            <Table modalOpen={openModal} data={data}/>

            <Modal isOpen={modalOpen} closeModal={closeModal} handleSubmit={handleSubmit}/>
        
    </div>
  )
}

export default ThematicPlanning