import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox, Input, Select, Textarea } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { createVaccine, updateVaccine, } from '../../../actions/medCenter/vassinations'
import ModalVacTop from '../TypeVaccine/ModalVacTop'
import Modal from 'react-responsive-modal'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').min(4, 'Должно быть не более 4 символов').max(50, 'Должно быть не более 50 символов'),
  phase: Yup.number().required('обязательное поле').max(9999, 'Должно быть не более 4 символов'),
  pause: Yup.number().required('обязательное поле').max(9999, 'Должно быть не более 4 символов'),
  expired: Yup.number().required('обязательное поле').max(9999, 'Должно быть не более 4 символов'),
  notes: Yup.string().max(255, 'Должно быть не более 255 символов'),

})


const expire_unitList = [
  { expire_unit: 'дней', typeId: 1 },
  { expire_unit: 'месяцев', typeId: 2 },
  { expire_unit: 'лет', typeId: 3 },
]

export default function ModalMain({ closeModal, data, selectedVaccine }) {
  console.log('data::: ', data);

  const dispatch = useDispatch()
  const { typesVaccinations } = useSelector(state => state.directoryMedCenter)

  const addMode = data === 'add'

  const [openModalVacTop, setOpenModalVacTop] = useState(false)

  const handleCreate = (values) => {
    dispatch(createVaccine(values))
    closeModal()
  }

  const handleUpdate = (values) => {
    dispatch(updateVaccine(values))
    closeModal()
  }

  const handleOpenVac = () => {
    setOpenModalVacTop(true);
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? {
      "c_vaccine": 0,
      "name": "",
      "used": null,
      "c_vac_type": 0,
      "phase": 1,
      "expired": 2,
      "pause": 180,
      "disabled": 1,
      "notes": " ",
      "expire_unit": 1
    } : data,
    enableReinitialize: true,
    // validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName]) && errors[fieldName]

  return (
    <Modal
      close={closeModal}
      open={!!data}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      center
      showCloseIcon={false}
      animationDuration={1}
      classNames={{
        modal: styles.roomTypesModal
      }}
      style={{
        padding: '0',
        margin: '0'
      }}>

      <div className={styles.modalMain} >
        <div className={styles.tabWrapper} >

          {openModalVacTop ?
            <ModalVacTop setOpenModalVacTop={setOpenModalVacTop} />
            : <></>
          }

          <div className={styles.inputsRow}>
            <Select
              label='Тип вакцины'
              value={values.c_vac_type}
              onChange={handleChange}
              name='c_vac_type'
              error={error('name')}
              style={{ width: '150px' }}
            >
              {typesVaccinations?.length && [{ name: "Выберите тип", c_vac_type: 0 }, ...typesVaccinations].map(({ name, c_vac_type }) => <option value={c_vac_type}>{name}</option>)}
            </Select>
            <div
              className={styles.edit}
              onClick={() => handleOpenVac()}
            >
              Изменить
            </div>
          </div>

          <div className={styles.inputsRow}>
            <Input
              label='Наименование'
              name='name'
              value={values.name}
              error={error('name')}
              onChange={handleChange}
              style={{ width: '620px' }}
            />
          </div>
          <div className={styles.inputsRow}>
            <Input
              name='phase'
              type='number'
              label='Компонент'
              value={values.phase}
              error={error('phase')}
              onChange={handleChange}
              style={{ width: '150px' }}
            />
            <Input
              name='pause'
              type='number'
              label='Срок действия'
              value={values.pause}
              error={error('pause')}
              onChange={handleChange}
              style={{ width: '150px' }}
            />
            <Select
              label='исчисление'
              value={values.expire_unit}
              onChange={handleChange}
              name='expire_unit'
              error={error('expire_unit')}
              style={{ width: '150px' }}
            >
              {expire_unitList?.map(({ expire_unit, typeId }) => <option value={typeId}>{expire_unit}</option>)}
            </Select>
            <Input
              name='expired'
              type='number'
              label='Интервал'
              value={values.expired}
              error={error('expired')}
              onChange={handleChange}
              style={{ width: '150px' }}
            />
          </div>
          <div className={styles.inputsRow}>
            <Checkbox label='Обучающийся' checked={!!values.disabled} value={!!values.disabled} onChange={handleChange} name='disabled' />
            <Checkbox label='Сотрудник' checked={!!values.expire_unit} value={!!values.expire_unit} onChange={handleChange} name='expire_unit' />
          </div>
          <br />
          <Textarea
            label='Описание'
            className={styles.noticeTextarea}
            style={{ width: '400px', height: '200px', resize: 'none' }}
            name='notes'
            error={error('notes')}
            value={values.notes}
            onChange={handleChange}
          />
        </div>
      </div>

      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </Modal>
  )
}

