import { api } from "../App";
import { SET_EDU_DIRECTIONS_LIST, SET_EDU_DIRECTIONS_LOADING, SET_EDUC_DIRECTIONS_LIST, SET_EDUC_DIRECTIONS_LOADING } from "./types";




export const getEducDirections = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EDUC_DIRECTIONS_LOADING, payload: true });
    const { data } = await api.get('/educdirection');
    dispatch({ type: SET_EDUC_DIRECTIONS_LIST, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EDUC_DIRECTIONS_LOADING, payload: false });
  }
}

export const createEducDirection = (data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_EDUC_DIRECTIONS_LOADING, payload: true });
    await api.post('/educdirection', data);
    await dispatch(getEducDirections())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EDUC_DIRECTIONS_LOADING, payload: false });
    return success
  }
}

export const updateEducDirection = (id, data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_EDUC_DIRECTIONS_LOADING, payload: true });
    await api.put(`/educdirection/${id}`, data);
    await dispatch(getEducDirections())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EDUC_DIRECTIONS_LOADING, payload: false });
    return success
  }
}

export const deleteEducDirection = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EDUC_DIRECTIONS_LOADING, payload: true });
    await api.delete(`/educdirection/${id}`);
    await dispatch(getEducDirections())
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EDUC_DIRECTIONS_LOADING, payload: false });
  }
}