import React from 'react'
import styles from './styles.module.css'
import cn from 'classnames'
import { Puff } from 'react-loader-spinner'

export default function Button ({children, className, mode, loading, ...props}) {

  const classObject = cn(styles.button, className, {
    [styles.white]: mode === 'white',
    [styles.bordered]: mode === 'bordered',
  })

  const loaderColor = mode === 'white' ? '#3689FF' : 'white'

  return (
    <button type='button' className={classObject} {...props} >
      {!!loading &&
        <Puff
          height="20"
          width="20"
          radius={1}
          color={loaderColor}
          ariaLabel="puff-loading"
          wrapperClass={styles.loaderWrap}
        />
      }
      {children}
    </button>    
  )
}
