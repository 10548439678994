import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select, } from '../../../components/ModalInputs'
import styles from '../styles.module.scss'
import Table from '../../../components/Table'
import { getScheduleSettingsRole, getScheduleSettingsRooms, updateScheduleSettingsRoomsTeachers, getScheduleSettingsRoomsTeachers } from '../../../actions/scheduleSettings'
import Loader from '../../../components/Loader'


export default function CabinetsTeachers() {

  const dispatch = useDispatch()
  const { scheduleSettingsRooms, scheduleSettingsRoomsTeachersList, loading, role } = useSelector(state => state.schedule_settings)
  const [currRole, setCurrRole] = useState(0);

  useEffect(() => {
    dispatch(getScheduleSettingsRooms())
    dispatch(getScheduleSettingsRoomsTeachers())
    dispatch(getScheduleSettingsRole())
  }, [])

  useEffect(() => {
    dispatch(getScheduleSettingsRoomsTeachers(currRole))
  }, [currRole])

  const selectedCell = (data) => () => {
    const res = { "rid": data[0], "mid": data[1] }
    dispatch(updateScheduleSettingsRoomsTeachers(res))
  }

  const renderLi = (data) => {
    if (data) return (
      <tr key={data[0]}>
        <td className={styles.fio}>{data[1]}</td>
        {data[2].map((el, i) =>
          <td
            key={el[0]}
            onClick={selectedCell([scheduleSettingsRooms?.[i].rid, data[0]])}
            style={{ backgroundColor: el[1] === 1 && '#3689FF' }}

          ></td>)}
      </tr >
    )
  }

  return (
    loading ? <Loader /> :
      <div className={styles.inputsWrapper}>
        <div className={styles.selectWrapper}>
          <Select
            label='Роль'
            value={currRole}
            // onChange={setCurrYear()}
            onChange={({ target: { name, value } }) => setCurrRole(value)}
            name='xp_key'
          // style={{ width: '500px' }}
          >
            {role && [{ Название: 'Выберите роль', pmid: 0 }, ...role].map(({ Название, pmid }) => <option key={pmid} value={pmid}>{Название}</option>)}
          </Select>
        </div>
        <Table style={{ marginTop: '2rem', backgroundColor: 'white', position: 'relative', height: '70vh' }}>
          <thead className={styles.headSchSet}>
            <tr>
              <th className={styles.fio2}></th>
              {scheduleSettingsRooms?.map(el => <th>{el.roomname}</th>)}
            </tr>
          </thead>
          <tbody>

            {scheduleSettingsRoomsTeachersList?.map(renderLi)}

          </tbody>
        </Table>

      </div>
  )
}
