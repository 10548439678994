import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Enrolment, AssessmentParams, Notices, NoticesStat, ExamTickets, Form} from './CandidatesComponents'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import styles from '../styles.module.scss'
import { useFormik } from 'formik'
import moment from 'moment'



export default function Candidates () {
  
  const { schoolYears } = useSelector(state => state.common)

  const form = useFormik({
    initialValues: {
      year: schoolYears?.[0].xp_key,
      date_from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
      format: 'Очно'
    },
    onSubmit: console.log
  })

  
  return (
    <div className={styles.candidatesWrapper}>
      
      <Form form={form}/>

      <Tabs>
              <TabList className={styles.tabsBlock}>
                  <Tab className={styles.tab} selectedClassName={styles.activeTab}>Нормативы зачисления</Tab>
                  <Tab className={styles.tab} selectedClassName={styles.activeTab}>Параметры оценивания</Tab>
                  <Tab className={styles.tab} selectedClassName={styles.activeTab}>Извещения</Tab>
                  <Tab className={styles.tab} selectedClassName={styles.activeTab}>Экзаменационные билеты</Tab>
              </TabList>

              <TabPanel>
                  <Enrolment form={form}/>
              </TabPanel>

              <TabPanel>
                  <AssessmentParams form={form}/>
              </TabPanel>

              <TabPanel>
                  <Notices/>
              </TabPanel>

              <TabPanel>
                  <ExamTickets/>
              </TabPanel>

          </Tabs>

    </div>
  )
}
