import React, { useContext, useEffect } from 'react'
import styles from '../styles.module.scss'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Table from '../../../components/Table'
// import Confirmation from '../../components/Confirmation/Confirmation'
import { GET_MKB_TEN_BOT, GET_MKB_TEN_MID } from '../../../actions/types'
import { getDataStructure } from '../../../actions/disciplineStructure'
import { DisciplineStructureContext } from '../../../utils/context'



export default function TableExpand() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { form, initialValues } = useContext(DisciplineStructureContext)

  const { values, setFieldValue, setValues, handleChange } = form

  const { data } = useSelector(state => state.discipline_structure)

  const [selectedItem, setSelectedItem] = useState([])
  
  const [dataTable, setDataTable] = useState()

  const [years, setYears] = useState([])
  const [teachers, setTeachers] = useState([])
  const [courses, setCourses] = useState([])
  const [course, setCourse] = useState([])

  useEffect(() => {
    let forRequest = { course_type: values?.educationType }
    if (dataTable?.xp_key) {
      forRequest = { ...forRequest, xp_key: dataTable?.xp_key }
    }
    if (dataTable?.teacher_mid) {
      forRequest = { ...forRequest, mid: dataTable?.teacher_mid }
    }
    if (dataTable?.cid) {
      forRequest = { ...forRequest, cid: dataTable?.cid }
      dispatch(getDataStructure(forRequest))
    }
    if (!dataTable?.cid) {
      dispatch(getDataStructure(forRequest))
    }
  }, [dataTable, values])

  useEffect(() => {
    if (data?.length) {
      !!data?.[0]?.xp_key && setYears(data)
      !data?.[0]?.xp_key && !!data?.[0]?.teacher_mid && !data?.[0]?.cid && setTeachers(data)
      !data?.[0]?.xp_key && !data?.[0]?.teacher_mid && !!data?.[0]?.cid && setCourses(data)
      !data?.[0]?.xp_key && !!data?.[0]?.teacher_mid && !!data?.[0]?.cid && !!data?.[0]?.oid && setCourse(data)
    } else {
      setTeachers([])
      setCourses([])
      setCourse([])
    }
  }, [data])


  const openModal = (data) => () => {
    console.log('data::: ', data);
    console.log('selectedItem::: ', selectedItem);
    console.log('dataTable::: ', dataTable);
    setSelectedItem((prev) => ({ ...prev, ...data }))
    setDataTable((prev) => ({ ...prev, ...data }))

    if (data.xp_key && dataTable?.xp_key !== data?.xp_key) {
      if (dataTable?.teacher_mid) {
        delete dataTable.teacher_mid
        delete dataTable.cid
        setDataTable(data)
        setCourses([])
        setCourse([])
      } 
    }
    if (data.teacher_mid && dataTable?.teacher_mid !== data?.teacher_mid) {
      if (dataTable?.cid) {
        delete dataTable.cid
        setCourses([])
        setCourse([])
        setDataTable((prev) => (console.log('prev::: ', prev),
          { ...prev, ...data }
        ))
        
      }
       else { 
        delete dataTable.teacher_mid
        delete dataTable.cid
        setCourses([])
        setCourse([])
      }

      setDataTable((prev) => ({ ...prev, ...data }))
    }

    if (data.cid && dataTable.cid && dataTable?.cid !== data?.cid) {
      setDataTable((prev) => ({ ...prev, ...data }))
    }
    if (data.cid && dataTable?.cid === data?.cid) {
      delete dataTable.cid
      setCourse([])
    }

  
  }

  const closeModal = () => {
    setSelectedItem(null)
  }

  const goHome = () => navigate('/')

  const renderTop = (datas) => {
    const { name, xp_key } = datas
    return (
      <>
        <tr key={xp_key} >
          <td name='name' onClick={openModal(datas)} >{name}</td>
          <td name='' onClick={openModal(datas)}>{ }</td>
          <td name='' onClick={openModal(datas)}>{ }</td>
          <td name='' onClick={openModal(datas)}>{ }</td>
          <td name='' onClick={openModal(datas)}>{ }</td>
        </tr>
        {selectedItem?.xp_key == xp_key && teachers?.map(renderMid)}
      </>
    )
  }

  const renderMid = (teachers) => {
    const { code, id, name, teacher, teacher_mid, oid } = teachers
    return (
      <>
        <tr style={{ backgroundColor: '#95d7fd', paddingLeft: '70px' }} >
          <td name='code' onClick={openModal(teachers)} >{teacher}</td>
          <td name='name' onClick={openModal({ mid: teachers.teacher_mid })} >{ }</td>
          <td name='name' >{ }</td>
          <td name='name' >{ }</td>
          <td name='name' >{ }</td>
          {/* <td name='code' onClick={openModal2(data)}>{code}</td>
              <td name='name' onClick={openModal2(data)} >{name}</td> */}
        </tr>
        {selectedItem?.teacher_mid == teacher_mid && !oid && courses?.map(renderBot)}
      </>
    )
  }

  const renderBot = (subject) => {
    const { cid, predmet } = subject
    return (
      <>
        <tr style={{ backgroundColor: '#a7f3b4', }} >

          {/* <td name='name' style={{ paddingLeft: '300px' }}>{predmet}</td> */}
          <td colSpan={2} name='name' style={{ paddingLeft: '100px' }} onClick={openModal(subject)} >{predmet}</td>
          {/* <td name='name' onClick={openModal3(data)} >{name}</td> */}
        </tr>
        {dataTable.cid && selectedItem?.cid == cid && course?.map(renderSub)}
       { console.log('dataTable.cid::: ', dataTable)}

      </>
    )
  }
  const renderSub = (subjects) => {
    const { code, predmet } = subjects
    return (
      <tr style={{ backgroundColor: '#49cbcd', paddingLeft: '150px' }} >
        <td colSpan={3} name='name' style={{ backgroundColor: '#49cbcd', paddingLeft: '100px' }}>{predmet}</td>
      </tr>
    )
  }

  return (
    <div className={styles.wrapper}>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>№ урока</th>
            <th>Кол-во часов</th>
            <th>Планируемые результаты</th>
            <th>Упражнения</th>
          </tr>
        </thead>
        <tbody>
          {years?.map(renderTop)}
        </tbody>
      </Table>

    </div>
  )
}