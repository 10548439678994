import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import styles from './styles.module.css'

import { useDispatch, useSelector } from 'react-redux'
import { signOut } from '../../actions/auth'
import { menuItems } from '../../assets/const'

import menuSVG from '../../assets/images/layout/menu.svg'
import emblem from '../../assets/images/layout/emblem.png'
import person from '../../assets/images/layout/person.svg'
import closeIcon from '../../assets/images/layout/closeIcon.svg'
import cn from 'classnames'
import { BsFillLockFill } from "react-icons/bs";
import { useRef } from 'react'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'

import Search from './Search'
import {BiSearch, BiX} from 'react-icons/bi'

export default function Layout () {
  
  const dispatch = useDispatch()
  const { displayname } = useSelector(state => state.auth)
  const pcMenuRef = useRef(null)

  const [burgerState, setBurgerState] = useState(false);
  const [isShowMenu, setIsShowMenu] = useState([]);
  const [pcMenu, setPcMenu] = useState(false)
  const [isSearch, setIsSearch] = useState(false);

  useOnClickOutside(pcMenuRef, () => setPcMenu(false))

  const handleMenu = (id, menu, e) => {
    if (menu) {
      e.preventDefault();

      if (isShowMenu.includes(id)) {
        const ids = [...isShowMenu].filter(el => el !== id);
        setIsShowMenu(ids)
      } else {
        setIsShowMenu([...isShowMenu, id])
      }
    }
  }

  const handleBurger = () => {
    setBurgerState(false);
    setIsShowMenu([]);
  }

  const logOut = () => dispatch(signOut())

  const renderLastLevelLi = ({link, label}) => {
    const disactive = link.length < 2
    return (
      <li className={styles.lastLevelLi}>
        <a
          href={link}
          style={disactive
                  ? {
                      color: 'grey',
                      pointerEvents: 'none'
                    }
                  : null
                }
          key={label}
        >
          {label}
        </a>
          {disactive && <BsFillLockFill color='grey'/>}
      </li>
    )
  }

  const renderBurgerMenuItem = (item) => {
    const {label, link, icon, menu, id} = item;
    return (
      <div className={styles.mainsub} key={label}>
      <li  
        className={styles.burgerMenuItem}
        onClick={(e) => handleMenu(id, menu, e)}>
        <div>
        {icon('#1671EF')}
        <span>{label}</span>
        </div>
        <i className={styles.fa}>
        <svg 
          className={`${isShowMenu.includes(id) ? styles.arrowActive : styles.arrow}`}  
          width="10" 
          height="14" 
          viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.74658 1L7.74658 7.13954L1.74658 13" stroke="#314561" strokeWidth="2" strokeLinecap="round"/>
        </svg>
        </i>
      </li>
      
      {
        isShowMenu.includes(id) ? 
        <ul>
        {
          menu ?
          menu.map(el => el.menu
            ?  <li>
                <a 
                  key={el.id}
                  href={el.link} 
                  onClick={(e) => handleMenu(el.id, el.menu, e)}>
                  {el.label}
                {
                  el.menu ? 
                  <i className={styles.fa}>
                  <svg 
                    className={`${isShowMenu.includes(el.id) ? styles.arrowActive : styles.arrow}`}  
                    width="10" 
                    height="14" 
                    viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.74658 1L7.74658 7.13954L1.74658 13" stroke="#314561" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                  </i>
                  :
                  ''
                }
                </a>

                {
                  isShowMenu.includes(el.id) ? 
                <ul className={`${styles.submenu} ${styles.submenu2}`}>
                {
                    el?.menu?.map(_el => 
                        <div key={_el.id}>
                        {
                          _el.menu ? 
                          <li>
                          <a
                            href={_el.link} 
                            onClick={(e) => handleMenu(_el.id, _el.menu, e)}>
                            {_el.label}
                          <i className={styles.fa}>
                          <svg 
                            className={`${isShowMenu.includes(_el.id) ? styles.arrowActive : styles.arrow}`}  
                            width="10" 
                            height="14" 
                            viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.74658 1L7.74658 7.13954L1.74658 13" stroke="#314561" strokeWidth="2" strokeLinecap="round"/>
                          </svg>
                          </i>
                          </a>
                          {
                            isShowMenu.includes(_el.id) ?
                            <ul 
                              className={`${styles.submenu222}`}>
                          {
                          _el?.menu?.map(__el => 
                              <div className={styles.last} key={__el.id}>
                                {
                                  __el.menu
                                    ? <li>
                                        <a href={__el.link}>{__el.label}
                                          <i className={`${styles.fa} ${styles.fa2}`}>
                                            <svg 
                                              className={`${isShowMenu.includes(__el.id) ? styles.arrowActive : styles.arrow}`} 
                                              width="10" 
                                              height="14" 
                                              viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path d="M1.74658 1L7.74658 7.13954L1.74658 13" stroke="#314561" strokeWidth="2" strokeLinecap="round"/>
                                            </svg>
                                          </i>
                                        </a>
                                      </li>
                                  : renderLastLevelLi(__el)
                                }
                              </div>
                            )
                          }
                          </ul>
                          :
                          ''
                          }

                          </li>
                          : renderLastLevelLi(_el)
                        }
                        </div>
                        )             
                      }
                      </ul>
                    :
                    ''  
                    }
                </li>
            : renderLastLevelLi(el)
          )
            : renderLastLevelLi(item)
          }
          </ul>  
          : ''
      }
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
          {!burgerState && <img onClick={() => setBurgerState(true)} src={menuSVG} alt='o_O'/>}
          <a href='/'>
            <img src={'/images/LMSLogo.png'} alt='o_O'/>
          </a>
          <div className={cn(styles.burgerWrapper, {[styles.burgerVisible] : burgerState})}>
          <div className={styles.burgerTitle}>
            <span>Навигация</span>
            <img onClick={() => handleBurger()} src={closeIcon} alt='o_O'/>
          </div>
            <div className={styles.burgerMenu}>
              <ul>
                {menuItems.map(renderBurgerMenuItem)}
              </ul>
            </div>
          </div>
          <div className={styles.emblemBlock}>
            <img src={emblem} alt='o_O'/>
            <span>Министерство обороны Российской Федерации</span>
          </div>

          <div className={styles.cabinet}>
          {!isSearch
                ? <i className={styles.searchIcon} onClick={() => setIsSearch(!isSearch)}>
                        <BiSearch/>
                    </i>
                : <i className={styles.searchIcon} onClick={() => setIsSearch(!isSearch)}><BiX/></i>}

            <div className={styles.cabinetTitles}>
              <div>{displayname}</div>
              <div>Администратор</div>
            </div>
            <div
              onClick={() => setPcMenu(true)}
              ref={pcMenuRef}
              className={styles.cabinetMenu}
            >
              <img src={person} alt='o_O'/>
              <ul className={cn(styles.cabinetList, {[styles.cabinetListVisible] : pcMenu})}>
                <li onClick={() => null}>Личный кабинет</li>
                <li onClick={logOut}>Выход</li>
              </ul>
            </div>
          </div>
      </header>
      {isSearch && <Search setIsSearch={setIsSearch} data={menuItems}/>} 
      <main>
        <Outlet/>
      </main>
    </div>
  )
}
