import React, { useEffect, useState } from 'react'
import { Select } from '../../../components/ModalInputs'
import { BACKEND_URL_PREFIX } from '../../../assets/const'
import { useSelector } from 'react-redux'
import { objFilter } from '../../../utils/smallHelpers'


export default function SelectComponent ({data, formik}) {
  
  const { token } = useSelector(state => state.auth)

  const [options, setOptions] = useState([])
  const [deps, setDeps] = useState(undefined)

  useEffect(() => {
      if (!formik.values || !data.arguments) return;
      const newDeps = data.arguments.reduce((acc, dep) => ({...acc, [dep]: formik.values[dep]}), {})
      if (!deps) return setDeps(newDeps);
      const needRerender = data.arguments.some(dep => deps[dep] !== newDeps[dep])
      if (!needRerender) return;
      setDeps(newDeps)
  }, [formik.values])
  

  useEffect(() => {
    const {api_route} = data
    api_route && getOptions(api_route) 
  }, [deps])
  
  const getOptions = async (entity) => {
    const headers = { Authorization: `Basic ${btoa(`nnz:${token}`)}` }
    const filteredPArams = objFilter(deps)
    const urlParams = new URLSearchParams(filteredPArams).toString();
    const respose = await fetch(`${BACKEND_URL_PREFIX}/${entity}?${urlParams}`, { headers })
    const data = await respose.json()
    setOptions(data[entity])
  }
  
  const renderOption = (option, index) => {
    const { mid, calc_fio, trmid, xp_key, name, title, cid } = option
    const optionValue = trmid || mid || xp_key || cid
    const optionTitle = title || name || calc_fio
    return <option key={`${optionValue}-${index}`} value={optionValue}>{optionTitle}</option>
  }

  return (
    <Select
      label={data.description}
      onChange={formik.handleChange}
      name={data.name}
    >
      <option key='Not_option' value=''>Выбрать</option>
      {options.map(renderOption)}
    </Select>
  )
}
