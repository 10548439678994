import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { personnelModalModes } from '../../assets/const'
import userPhoto from '../../assets/images/user_photo2.png'
import cn from 'classnames'
import { Main, More, Education, Qualification, Award, Punish, Experience, Lessons, Events, Roles, Documents, Attestation } from './ModalTabs'
import { StaffRosterContext } from '../../utils/context'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { createPersonnelInfo, updatePersonnelInfo } from '../../actions/personnel'


export default function ModalComponent({ isOpen, closeModal, modalOpen, person }) {

    const dispatch = useDispatch()

    const [mode, setMode] = useState('main')
    const addMode = modalOpen === 'add' ? true : false

    // TODO 400 
    const handleCreateStaffRoster = (values) => {
        dispatch(createPersonnelInfo(values))
        closeModal()
    }
    // TODO 400

    const handleUpdateStaffRoster = (values) => {
        dispatch(updatePersonnelInfo(values))
        closeModal()
    }


    const allForms = useFormik({
        // initialValues: addMode ? {xp_key: '', name: '', type: '', hidden: '', timestamp: ''} : data,
        // initialValues: addMode ? {} : modalOpen,
        initialValues: addMode ? {
            "lastname": "Алехин",
            "firstname": "Николай",
            "patronymic": "Романович",
            "information": "''",
            "address": "''",
            "fax": " ",
            "login": "n.alehin",
            "password": "*9F199D86FCC9BA7DD20BDCDC537DDFB290E43409",
            "password_parent": null,
            "javapassword": "",
            "birthdate": null,
            "cellularnumber": " ",
            "invalid_login": 0,
            "isad": 0,
            "access_level": 5,
            "xp_daysoffquantity": 0,
            "lastparent": null,
            "countloginparent": 0,
            "login_parent": null,
            "password_md5": "ec92502018f2a9fdae55bb9f7dba1d03",
            "password_parent_md5": null,
            "pass1": null,
            "socialstatus": null,
            "category": " ",
            "issystem": 0,
            "timestamp": "2024-01-24T22:26:08+03:00",
            "blocked": 0,
            "need_change_password": -1,
            "xp_pf_id": 811,
            "number": " ",
            "liter": " ",
            "schoolfrom": " ",
            "sex": "мужской",
            "birthday": "1998-07-22",
            "birthplace": " ",
            "ethnicity": " ",
            "kindergarten": " ",
            "dateleaveschool": null,
            "placeleave": " ",
            "dateincludingpansion": "2021-09-01",
            "militarydistrictarrival": " ",
            "resultincluding": " ",
            "accesstoexam": " ",
            "reasondeny": " ",
            "familystatus": " ",
            "havingmanychildrenfamily": 0,
            "underwardship": 0,
            "stepfather": 0,
            "awards": " ",
            "awards_death": " ",
            "invalids": " ",
            "vyslugafather": " ",
            "vyslugamother": " ",
            "comments": " ",
            "whocreate": null,
            "whencreate": "2021-09-01",
            "whochange": null,
            "whenchange": "2023-03-14",
            "registrationaddress": null,
            "residingindex": null,
            "residingcountry": null,
            "residingsubject": null,
            "residingtown": null,
            "residingaddress": "г.Санкт-Петербург, Б. Сампсониевский проспект, 63",
            "documenttype": "Паспорт",
            "documentseria": "4018",
            "documentnumber": "139808",
            "documentwhogive": "ГУ МВД России по г. Санкт-Петербургу и Ленинградской области ",
            "documentwhengive": "2018-09-18",
            "passportseria": null,
            "passportnumber": null,
            "passportdate": null,
            "insuranceseria": null,
            "insurancenumber": null,
            "insurancecompany": null,
            "insurancedate": null,
            "notebooknumber": null,
            "certificatenumber": null,
            "fiotutor": null,
            "dateleavepansion": null,
            "numberprotocolleavepansion": null,
            "numberorderleavepansion": null,
            "privelegecode": null,
            "privelegename": null,
            "privelege_xp_fm_id": null,
            "enterexam1name": null,
            "enterexam1grade": null,
            "enterexam2name": null,
            "enterexam2grade": null,
            "enterexam3name": null,
            "enterexam3grade": null,
            "entertest1grade": null,
            "entertest2grade": null,
            "entertest3grade": null,
            "webid": 0,
            "rfidid": null,
            "enteravggrade": " ",
            "entered": 0,
            "portfoliotext": " ",
            "portfoliograde": 0,
            "school2enter": " ",
            "phone_work": null,
            "pone_work_ext": null,
            "phone_cell": "1",
            "phone_home": null,
            "education": "-",
            "vuz": "-",
            "specialisation": "-",
            "scn_degree": null,
            "all_exp": 0,
            "ped_exp": 0,
            "razryad": null,
            "pedcategory": "Без категории",
            "nagruzka": null,
            "datelastcert": null,
            "additions": null,
            "kindwork": "внешнее совместительство",
            "klassincludingpansion": null,
            "workstatus": "Работает",
            "persstate": "Прикомандирован",
            "perstype": "Преподаватели",
            "warready": 0,
            "pensionss": null,
            "civilschool": 0,
            "enterotherschool": 0,
            "entercivilhighschool": 0,
            "enterwarhighschool": 0,
            "behaviour": null,
            "health": null,
            "socialgroup": 0,
            "muz_instrument": 0,
            "email": "n.alehin@pansion.spb.ru",
            "skype": "",
            "email_parent": null,
            "whocreate_mid": 22,
            "whochange_mid": 295,
            "stagdate": "2021-09-01",
            "totaldays": 0,
            "totalmonths": 0,
            "totalyears": 0,
            "contdays": null,
            "contmonths": null,
            "contyears": null,
            "vysldays": null,
            "vyslmonths": null,
            "vyslyears": null,
            "mil_reservecategory": null,
            "mil_rank": null,
            "mil_sostav": null,
            "mil_vus": null,
            "mil_validatecategory": null,
            "mil_commissariat": null,
            "mil_registrationon": null,
            "mil_registrationgeneral": 0,
            "mil_registrationspecial": 0,
            "mil_registrationoff": null,
            "birthplaceokato": null,
            "citizenokin": 0,
            "registrationindex": null,
            "registrationdate": null,
            "inn": null,
            "datet2": null,
            "contractnumber": null,
            "contracttext": null,
            "contractendosnovanie": null,
            "contractendprikaz": null,
            "contractenddate": null,
            "mainprofession": null,
            "mainprofcode": null,
            "otherprofession": null,
            "otherprofcode": null,
            "marriokin": null,
            "idschool": null,
            "healthgroup": 0,
            "extraaction": null,
            "sciencemetaction": null,
            "achievements": null,
            "hobby": null,
            "peddays": 0,
            "pedmonths": 0,
            "pedyears": 0,
            "heredays": 0,
            "heremonths": 0,
            "hereyears": 0,
            "enterexam4grade": null,
            "enterexam4name": null,
            "enterexam5grade": null,
            "enterexam5name": null,
            "enterexam6grade": null,
            "enterexam6name": null,
            "profile": null,
            "honortitle": null,
            "idapplicant": null,
            "documentseria2": null,
            "documentnumber2": null,
            "documentwhengive2": null,
            "documentwhogive2": null,
            "entertest4grade": null,
            "entertest5grade": null,
            "entertest6grade": null,
            "teacherattestation": 0,
            "positioneligibility": 0,
            "notebooknumber2": null,
            "phonecelloperator": null,
            "phonecellmodel": null,
            "phonecellimei": null,
            "reasonleave": null,
            "commentleave": null,
            "rid": null,
            "idadm_district_moscow": 0,
            "lastupdatestagdate": "2024-01-25",
            "expnow": " 0л 0м 0д",
            "expnowteach": " 0л 0м 0д",
            "id_category": null,
            "enterwarhighschoolother": 0,
            "passportissuedate": null,
            "not_certified": 0,
            "family_status": 0,
            "family_financial_sotuation": 0,
            "psychologicalattention": 0,
            "scnrank": null,
            "physicalgroup": 0,
            "desk_number": "0    ",
            "doctors_recommendation": null,
            "stream": null,
            "expnowhere": " 0л 0м 0д",
            "expnowmo": " 0л 0м 0д",
            "modays": 0,
            "momonths": 0,
            "moyears": 0,
            "allowediplist": null,
            "documentissuercode": null,
            "phone_book_entry": null,
            "typeinformationpercept": null,
            "citizen": null

        } : person,
        enableReinitialize: true,
        // validateOnChange: true,
        // validationSchema: validationSchema,
        onSubmit: addMode ? handleCreateStaffRoster : handleUpdateStaffRoster
    })
    const renderLi = ({ name, value, icon }) => {
        const selected = value === mode
        return (
            <li
                onClick={() => setMode(value)}
                className={cn(styles.modalMenuItem, {
                    [styles.selectedMenuItem]: selected
                })}
            >
                {icon()}
                {name}
            </li>
        )
    }

    const renderTab = () => {
        switch (mode) {
            case 'main': return <Main />
            case 'more': return <More />
            case 'education': return <Education />
            case 'qualification': return <Qualification table />
            case 'award': return <Award />
            case 'punish': return <Punish />
            case 'experience': return <Experience />
            case 'lesson': return <Lessons />
            case 'event': return <Events />
            case 'role': return <Roles />
            case 'documents': return <Documents />
            case 'attestation': return <Attestation />
            default: return
        }
    }

    return (
        // TODO
        <StaffRosterContext.Provider value={{ closeModal, allForms, addMode }}>
            <Modal
                open={isOpen}
                classNames={{ modal: styles.modalMain }}
                closeOnOverlayClick={false}
                closeOnEsc={false}
                showCloseIcon={false}
                animationDuration={1}
            >
                <div className={styles.modalContent}>
                    <div className={styles.modalMenu}>
                        <img src={userPhoto} alt='o_O' />
                        <ul>{personnelModalModes.map(renderLi)}</ul>
                    </div>
                    <div className={styles.modalMain}>{renderTab()}</div>
                    <div className={styles.modalFooter}>
                        <Button mode='bordered'>Пароль</Button>
                        <div className={styles.modalFooterRightBlock}>
                            <div>
                                {/* <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
                        <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div> */}
                            </div>
                            <Button mode='white' onClick={allForms.handleSubmit}>Сохранить</Button>
                            <Button mode='white' onClick={closeModal}>Закрыть</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </StaffRosterContext.Provider>

    )
}
