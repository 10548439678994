import React from "react";
import { BrowserRouter, Navigate, Route, Routes as Switch } from "react-router-dom";

import { PrivateRoute } from "../components/Routing";

import Layout from "../components/Layout";
import Auth from '../pages/Auth'
import Main from "../pages/Main";
import Roles from "../pages/Roles";
import RoleSettings from "../pages/RoleSettings";
import Candidates from "../pages/Candidates";
import Schedule from '../pages/Schedule'
import Personnel from "../pages/Personnel";
import Students from "../pages/Students";
import Classing from "../pages/Classing";
import Courses from "../pages/Courses";
import SchoolYears from "../pages/SchoolYears";
import SchoolWeeks from "../pages/Weeks";
import Terms from "../pages/Terms";
import Periods from "../pages/Periods";
import ScheduleVariants from "../pages/ScheduleVariants";
import Reports from "../pages/Reports";
import Classbook from "../pages/Classbook";
import ClassbookDO from "../pages/ClassbookDO";
import ClassbookVD from "../pages/ClassbookVD";
import MethodOffice from "../pages/MethodOffice";
import ThematicPlanning from "../pages/ThematicPlanning";
import Directory from "../pages/Directory";
import Settings from "../pages/Settings";
import DisciplineStructure from "../pages/DisciplineStructure";
import RoomTypes from "../pages/RoomTypes";
import Rooms from "../pages/Rooms";
import Ads from "../pages/Ads";
import FAQ from "../pages/FAQ";
import TypesStudySessions from "../pages/TypesStudySessions";
import GradesOptions from "../pages/GradesOptions";
import GradeWeight from "../pages/GradeWeight";
import EducationalStatuses from "../pages/EducationalStatuses";
import EducationalStructure from "../pages/EducationalStructure";
import Scheduling from "../pages/Scheduling";
import DirectoryObjectsUMB from "../pages/DirectoryObjectsUMB";
import Culture from "../pages/Culture";
import FormsOfEducation from "../pages/FormsOfEducation";
import SubjectsByYearStudy from "../pages/SubjectsByYearStudy";
import Countries from "../pages/Countries";
import ScheduleSettings from "../pages/ScheduleSettings";
import StudyPlans from "../pages/StudyPlans";
import TypesOfDisease from "../pages/TypesOfDisease";
import MKBten from "../pages/MKBten";
import Discharge from "../pages/Discharge";
import Healthgroup from "../pages/Healthgroup";
import TypesOfVaccinations from "../pages/TypesOfVaccinations";
import GroupNutrition from "../pages/GroupNutrition";
import PeriodNutrition from "../pages/PeriodNutrition";
import ProfileTrack from "../pages/ProfileTrack";
import Competence from "../pages/Competence";
import EduDirections from "../pages/EduDirections";
import SubjectsExtraEdu from "../pages/SubjectsExtraEdu";
import SubjectsExtraCurEdu from "../pages/SubjectsExtraCurEdu";
import EventLevels from "../pages/EventLevels";
import StudyShifts from "../pages/StudyShifts";
import Achievements from "../pages/Achievements";
import ScheduleVariantsDO from "../pages/ScheduleVariantsDO";
import ActivityTypes from "../pages/ActivityTypes";
import EventTypes from "../pages/EventTypes";
import PhysicalGroup from "../pages/PhysicalGroup";
import SpecMedGroup from "../pages/SpecMedGroup";
import AchievementsPoints from "../pages/AchievementsPoints";
import CanteenStaff from "../pages/CanteenStaff";
import ScheduleVariantsVD from "../pages/ScheduleVariantsVD";
import EducDirections from "../pages/EducDirections";
import MedicalCards from "../pages/MedicalCards";
import Manners from "../pages/Manners";
import { Info, Report, Schedule as ExamSchedule } from "../pages/Exam";
import MenuDay from "../pages/MenuDay";
import Dishes from "../pages/Dishes";
import Homeworks from "../pages/Homeworks";
import DigitalExamBook from "../pages/DigitalExamBook";
import JournalControl from "../pages/JournalControl";
import JournalControlDO from "../pages/JournalControlDO";
import JournalManners from "../pages/JournalManners";
import StudyPlansDO from "../pages/StudyPlansDO";
import StudyPlansVD from "../pages/StudyPlansVD";
import Subgroups from "../pages/Subgroups";
import EventsList from "../pages/EventsList";
import Holidays from "../pages/Holidays";
import EducationalInstitutions from "../pages/EducationalInstitutions";
import StagesStudy from "../pages/StagesStudy";
import FitnessIndicators from "../pages/FitnessIndicators";
import DailyRoutine from "../pages/DailyRoutine";


export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/auth' element={<Auth />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<Main />} />

            <Route path="directory" element={<Directory />} />
            <Route path="candidates" element={<Candidates />} />
            <Route path="personnel" element={<Personnel />} />
            {/* <Route path="variable_stuff" element={<VariableStuff/>} /> */}

            {/* ИНФОРМАЦИОННЫЕ СЕРВИСЫ / УЧ-МАТ БАЗА */}
            <Route path="room_types" element={<RoomTypes />} />
            <Route path="rooms" element={<Rooms />} />
            <Route path="directoryObjectsUMB" element={<DirectoryObjectsUMB />} />
            <Route path="event_levels" element={<EventLevels />} />
            <Route path="achievements" element={<Achievements />} />
            <Route path="act_types" element={<ActivityTypes />} />
            <Route path="event_types" element={<EventTypes />} />
            <Route path="events_list" element={<EventsList />} />
            <Route path="achiev_points" element={<AchievementsPoints />} />
            <Route path="educ_directions" element={<EducDirections />} />

            {/* МЕДПУНКТ */}
            <Route path="medical_cards" element={<MedicalCards />} />
            <Route path="types_of_disease" element={<TypesOfDisease />} />
            <Route path="MKB_ten" element={<MKBten />} />
            <Route path="discharge" element={<Discharge />} />
            <Route path="health_group" element={<Healthgroup />} />
            <Route path="types_of_vaccinations" element={<TypesOfVaccinations />} />
            <Route path="physical_group" element={<PhysicalGroup />} />
            <Route path="special_medical_groups" element={<SpecMedGroup />} />

            {/* СТОЛОВАЯ */}
            <Route path="group_nutrition" element={<GroupNutrition />} />
            <Route path="period_nutrition" element={<PeriodNutrition />} />
            <Route path="canteen_staff" element={<CanteenStaff />} />
            <Route path="menu_day" element={<MenuDay />} />
            <Route path="dishes" element={<Dishes />} />

            {/* ОБРАЗОВАНИЕ / ОСНОВНОЕ ОБРАЗОВАНИЕ */}
            <Route path="study_plans" element={<StudyPlans />} />
            <Route path="edu_directions" element={<EduDirections />} />
            <Route path="students/:studentId?" element={<Students />} />
            <Route path="classing" element={<Classing />} />
            <Route path="courses" element={<Courses />} />
            <Route path="ads" element={<Ads />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="competence" element={<Competence />} />
            <Route path="schedule_variants" element={<ScheduleVariants />} />
            <Route path="discipline_structure_ba" element={<DisciplineStructure from='0' />} />
            <Route path="discipline_structure_do" element={<DisciplineStructure from='1' />} />
            <Route path="discipline_structure_vd" element={<DisciplineStructure from='2' />} />
            <Route path="schedule" element={<Schedule />} />
            <Route path="classbook" element={<Classbook />} />
            <Route path="methodOffice" element={<MethodOffice />} />
            <Route path="thematic_planning" element={<ThematicPlanning />} />
            <Route path="culture" element={<Culture />} />
            <Route path="subjects_by_year_study" element={<SubjectsByYearStudy />} />
            <Route path="schedule_settings" element={<ScheduleSettings />} />
            <Route path="track_profile" element={<ProfileTrack />} />
            <Route path="study_shifts" element={<StudyShifts />} />
            <Route path="digital_exam_book" element={<DigitalExamBook />} />
            <Route path="journal_control" element={<JournalControl />} />
            <Route path="manners" element={<Manners />} />
            <Route path="journal_manners" element={<JournalManners />} />
            <Route path="homeworks" element={<Homeworks />} />
            <Route path="subgroups" element={<Subgroups />} />

            <Route path="ege">
              <Route path="info" element={<Info />} />
              <Route path="report" element={<Report />} />
              <Route path="schedule" element={<ExamSchedule />} />
            </Route>
            <Route path="oge">
              <Route path="info" element={<Info ogeMode={true} />} />
              <Route path="report" element={<Report ogeMode={true} />} />
              <Route path="schedule" element={<ExamSchedule ogeMode={true} />} />
            </Route>

            {/* ДОПОЛНИТЕЛЬНОЕ ОБРАЗОВАНИЕ */}
            <Route path="subjects_extra_edu" element={<SubjectsExtraEdu />} />
            <Route path="subjects_extracur_edu" element={<SubjectsExtraCurEdu />} />
            <Route path="do_schedule_variants" element={<ScheduleVariantsDO />} />
            <Route path="study_plans_do" element={<StudyPlansDO />} />
            <Route path="classbook_do" element={<ClassbookDO />} />
            <Route path="journal_control_do" element={<JournalControlDO />} />

            {/* ВНЕУРОЧНАЯ ДЕЯТЕЛЬНОСТЬ */}
            <Route path="vd_schedule_variants" element={<ScheduleVariantsVD />} />
            <Route path="study_plans_vd" element={<StudyPlansVD />} />
            <Route path="classbook_vd" element={<ClassbookVD />} />

            {/* НАСТРОЙКИ */}
            <Route path="settings" element={<Settings />} />
            <Route path="educational_structure" element={<EducationalStructure />} />
            <Route path="educational_statuses" element={<EducationalStatuses />} />
            <Route path="grades_options" element={<GradesOptions />} />
            <Route path="grade_weight" element={<GradeWeight />} />
            <Route path="types_study_sessions" element={<TypesStudySessions />} />
            <Route path="school_years" element={<SchoolYears />} />
            <Route path="terms" element={<Terms />} />
            <Route path="school_weeks" element={<SchoolWeeks />} />
            <Route path="forms_of_education" element={<FormsOfEducation />} />
            <Route path="countries" element={<Countries />} />
            <Route path="periods" element={<Periods />} />
            <Route path="holidays" element={<Holidays />} />
            <Route path="educational_institutions" element={<EducationalInstitutions />} />
            <Route path="stages_study" element={<StagesStudy />} />
            <Route path="fitness_indicators" element={<FitnessIndicators />} />
            <Route path="daily_routine" element={<DailyRoutine />} />

            <Route path="roles">
              <Route index element={<Roles />} />
              <Route path=':roleId' element={<RoleSettings />} />
            </Route>

            <Route path="scheduling" element={<Scheduling />} />
            <Route path="reports">
              <Route index element={<Reports />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<Navigate to='/' replace />} />
      </Switch>
    </BrowserRouter>
  );
}