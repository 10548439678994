import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import moment from 'moment'

import Title from '../../components/Title'
import Search from './Search'
import List from './List'
import Modal from './Modal'
import { useDispatch } from 'react-redux'
import { getCoursesAlias } from '../../actions/courses'

const dateFormat = 'YYYY-MM-DD'; // Тип даты
const defaultDate = {from: moment(new Date()).format(dateFormat), to: moment(new Date()).format(dateFormat)}; // Дата по умолчанию

const Ads = () => {
        const [date, setDate] = useState(defaultDate); // Дата для поиска
        const [type, setType] = useState(null); // Тип объявления

        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(getCoursesAlias())
        }, [])

        return (
    <div className={styles.wrapper}>
        <Title title="Объявления"/>
        <Search date={date} setDate={setDate} dateFormat={dateFormat} setType={setType}/> 
        <List date={date} setType={setType}/>

        
        {type && <Modal type={type} setType={setType} date={date}/>}
    </div>
  )
}

export default Ads