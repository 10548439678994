import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'


const Leaf = ({ title, options, formData, setFormData, ...props}) => {

  const firstOps = options.length && options?.[0].week_id;

  const [isActive, setIsActive] = useState(firstOps);

  const handleClick = ( value ) => {
      const activeIndex = options.findIndex(el =>  el.week_id == isActive);
      const newIndex = activeIndex + value;
    if (newIndex < 0 || newIndex > options.length - 1) {
      return
    } else {
      setIsActive(options[newIndex].value) || setIsActive(options[newIndex].week_id);
    }
  }

    useEffect(()=>{
      setFormData({
        ...formData,
        week: isActive,
    })
    },[isActive])

    useEffect(()=> {
      !formData.week &&
      // setFormData(prev => prev.week !== formData.week && ({...prev, week:isActive }))
      setIsActive(firstOps)
    },[options])

    useEffect(()=> {
      setFormData(prev => ({...prev, week:isActive }))
      setIsActive(firstOps)
    },[formData.scYear])

   return (
      <div className={styles.wrapper}>
        <span className={styles.title}>{title}</span>
        
        <div className={styles.wrapperSelect}>
        
        <i 
        className={styles.arrow}
        onClick={() => handleClick(1)}
        ><svg 
        width="29" height="8" viewBox="0 0 29 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 3.5C0.223858 3.5 0 3.72386 0 4C0 4.27614 0.223858 4.5 0.5 4.5L0.5 3.5ZM28.8536 4.35355C29.0488 4.15829 29.0488 3.84171 28.8536 3.64645L25.6716 0.464466C25.4763 0.269204 25.1597 0.269204 24.9645 0.464466C24.7692 0.659728 24.7692 0.976311 24.9645 1.17157L27.7929 4L24.9645 6.82843C24.7692 7.02369 24.7692 7.34027 24.9645 7.53553C25.1597 7.7308 25.4763 7.7308 25.6716 7.53553L28.8536 4.35355ZM0.5 4.5L28.5 4.5V3.5L0.5 3.5L0.5 4.5Z" fill="#85939F"/>
        </svg></i>


        <select 
        value={isActive} 
        className={styles.select}
        onChange={(e) => setIsActive(e.target.value)}
        {...props}
        >
        {options && options.map(el => 
          <option 
            key={el.value || el.week_id}
            value={el.value || el.week_id}
            className={styles.option}
            >{el.name || el.week_name}</option>
          )
        }
        </select>

        <i
        className={styles.arrow}
        onClick={() => handleClick(-1)}
        ><svg  
        width="29" height="8" viewBox="0 0 29 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 3.5C0.223858 3.5 0 3.72386 0 4C0 4.27614 0.223858 4.5 0.5 4.5L0.5 3.5ZM28.8536 4.35355C29.0488 4.15829 29.0488 3.84171 28.8536 3.64645L25.6716 0.464466C25.4763 0.269204 25.1597 0.269204 24.9645 0.464466C24.7692 0.659728 24.7692 0.976311 24.9645 1.17157L27.7929 4L24.9645 6.82843C24.7692 7.02369 24.7692 7.34027 24.9645 7.53553C25.1597 7.7308 25.4763 7.7308 25.6716 7.53553L28.8536 4.35355ZM0.5 4.5L28.5 4.5V3.5L0.5 3.5L0.5 4.5Z" fill="#85939F"/>
        </svg></i>

        </div>

      </div>
  )
}

export default Leaf