import * as types from "../actions/types";



const initialState = {
  cultureList:[]
};


export default function culture (state = initialState, action) {
  switch (action.type) {
    case types.GET_CULTURE:
      return {
        ...state,
        cultureList: action.payload,
      };
  
    default:
      return state;
  }
}