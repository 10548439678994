import * as types from "../actions/types";


const initialState = {
  cycle: [],
  roles: [],
  access: [],
  access_personnel_table: [],
  folder: [],
  subfolder: [],
  files: [],
  loading: false,
};


export default function classbook (state = initialState, action) {
  switch (action.type) {

    case types.GET_METHOD_OFFICE_CYCLE:
      return {
        ...state,
        cycle: action.payload
      };
 
    case types.GET_METHOD_OFFICE_ROLES:
      return {
        ...state,
        roles: action.payload
      };
    case types.GET_METHOD_OFFICE_ACCESS:
      return {
        ...state,
        access: action.payload
      };
    case types.GET_METHOD_OFFICE_ACCESS_FILTERED_PERSONNEL:
      return {
        ...state,
        access_personnel_table: action.payload
      };
    case types.GET_METHOD_OFFICE_ACCESS_PERSONNEL:
      return {
        ...state,
        access_personnel_table: action.payload
      };
    case types.SET_METHOD_OFFICE_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case types.GET_METHOD_OFFICE_FILES:
      return {
        ...state,
        files: action.payload
      };
    case types.GET_METHOD_OFFICE_FOLDER:
      return {
        ...state,
        folder: action.payload
      };
    case types.GET_METHOD_OFFICE_SUBFOLDER:
      return {
        ...state,
        subfolder: action.payload
      };
    case types.METHOD_OFFICE_TABLE_CLEAN:
      return {
        ...state,
        cycle: [],
        roles: [],
        access: [],
        table: [],
        loading: false,
      }
  
    default:
      return state;
  }
}