import React, { useMemo, useRef } from 'react'
import Button from '../../components/Button'
import Loader from '../../components/Loader'
import styles from './styles.module.scss'
import { checked } from '../../assets/svg/other'
import { tests, results, notify } from '../../assets/svg/candidates'
import { add, remove, change, status, excel, print, interview, copy } from '../../assets/svg/footers'
import Select from '../../components/Select'
import Input from '../../components/Input'
import { useState } from 'react'
import Modal from './modal'
import { admissionYears, armyClasses, armyDistricts, itemsOnPage, statuses } from '../../assets/const'
import { useNavigate } from 'react-router-dom'
import Table from '../../components/Table'
import { Pagination } from '../../components/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCandidatesList, setPage } from '../../actions/candidates'
import { getSchoolYears } from '../../actions/common'
import useDoubleClick from 'use-double-click'
import classNames from 'classnames'
import NoticeModal from './NoticeModal'



export default function Candidates () {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {data, form} = useSelector(state => state.candidates)
  const {schoolYears} = useSelector(state => state.common)

  const { pageCount, pageNumber } = form
  const { list, loading } = data

  const [candidateId, setCandidateId] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])
  const [noticeModal, setNoticeModal] = useState(false)

  useEffect(() => {
    dispatch(getCandidatesList())
    !schoolYears && dispatch(getSchoolYears())
  }, [pageNumber])
  

  const openModal = (id) => () => setCandidateId(id)

  const closeModal = () => setCandidateId(null)

  const closeNoticeModal = () => setNoticeModal(false)

  const goHome = () => navigate('/')

  const select = (id) => () => {
    const newSelectedIds = selectedIds.includes(id)
      ? selectedIds.filter(i => i !== id)
      : [...selectedIds, id]
    setSelectedIds(newSelectedIds)
  }

  const renderOption = (option, index) => {
    const {  xp_key,  value, trmid, cid, gid, work_status, id, pmid, did ,  name, title, year, access, } = option
    const optionValue =   xp_key || value ||trmid ||cid || gid || work_status || id || pmid || did
    const optionTitle =  name || title || value || year || work_status || access
    return <option key={`${optionValue}-${index}`} value={optionValue}>{optionTitle}</option>
  }

  const pageClick = ({selected}) => dispatch(setPage(selected))
  
  const selectedEmails = useMemo(() => list?.reduce((acc, {xp_key, id, email}) => selectedIds.includes(xp_key) ? [...acc, {id, email}] : acc, []), [selectedIds])

  return (
    <div className={styles.wrapper}>
        <Modal
          candidateId={candidateId}
          isOpen={!!candidateId}
          closeModal={closeModal}
        />
        <NoticeModal
          open={noticeModal}
          selectedEmails={selectedEmails}
          closeModal={closeNoticeModal}
        />
        <h1>Претенденты</h1>
        <div className={styles.filters}>
          <div>
            <Input title='C' type='date'/>
            <Input title='По' type='date'/>
            <Select 
                title='Род войск родителей' 
                style={{maxWidth: '443px'}} 
                options={armyClasses}>    
                  <option value=''>Выбрать</option>
                  {armyClasses?.map(renderOption)}
            </Select>
            <Select 
                title='Военный округ родителей' 
                options={armyDistricts}>    
                  <option value=''>Выбрать</option>
                  {armyDistricts?.map(renderOption)}
            </Select>
            <Select 
                title='Год поступления' 
                options={schoolYears}>    
                  <option value=''>Выбрать</option>
                  {schoolYears?.map(renderOption)}
            </Select>
            <Select 
                title='Статус' 
                options={statuses}>    
                  <option value=''>Выбрать</option>
                  {statuses?.map(renderOption)}
            </Select>
          </div>
          <Button>Обновить</Button>
        </div>
        <div className={styles.modes}>
            <Button mode='white'>{tests()}Испытания</Button>
            <Button mode='white'>{results()}Результаты</Button>
            <Button onClick={() => setNoticeModal(true)} mode='white' >{notify()}Извещения</Button>
        </div>
        <Pagination
          pageCount={pageCount}
          pageNumber={pageNumber}
          onPageChange={pageClick}
          loading={loading}
        >
          {!!list?.length && 
            <Table style={{marginTop: '1rem', maxHeight: 'none'}}>
              <thead>
                  <tr>
                      <th>WebID</th>
                      <th>ФИО</th>
                      <th>Дата рождения</th>
                      <th>Класс для поступления</th>
                      <th>Ед. балл</th>
                      <th>Итог. заключение (псих.)</th>
                      <th>Ср. балл по табелю</th>
                      <th>Участие в олимпиадах</th>
                      <th>Экзаменационная группа</th>
                      <th>Прошел по конкурсу</th>
                      <th>Статус</th>
                  </tr>
              </thead>
              <tbody>
                {list.map((candidate, pageIndex) =>
                  <CandidateItem
                    key={candidate.id}
                    pageIndex={pageIndex}
                    data={candidate}
                    pageNumber={pageNumber}
                    openModal={openModal}
                    selectedIds={selectedIds}
                    select={select}
                  />
                )}
              </tbody>
            </Table>
          }
        </Pagination>
        <footer className={styles.footer}>
            <div>
                <Button className={styles.footerButton} mode='bordered' onClick={openModal}>{add()}Добавить</Button>
                <Button className={styles.footerButton} mode='bordered'>{remove()}Удалить</Button>
                <Button className={styles.footerButton} mode='bordered' onClick={openModal}>{change()}Изменить</Button>
                <Button className={styles.footerButton} mode='bordered'>{status()}Изменить статус</Button>
                <Button className={styles.footerButton} style={{padding: '.5rem 0'}}>{excel()}В Excel</Button>
                <Button className={styles.footerButton} style={{padding: '.5rem 0'}}>{print()}Печать экзаменационных билетов</Button>
                <Button className={styles.footerButton} style={{padding: '.5rem 0'}}>{interview()}Лист собеседования</Button>
                <Button className={styles.footerButton} style={{padding: '.5rem 0'}}>{copy()}Копировать</Button>
            </div>
            <Button mode='white' onClick={goHome}>Закрыть</Button>
        </footer>
    </div>
  )
}


const CandidateItem = ({
  data,
  pageIndex,
  pageNumber,
  openModal,
  selectedIds,
  select
}) => {

  const {calc_fio, date, child_class, xp_key } = data
  const index = pageNumber * itemsOnPage + pageIndex + 1
  const selected = selectedIds.includes(xp_key)
  const rowRef = useRef()

  useDoubleClick({
    onSingleClick: select(xp_key),
    onDoubleClick: openModal(xp_key),
    ref: rowRef,
    latency: 200
  });


  return (
    <tr className={classNames({[styles.selectedRow]: selected})} ref={rowRef}>
      <td>{index}</td>
      <td>{calc_fio}</td>
      <td>{date}</td>
      <td>{child_class}</td>
      <td>{}</td>
      <td>{}</td>
      <td>{}</td>
      <td>{}</td>
      <td>{}</td>
      <td>{checked()}</td>
      <td>Подтверждение</td>
    </tr>
  )
}