import React from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import moment from 'moment'
import { Checkbox, Input, Textarea } from '../../../components/ModalInputs'

const data = {
  date: '10.01.2022',
  lesson: 'География 6',
  class: '6А',
}

const Homework = () => {
  return (
    <div className={styles.wrapperHomework}>
      <div className={styles.headerHomework}>
          
          <div className={styles.homeData}>
            <Input disabled label='Дата' value={moment(data.date).format('DD.MM.YYYY')}/>
            <Input disabled label='Предмет' value={data.lesson}/>
            <Input disabled label='Класс' value={data.class}/>
            <div className={styles.homeCheck}>
                <Checkbox label='Прислать в виде файла преподавателю' />
            </div>
          </div>
          
          <div className={styles.homeInfo}>
            
            <div className={styles.homeTime}>
              <span>Время на выполнение:</span> 
              <Input type='number' /> <span>Часы</span> <Input type='number'/><span>Минуты</span></div>
          
          </div>
      </div>

      <div className={styles.homework}>
        <Textarea label='Текст задания' className={styles.textarea} />
      </div>

    </div>
  )
}

export default Homework