import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAboutData } from '../../../actions/settings/about'
import styles from '../styles.module.scss'
import {Input, Quill} from '../../../components/ModalInputs'
import { SettingsContext } from '../../../utils/context'



export default function About () {
  
  const dispatch = useDispatch()
  const { about } = useSelector(state => state.settings)
  const { form } = useContext(SettingsContext)

  const { values, handleChange, handleBlur } = form

  useEffect(() => {
    !about && dispatch(getAboutData())
  }, [])
  
  
  return (
    <div className={styles.inputsWrapper} style={{flexDirection: 'column'}}>
      <Input
        label='Дата образования'
        name='Foundation_date'
        type='date'
        style={{width: '150px'}}
        value={values.Foundation_date}
        onChange={handleChange}
      />
      
      <Input
        label='Основание'
        name='Foundation_reason'
        style={{width: '650px'}}
        value={values.Foundation_reason}
        onChange={handleChange}
      />

      <Quill
        label='Описание'
        value={values.AboutOrg}
        readOnly
      />
    </div>
  )
}
