import React from 'react'
import styles from './styles.module.css'
import cn from 'classnames'

export default function Input ({className, title, fz=20, ...props}) {
  
  const classObject = cn(styles.input, className)
  
  return (
    <div className={styles.wrapper}>
        <span className={styles.title} style={{fontSize: `${fz}px`}}>{title}</span>
        <input className={classObject} {...props}/>
    </div>
  )
}
