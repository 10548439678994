import { api } from "../../App";
import { SET_EXAM_LOADING, SET_EXAM_SCHEDULE, SET_EXAM_YEAR } from "../types";


export const setYear = (year) => ({type: SET_EXAM_YEAR, payload: year})


export const getExamSchedule = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EXAM_LOADING, payload: true });
    const { data } = await api.get('/exam_schedule');
    dispatch({ type: SET_EXAM_SCHEDULE, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EXAM_LOADING, payload: false });
  }
}

export const createExamSchedule = (data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_EXAM_LOADING, payload: true });
    await api.post('/exam_schedule', data);
    await dispatch(getExamSchedule())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EXAM_LOADING, payload: false });
    return success
  }
}

export const updateExamSchedule = (id, data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_EXAM_LOADING, payload: true });
    await api.put(`/exam_schedule/${id}`, data);
    await dispatch(getExamSchedule())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EXAM_LOADING, payload: false });
    return success
  }
}

export const deleteExamSchedule = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EXAM_LOADING, payload: true });
    await api.delete(`/exam_schedule/${id}`);
    await dispatch(getExamSchedule())
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EXAM_LOADING, payload: false });
  }
}