import React, { useState } from 'react'
import {Main} from './ModalTabs'

import Modal from '../../components/Modal'

export default function ModalComponent ({closeModal, addMode, values, handleChange, handleSubmit, setFieldValue, errors, height, selectedItem}) {

  const [mode, setMode] = useState('main')

  const renderTab = () => {
      switch (mode) {
          case 'main': return <Main 
            closeModal={closeModal} 
            addMode={!selectedItem?.weight}    
            values={values}
            handleChange={handleChange}
            handleSubmit={handleSubmit} 
            setFieldValue={setFieldValue}
            errors={errors} 
            selectedItem={selectedItem}
            />
          default: return
      }
  }

  return (
      <Modal
        open={!!selectedItem}
        menuItem={mode}
        setMenuItem={setMode}
        height={height}
      >
        {renderTab()}
      </Modal>
  )
}
