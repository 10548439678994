import XLSX from "xlsx";
// import {createNotification} from "../../../components/notifications/notifications";

function readFileAsync(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsArrayBuffer(file);
    })
}

const importExcel = async (file) => {
    const reader = new FileReader();

    let contentBuffer = await readFileAsync(file);
    const data = new Uint8Array(contentBuffer);
    const arr = new Array();
    for (let i = 0; i < data.length; ++i) arr[i] = String.fromCharCode(data[i]);
    const bstr = arr.join("");
    const workbook = XLSX.read(bstr, {type: "binary"});
    const first_sheet_name = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[first_sheet_name];
    const arrayOfObjects = XLSX.utils.sheet_to_json(worksheet, {raw: true});


    if (arrayOfObjects.length === 0) {
        // createNotification('error',
        //     `Пустой файл`
        // );
        return null;
    }

    if (arrayOfObjects.filter(row => Object.keys(row).length > 2).length > 0) {
        // createNotification('error',
        //     `Количество столбцов в каждом ряду не должно быть больше 2`,
        //     'Неверное количество столбцов'
        // );
        return null;
    }

    const resultArray = [];
    const rowName = Object.keys(arrayOfObjects[0])[0];
    const rowCode = Object.keys(arrayOfObjects[0])[1];

    arrayOfObjects.forEach(row => {
            resultArray.push({
                name: row[rowName] ? row[rowName] : '',
                code: row[rowCode] ? row[rowCode] : '',
                show: true,
                children: [],
            })
        }
    );



    return resultArray;
}

export default importExcel;