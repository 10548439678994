import * as types from "../actions/types";

const initialState = {
  list: null,
  loading: false,
  tfo_list: null,
  tfo_types: null,
  equipmentsList: null,
  equipmentsByRoom: null,
};


export default function rooms (state = initialState, action) {
  switch (action.type) {
    case types.SET_ROOMS:
      return {
        ...state,
        list: action.payload
      };
    case types.SET_ROOMS_LOAD:
      return {
        ...state,
        loading: action.payload
      };
    case types.SET_TFO_LIST:
      return {
        ...state,
        tfo_list: action.payload
      };
    case types.SET_TFO_TYPES_LIST:
      return {
        ...state,
        tfo_types: action.payload
      };
    case types.SET_EQUIPMENTS_LIST:
      return {
        ...state,
        equipmentsList: action.payload
      };
    case types.SET_EQUIPMENTS_BY_ROOM_LIST:
      return {
        ...state,
        equipmentsByRoom: action.payload
      };
    default:
      return state;
  }
}