import { api } from "../App";
import { SET_EVENT_LEVELS_LIST, SET_EVENT_LEVELS_LOADING } from "./types";




export const getEventLevels = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
    const { data } = await api.get('/news_levels');
    dispatch({ type: SET_EVENT_LEVELS_LIST, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
  }
}

export const createEventLevel = (data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
    await api.post('/news_levels', data);
    await dispatch(getEventLevels())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
    return success
  }
}

export const updateEventLevel = (id, data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
    await api.put(`/news_levels/${id}`, data);
    await dispatch(getEventLevels())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
    return success
  }
}

export const deleteEventLevel = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
    await api.delete(`/news_levels/${id}`);
    await dispatch(getEventLevels())
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
  }
}