import { BACKEND_URL_PREFIX } from "../../assets/const";
import { createNotification } from "../../components/Notifications/Notifications";
import { checkAndUpdateToken } from "../auth";
import { buildDefaultHeaders } from "../helper";
import {
    GET_TYPES_DISEASE,
} from "../types";


export const getTypesOfDisease = () => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(`${BACKEND_URL_PREFIX}/disease`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({ type: GET_TYPES_DISEASE, payload: result });
        }
    }
};

export const updateTypesOfDisease = (params) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(`${BACKEND_URL_PREFIX}/disease/${params.disease}`, {
            method: "PUT",
            body: JSON.stringify(params),
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            await dispatch(getTypesOfDisease());
        }
    };
}

export const createTypesOfDisease = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/disease`, {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getTypesOfDisease());
        } else createNotification('error', "Не удалось создать запись");
    }
};

export const deleteTypesOfDisease = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/disease/${id}`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getTypesOfDisease());
        }
    }
};